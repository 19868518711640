import React, { Fragment } from "react";
import { motion, useInView } from "framer-motion";

const FAQHelper = ({ width, ref2, isInView }) => {
    return (
        <Fragment>
            <div className={width <= 775 ? "" : "team-container"}>
                <div className="team-header">
                    <h1 style={{ fontSize: 52.25, marginLeft: 10, textAlign: "left" }}>FAQ's</h1>
                </div>
                <div>
                    <div className="middle-content-backgrounded">
                        <div className="container" style={width <= 775 ? { margin: 0, padding: 0, backgroundColor: "#F7F7F7" } : { backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                            <div>
                                <div style={{ backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Can I retract a bid or max bid after it is placed?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        The bids placed on our site, including proxy bids, are legally binding agreements to purchase items at the bid price if they are the last and final bid. Once a bid or proxy bid is placed in our auction, it is considered final. We do not allow bid retractions or adjustments under any circumstances. Thus, it is essential for all participants to carefully consider their bids before placing them.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">How can I inspect the violins before bidding?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                            We offer two convenient options for inspecting violins: online viewing and in-person inspection at our Chicago location. Our website provides detailed listings with high-resolution photos and comprehensive descriptions, allowing you to assess the instruments from home. If you're in the wonderful city of Chicago, you're welcome to visit us for a hands-on inspection where our staff can assist you. For further inquiries or assistance during the inspection process, feel free to contact us. We're here to ensure your auction experience is informed and enjoyable.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">What are the conditions and provenances of the instruments offered?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        The conditions and provenances of the instruments offered vary depending on each listing. We strive to provide comprehensive information for each violin, including details about its condition, provenance, and any relevant historical or ownership background. Our listings may include information about the instrument's age, maker, previous owners, restoration history, and any accompanying documentation or certificates of authenticity.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Are there any guarantees or returns?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        We do not offer guarantees or returns on instruments sold through our auctions. It's essential for bidders to carefully review all available information, including photos, descriptions, and condition reports, before placing a bid. We will make every effort to provide accurate and detailed information about each violin to assist bidders in making informed decisions. Additionally, we encourage bidders to inspect instruments in person or inquire about specific details before bidding. By participating in our auctions, bidders acknowledge and accept the conditions of sale, including the absence of guarantees or returns.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Why is there a charge on my card after I registered as a bidder?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        The auction house employs a $1.00 charge to validate new payment methods. This charge is processed using Stripe, our secure payment processor and typically occurs within 24 hours of registering a new payment method.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">What is a 'proxy bid'?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        A proxy bid, also known as a max bid, is a feature that enables bidders to set the highest amount they are willing to pay for an item. When placing a proxy bid, bidders enter their maximum bid amount into the auction system. The system then automatically places incremental bids on their behalf, up to their maximum bid, as other bidders place competing bids.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">What is a 'reserve'?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        A reserve price is the minimum amount that the seller is willing to accept for an item in an auction. It is a predetermined price agreed upon by the seller and the auction house, not disclosed to bidders, which must be met or exceeded for the item to be sold. If the highest bid at the end of the auction does not meet or exceed the reserve price, the item may not be sold, and the auction may end without a winner.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">What are the bidding increments?</p>
                                        <img src={require("../../../../assets/images/screenshotrates.png")} />
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">What happens if I win bid?</p>
                                        <motion.p ref={ref2}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 2, ease: "easeInOut" }} className="text-left">
                                        If you win a bid, congratulations! We will be in touch with you shortly. You'll be required to pay the hammer price plus any applicable buyer's premium and taxes. After payment, you can arrange for the pickup or delivery of the violin. It's essential to review the auction house's terms and conditions regarding payment, pickup, and shipping.
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default FAQHelper;