import React, { Fragment } from "react";
import ModifyCalendarEventsHelper from "../../components/addToCalendar/index";

const ModifyCalendarEventsPage = (props) => {
    return (
        <Fragment>
            <ModifyCalendarEventsHelper />
        </Fragment>
    );
}

export default ModifyCalendarEventsPage;