import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Datetime from 'react-datetime';
import moment from "moment";
import axios from "axios";
import { NotificationManager } from 'react-notifications';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { confirmAlert } from 'react-confirm-alert';
                                            

const ModifyCalendarEventsHelper = (props) => {
    const [state, setState] = useState({
        title: "",
        relevantLink: "",
        startDate: null,
        endDate: null
    });

    const handleDateChange = (date) => {
        // Convert the date to a JavaScript Date object if it's a moment object
        const selectedDate = date instanceof Date ? date : new Date(date.toISOString());
        console.log("Selected Date:", selectedDate);
        // Update the state
        setState(prevState => ({ ...prevState, startDate: selectedDate }));
    };

    useEffect(() => {
        const configuration = {};

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/current/calendar/events`, configuration)
            .then((res) => {
                if (res.data.message === "Successfully processed!") {
                    console.log("res.data", res.data);

                    const { events } = res.data;

                    setState(prevState => ({
                        ...prevState,
                        events: events.map((event) => ({
                            start: moment(event.startDate).toDate(),
                            end: moment(event.endDate).toDate(),
                            title: event.title, // Ensure title is properly set
                            url: event.relevantLink,
                            ...event
                        }))
                    }));
                } else {
                    console.log("err");
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error('An error occurred.', 'Error', 3000);
            });
    }, []);

    const handleSubmission = (e) => {
        e.preventDefault();

        const {
            title,
            relevantLink,
            startDate,
            endDate
        } = state;

        if ((typeof title !== "undefined" && title.length > 0) && (typeof relevantLink !== "undefined" && relevantLink.length > 0) && (startDate !== null) && (endDate !== null)) {
            const configuration = {
                title,
                relevantLink,
                startDate,
                endDate
            };
    
            axios.post(`${process.env.REACT_APP_BASE_URL}/add/calendar/event/db`, configuration).then((res) => {
                if (res.data.message === "Successfully processed!") {
                    console.log("res.data", res.data);
    
                    NotificationManager.success('Successfully added event to calendar!', 'Successfully addded.', 3000);
                } else {
                    console.log("err");
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            }).catch(err => {
                console.error(err);
    
                NotificationManager.error('An error occurred.', 'Error', 3000);
            });
        } else {
            NotificationManager.warning('Fill out all inputs before proceeding with this request!', "Fill out all inputs first!", 3000);
        }
    };

    const deleteCalendarEvent = (event) => {
        console.log("clicked/ran");

        const configuration = {
            id: event.id
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/remove/calendar/event`, configuration).then((res) => {
            if (res.data.message === "Successfully processed!") {
                console.log("res.data", res.data);

                setState(prevState => ({ 
                    ...prevState, 
                    events: prevState.events.filter((item) => item.id !== event.id).map((event) => ({
                        start: moment(event.startDate).toDate(),
                        end: moment(event.endDate).toDate(),
                        title: event.title, // Ensure title is properly set
                        url: event.relevantLink,
                        ...event
                    }))
                }))

                NotificationManager.success('Successfully removed event from calendar!', 'Successfully deleted/removed.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        }).catch(err => {
            console.error(err);

            NotificationManager.error('An error occurred.', 'Error', 3000);
        });
    }

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div style={{ marginTop: 20, marginBottom: 20, minHeight: "90vh" }} className="container">
                <div className="row form-horizontal cardcustomdesign">
                    <h3 className="fieldset-title row">Update Calendar Events</h3>
                    <hr />
                    <Tabs>
                        <TabList>
                            <Tab>Add New Event</Tab>
                            <Tab>Edit/Delete Event</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="container mt-4">
                                <form>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="input1" className="form-label">
                                                Title of calendar event
                                            </label>
                                            <input
                                                type="text"
                                                value={state.title}
                                                onChange={(e) => setState(prevState => ({ ...prevState, title: e.target.value }))}
                                                id="input1"
                                                className="form-control"
                                                placeholder="Enter your title or value..."
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                                                    <label style={{ marginTop: 10 }} className="label-custom">Calendar Event Duration (Start Date)</label>
                                                    <br />
                                                    <br />
                                                    <div className="float-left">
                                                        <Datetime timeFormat={true} minDate={new Date()} onChange={handleDateChange} value={state.startDate} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                                                    <label style={{ marginTop: 10 }} className="label-custom">Calendar Event Duration (End Date)</label>
                                                    <br />
                                                    <br />
                                                    <div className="float-left">
                                                        <Datetime timeFormat={true} minDate={new Date()} onChange={(date) => setState(prevState => ({ ...prevState, endDate: new Date(date) }))} value={state.endDate} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <label htmlFor="input3" className="form-label">
                                                Relevant Link
                                            </label>
                                            <input
                                                type="text"
                                                id="input3"
                                                value={state.relevantLink}
                                                onChange={(e) => setState(prevState => ({ ...prevState, relevantLink: e.target.value }))}
                                                className="form-control"
                                                placeholder="Enter the FULL url link"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <button onClick={handleSubmission} type="submit" className="btn btn-primary">
                                        Submit my new event
                                    </button>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Title</Th>
                                        <Th>Start Date</Th>
                                        <Th>End Date</Th>
                                        <Th>URL Link</Th>
                                        <Th>ID</Th>
                                        <Th>Created</Th>
                                        <Th>Deletion</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {typeof state.events !== "undefined" && state.events.length > 0 ? state.events.map((event, index) => {
                                        console.log("event", event);
                                        return (
                                            <Fragment key={index}>
                                                <Tr>
                                                    <Td>{event.title}</Td>
                                                    <Td>{moment(event.startDate).format("MM/DD/YYYY")}</Td>
                                                    <Td>{moment(event.endDate).format("MM/DD/YYYY")}</Td>
                                                    <Td>{event.url}</Td>
                                                    <Td>{event.id}</Td>
                                                    <Td>{moment(event.createdAt).fromNow()}</Td>
                                                    <Td className="hover"><img onClick={() => {
                                                        confirmAlert({
                                                            title: `Are you sure you want to delete this calendar event?`,
                                                            message: `This action cannot be undone. Once deleted, the calendar event will be permanently removed and will no longer appear in your schedule.`,
                                                            buttons: [
                                                                {
                                                                    label: 'Yes, Delete!',
                                                                    onClick: (e) => {
                                                                        console.log("Event deleted successfully.");
                                                                        
                                                                        deleteCalendarEvent(event); // Call the function to delete the event
                                                                    }
                                                                },
                                                                {
                                                                    label: `No, Keep it.`,
                                                                    onClick: () => {
                                                                        console.log("Event deletion canceled.");
                                                                    }
                                                                }
                                                            ]
                                                        });                                                    
                                                    }} src={require("../../assets/icons8-remove-64.png")} style={{ maxWidth: 32.25, maxHeight: 32.25 }} /></Td>
                                                </Tr>
                                            </Fragment>
                                        );
                                    }) : null}
                                </Tbody>
                            </Table>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default ModifyCalendarEventsHelper;
