import React, { Fragment, useState, useEffect } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import "./styles.css";
import { NotificationManager } from 'react-notifications';
import axios from "../../../interceptor";
import { connect } from "react-redux";
import { authentication } from "../../../redux/actions/authentication/auth";
import { useNavigate } from "react-router-dom";


const ConfirmEmailHelper = ({ authData, authentication }) => {

    const navigate = useNavigate();

    const [ state, setState ] = useState({
        countdown: 0,
        inputCode: ""
    });

    const resendCode = () => {
        console.log("resendCode clicked/ran...", authData.uniqueId);

        const configuration = {
            uniqueId: authData.uniqueId,
            email: authData.email
        };

        setState(prevState => ({ ...prevState, countdown: 45 }))

        axios.post(`${process.env.REACT_APP_BASE_URL}/send/code/email/confirmation/resend`, configuration).then((res) => {
            if (res.data.message === "Successfully sent your message!") {
                NotificationManager.success('Successfully sent the code again!', 'Successfully re-sent code.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
            }
        })
    };

    const verifyEmailCode = (e) => {
        e.preventDefault();

        const configuration = {
            code: state.inputCode,
            uniqueId: authData.uniqueId
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/verify/email/code/auth`, configuration).then((res) => {
            if (res.data.message === "Successfully verified code.") {
                console.log("Successfully verified code.");

                const { user } = res.data;

                authentication(user);

                NotificationManager.success('Successfully VERIFIED your code from your email.', 'Successfully verified email code.', 3000);

                setTimeout(() => {
                    navigate("/");
                }, 2450);

            } else if (res.data.message === "Did not match codes") {
                NotificationManager.info('The code you entered does NOT match our records...', 'Code did NOT match records!', 3000);
            } else {
                console.log("err verifying code...");

                NotificationManager.warning('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
            }
        })
    }

    useEffect(() => {
        let timer;
    
        if (state.countdown > 0) {
            timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, countdown: prevState.countdown - 1 }))
            }, 1000);
        }
    
        return () => {
            // Clear the timer when the component unmounts
            clearTimeout(timer);
        };
    }, [state.countdown]);
  
    const renderCountdownSendAgainText = () => {
        if (state.countdown === 0) {
            return `Resend the code again...`;
        } else {
            return `New code sent, wait ${state.countdown} seconds before trying to send the code again...`;
        }
    }

    return (
        <Fragment>
            <NavigationUniversalHelper />
                <div
                    className="section bg-theme-color-light overlay-dark overlay-opacity-8 bg-cover lazy addbacked"
                    data-loaded="true"
                >
                    <div className="container spacertop">
                        <div className="row text-center-md text-center-xs d-middle justify-content-start">
                        <div
                            className="col-12 col-lg-6 mb-5 text-white aos-init aos-animate"
                            data-aos="fade-in"
                            data-aos-delay={0}
                            data-aos-offset={0}
                        >
                            {/* main title */}
                            <h1 className="display-4 fw-bold mb-0">
                            <span className="d-inline-block">
                                <span className="h6 fw-normal d-block text-align-end text-center-xs">
                                    Check your inbox/email!
                                </span>
                                <span className="text-danger" style={{ textDecorationLine: "underline" }}>Confirm</span> your email address
                            </span>
                            </h1>
                            {/* slogan */}
                            <p className="h3 fw-normal mb-0">Enter the code we just sent you to your email address.</p>
                        </div>
                        <div
                            className="col-12 col-lg-6 text-align-end text-center-md text-center-xs aos-init aos-animate miminumheight"
                            data-aos="fade-in"
                            data-aos-delay={50}
                            data-aos-offset={0}
                        >
                            <div className="d-inline-block bg-white shadow-primary-xs rounded p-4 p-md-5 w-100 max-w-450 text-align-start miminumheight">
                            <h2 className="h5 mb-5">
                                <i className="fi fi-homeadvisor" />
                                <span className="d-inline-block px-2">Confirm your email address by entering the code we mailed to your inbox.</span>
                            </h2>
                            <a onClick={resendCode} className="clickable-resenda"><span className="d-inline-block px-2">{renderCountdownSendAgainText()}</span></a>
                            <form noValidate="" className="bs-validate">
                                <div className="form-floating mb-3">
                                    <input
                                        placeholder="Address or Zipcode"
                                        id="code"
                                        type="text"
                                        defaultValue=""
                                        value={state.inputCode}
                                        onChange={(e) => setState(prevState => ({ ...prevState, inputCode: e.target.value }))}
                                        className="form-control"
                                    />
                                    <label htmlFor="code">Code Entry</label>
                                </div>
                                <button
                                    type="submit"
                                    onClick={verifyEmailCode}
                                    className="btn w-100 btn-lg btn-danger bg-gradient-danger"
                                >
                                    Submit Your Code..
                                </button>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            <FooterUniversalHelper />
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.data
    }
};

export default connect(mapStateToProps, { authentication })(ConfirmEmailHelper);