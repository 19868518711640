import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import {
    useWindowSize
} from '@react-hook/window-size'                         

const AboutUsHelper = (props) => {
    const [width, height] = useWindowSize()
    const [ state, setState ] = useState({
        active: "about"
    });

    const renderAccordionContent = () => {
        const { active } = state;

        switch (active) {
            case "about":
                return (
                    <Fragment>
                        <h1 style={{ marginLeft: 15, fontWeight: "bold", fontSize: 52.25 }}>About Us</h1>
                        <p className="text-left">
                            <strong>Welcome to The Chicago Violin Auction.</strong> With a focus on the consignment and sale of violin family instruments and their respective bows, we are a startup-auction, based in one of the violin trades historic epicenters - downtown Chicago. The mission of The Chicago Violin Auction is to start an old-school, bare-bones style violin auction, modernized as an internet-based provider. 
                        </p>
                        <p className="text-left">
                            Auction buyers and sellers in the U.S. have limited choices. Non-string-instrument-specific
                            auction houses lack the expertise or credibility to generate high wholesale prices and fail
                            to maintain post-sale anonymity and discretion on the Internet. String-instrument-specific
                            auction houses are forced to reject many potential offerings due to excessive seller-side
                            demand. At the same time, the string-instrument-specific auction houses' efforts to generate
                            retail traffic make them a less desirable marketplace for trade buyers. Chicago Violin
                            Auction LLC fills this gap between retail and wholesale that has been widening as auction
                            houses have increasingly catered to the retail trade.
                        </p>
                    </Fragment>
                );
                break;
            case "our-team":
                return (
                    <Fragment>
                        <div className={width <= 775 ? "" : "team-container"}>
                            <div className="team-section" style={{ padding: "40px 20px", backgroundColor: "#f9f9f9" }}>
                                <h2 style={{ textAlign: "center", fontSize: "2.5rem", marginBottom: "20px", color: "#333" }}>
                                    Our Team
                                </h2>
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", maxWidth: "900px", margin: "0 auto" }}>
                                    <div style={{ flex: "1 1 50%", padding: "20px" }}>
                                        <h3 className="text-left" style={{ fontSize: "1.8rem", color: "#222", marginBottom: "10px", marginLeft: 15 }}>John Ryan</h3>
                                        <p className="text-left" style={{ fontSize: "1rem", color: "#555", margin: "5px 0" }}>Chicago Violin Auction</p>
                                        <p className="text-left" style={{ fontSize: "1rem", color: "#555", margin: "5px 0" }}>
                                            Chicago College of Performing Arts (B.M)
                                        </p>
                                        <p className="text-left" style={{ fontSize: "1rem", color: "#555", margin: "5px 0" }}>
                                            Eastman School of Music (M.M)
                                        </p>
                                        <p className="text-left" style={{ fontSize: "1rem", color: "#555", margin: "5px 0" }}>License NO 441.002697</p>
                                    </div>
                                    <div style={{ flex: "1 1 50%", textAlign: "center", padding: "20px" }}>
                                        <img src={require("../../assets/images/john.png")} alt="John Ryan holding a violin"
                                            style={{
                                                width: "100%",
                                                maxWidth: "425px",
                                                borderRadius: "10px",
                                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                            }} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="team-bio">
                                <p className="text-left">
                                    John Ryan, founder and head auctioneer, leads a diverse and fulfilling musical life. He began his career in Chicago, Illinois, while pursuing a Bachelor of Music in Viola Performance at the Chicago College of Performing Arts (CCPA). Immersed in one of the world's renowned musical hubs and situated near the Fine Arts Building—often referred to as the “violin mall”—he developed a deep passion for the wholesale trade of musical instruments and the vibrant community of musicians who brought them to life.
                                </p>
                                <p className="text-left">
                                    I've always been fascinated by these old wooden boxes. They not only look beautiful, but sing,” John explains. “During my time at CCPA, I was immersed—I spent my time playing and dealing violins, learning firsthand the stories of the violin trade and the great music that accompanied them. It felt I was not only looking vividly into the great accolades of the past, but truly connecting with them. These were rich and colorful histories—alive in a way you could not only handle and see, but hear. 
                                </p>
                                <p className="text-left">
                                    After earning his Bachelor of Music degree, John continued his studies at the Eastman School of Music in Rochester, NY, where he completed a Master of Music in Musical Performance and Literature. While establishing The Chicago Violin Auction, John dedicated himself to teaching. He taught music in both private and public schools, sharing his love for music with local communities. In particular, he worked extensively in underprivileged and high-need schools on Chicago's South and West Sides, fostering musical growth and inspiring young musicians in under-served areas.
                                </p>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
            {/* <div className="small-content" /> */}
            <div className="container">
                <div className="row">
                    <div className="spacer" />
                    <div className="header">
                        <img className="banner-image-about" src={require("../../assets/images/merged.png")} />
                    </div>
                    <div className="about-us-container col-sm-12 col-lg-12 col-md-12">
                        {width <= 775 ? <Fragment>
                            <ul>
                                <li id="list-item-accordian-mobile" style={{ marginTop: 15 }} onClick={() => setState(prevState => ({ ...prevState, active: "about" }))} className={state.active === "about" ? "active" : ""}>About Us</li>
                                <li id="list-item-accordian-mobile" style={{ marginTop: 15 }} onClick={() => setState(prevState => ({ ...prevState, active: "our-team" }))} className={state.active === "our-team" ? "active" : ""}>Our Team</li>
                            </ul>
                            <div className="main-content-secondary">
                                {renderAccordionContent()}
                            </div>
                        </Fragment> : <div className="content-secondary">
                            <div className="sidebar">
                                <ul>
                                    <li id="list-item-accordian" style={{ marginTop: 15 }} onClick={() => setState(prevState => ({ ...prevState, active: "about" }))} className={state.active === "about" ? "active" : ""}>About Us</li>
                                    <li id="list-item-accordian" style={{ marginTop: 15 }} onClick={() => setState(prevState => ({ ...prevState, active: "our-team" }))} className={state.active === "our-team" ? "active" : ""}>Our Team</li>
                                </ul>
                            </div>
                            <div className="main-content-secondary">
                                {renderAccordionContent()}
                            </div>
                        </div>}
                        <div className={width <= 775 ? "row" : "footer row"}>
                            {/* <div className="social-link"> */}
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    <a href="https://www.facebook.com/people/Chicago-Violin-Auction/61568371108590" target="_blank">
                                        <img className="image-bottom" src={require("../../assets/images/scrollll2.png")} alt="Facebook" />
                                    </a>
                                    <div style={{ height: 20 }} />
                                    <span style={{ fontWeight: "bold" }}>Facebook</span>
                                </div>
                            {/* </div> */}
                            {/* <div className="social-link"> */}
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    <a href="https://www.instagram.com/chicagoviolinauction" target="_blank">
                                        <img className="image-bottom" src={require("../../assets/images/cases2.png")} alt="Instagram" />
                                    </a>
                                    <div style={{ height: 20 }} />
                                    <span style={{ fontWeight: "bold" }}>Instagram</span>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default AboutUsHelper;
