import React, { Fragment } from "react";
import { motion, useInView } from "framer-motion";

const ShippingAccordionHelper = ({ width, ref, isInView }) => {
    return (
        <Fragment>
            <div className={width <= 775 ? "" : "team-container"}>
                <div className="team-header">
                    <h1 style={{ fontSize: 52.25, marginLeft: 10, textAlign: "left" }}>Shipping</h1>
                </div>
                <div>
                    <div className="middle-content-backgrounded">
                        <div className="container" style={width <= 775 ? { margin: 0, padding: 0, backgroundColor: "#F7F7F7" } : { backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                            <div>
                                <div style={{ backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Item Collection and Delivery</p>
                                        <motion.p ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }} className="text-left">
                                            After your payment is processed, we will send you details on how to collect or receive delivery of your purchase. At Chicago Violin Auction, we aim to make this process as smooth as possible. We offer an in-house pack-and-ship service for flat rates. To inquire about combining shipments, please contact us!
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default ShippingAccordionHelper;