import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import { useWindowSize } from '@react-hook/window-size';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const localizer = momentLocalizer(moment);

const CalendarComponent = (props) => {
    const [width, height] = useWindowSize();
    const [state, setState] = useState({ events: [] });



    useEffect(() => {
        const configuration = {};

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/current/calendar/events`, configuration)
            .then((res) => {
                if (res.data.message === "Successfully processed!") {
                    console.log("res.data", res.data);

                    const { events } = res.data;

                    setState(prevState => ({
                        ...prevState,
                        events: events.map((event) => ({
                            start: moment(event.startDate).toDate(),
                            end: moment(event.endDate).toDate(),
                            title: event.title, // Ensure title is properly set
                            url: event.relevantLink
                        }))
                    }));
                } else {
                    console.log("err");

                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error('An error occurred.', 'Error', 3000);
            });
    }, []);

    // Handle event click
    const handleSelectEvent = (event) => {
        if (event.url) {
            window.location.href = event.url; // Redirect to the URL
        } else {
            NotificationManager.info('Event does not have a redirect URL.', 'Info', 3000);
        }
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="container">
                <div className="row">
                    <div className="spacer" />
                    <Calendar
                        localizer={localizer}
                        events={state.events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="month"
                        style={{ height: "100vh" }}
                        onSelectEvent={handleSelectEvent} // Add event selection handler
                    />
                    <div className="spacer" />
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default CalendarComponent;
