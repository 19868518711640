import React, { Fragment, useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = { width: '225px', height: '150px', zIndex: 0 };

const center = { lat: 41.876610, lng: -87.624573 };

const FooterUniversalHelper = () => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
    })
    
    const [map, setMap] = React.useState(null)
    
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        console.log("window.google.maps.", window.google.maps);
        // map.fitBounds(bounds);
        setMap(map)
    }, [])
    
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    
    return (
        <Fragment>
            <footer id="footer" style={{ backgroundColor: '#000', color: '#fff', padding: '20px 40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Left Section: Logo and Links */}
                <div className="logo-container" style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                    <Link to="/">
                        <img
                            src={require("../../../assets/whitelogo.png")}
                            alt="CVA logo"
                            className="logo"
                            style={{ height: 'auto' }}
                        />
                    </Link>
                    <ul className="foot-links" style={{ display: 'flex', gap: '20px', listStyleType: 'none', margin: 0, padding: 0 }}>
                        <li>
                            <Link style={{ color: "#fff", textDecoration: 'none' }} to="/terms_and_conditions">Terms of Use</Link>
                        </li>
                        <li>
                            <Link style={{ color: "#fff", textDecoration: 'none' }} to="/cookie_policy">Cookie Policy</Link>
                        </li>
                        <li>
                            <Link style={{ color: "#fff", textDecoration: 'none' }} to="/privacy_policy">Privacy Policy</Link>
                        </li>
                    </ul>
                </div>
                {/* Right Section: Socials and Address */}
                <div className="footer-right" style={{ textAlign: 'right' }}>
                    <div style={{ marginBottom: '15px', fontSize: '14px' }}>
                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <Marker position={center} />
                            </GoogleMap>
                        ) : null}
                        <div className="row">
                            <div className="socials" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                <a target="_blank" rel="noopener noreferrer" className="col-md-4 col-lg-4 col-sm-12" href="https://www.instagram.com/chicagoviolinauction?igsh=YjhuNDM5NjRocGk1&utm_source=qr">
                                    <img
                                        src={require("../../../assets/icons/igfooter.png")}
                                        alt="Instagram Icon"
                                        style={{ height: '30px', width: '30px' }}
                                    />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" className="col-md-4 col-lg-4 col-sm-12" href="https://www.facebook.com/chicago.violin.auction?mibextid=uzlsIk">
                                    <img
                                        src={require("../../../assets/icons/facebookfooter.png")}
                                        alt="Facebook Icon"
                                        style={{ height: '30px', width: '30px' }}
                                    />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" className="col-md-4 col-lg-4 col-sm-12" href="https://x.com/chicagoauction/">
                                    <img
                                        src={require("../../../assets/icons/icons8-x-100footer.png")}
                                        alt="Twitter X Icon"
                                        style={{ height: '30px', width: '30px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
} 

export default FooterUniversalHelper;