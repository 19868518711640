import axios from "axios";
import { store } from "./redux/store/store"; // Your Redux store

// Create an Axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

console.log("store.getState().auth", store.getState().auth.data);
// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.data.token; // Access token from Redux store
        console.log("token", token);

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;