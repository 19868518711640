import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import "./styles.css";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import axios from "../../../interceptor";
import ContentLoader from 'react-content-loader'
import {
    useWindowSize
} from '@react-hook/window-size'
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import uuid from "react-uuid";
import _ from "lodash";
import Downshift from 'downshift';


const currentDate = new Date();
// Array of month names in full format
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// Get the month number from the Date object (0 for January, 1 for February, etc.)
const monthNumber = currentDate.getMonth();
const fullYear = currentDate.getFullYear();
// Get the full month name by indexing into the monthNames array
const fullMonthName = monthNames[monthNumber];

const PERCENTAGE_FEE = 0.20;
const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    autoplaySpeed: 2500,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    ending: "newest",
    price: "low"
};     

const EntireCatalogMainHelper = ({ authData }) => {
    const navigate = useNavigate();
    const [width, height] = useWindowSize();
    
    const [ state, setState ] = useState({
        listings: []
    });

    useEffect(() => {
        const configuration = {
            ending: new Date(state.ending),
            price: state.price
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions/filtered`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;

                setState(prevState => ({ ...prevState, listings }));

                // NotificationManager.success('Successfully fetched/gathered live auctions!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, []);;

    useEffect(() => {
        // Function to find the lowest bid in an array
        const findLowestBid = (arr) => {
            if (!arr || arr.length === 0) {
                return Infinity; // Default to a very high value if no bids exist
            }
            return arr.reduce((minPrice, obj) => 
                obj.bidAmount < minPrice ? obj.bidAmount : minPrice, Infinity); // Find the minimum bid
        };
    
        // Function to find the highest bid in an array
        const findHighestBid = (arr) => {
            if (!arr || arr.length === 0) {
                return -Infinity; // Default to a very low value if no bids exist
            }
            return arr.reduce((maxBid, obj) => 
                obj.bidAmount > maxBid ? obj.bidAmount : maxBid, -Infinity); // Find the maximum bid
        };
    
        // Process and sort the listings
        const sortedListings = [...state.listings].map((listing) => {
            // Determine the current bid
            const hasBids = listing.bidsArray && listing.bidsArray.length > 0;
            const currentBid = hasBids
                ? (state.price === "low" 
                    ? findLowestBid(listing.bidsArray) 
                    : findHighestBid(listing.bidsArray))
                : listing.bidPrice || 0; // Use listing.bidPrice or fallback to 0 if undefined
            console.log("currentBid", currentBid);
            return {
                ...listing,
                currentBid, // Add or update the currentBid in each listing
            };
        }).sort((a, b) => {
            // Extract the highest bid from the bidsArray for 'a' and 'b'
            const highestBidA = a.bidsArray && a.bidsArray.length > 0 
                ? a.bidsArray.reduce((max, bid) => Math.max(max, bid.bidAmount), -Infinity) 
                : a.bidPrice || 0; // Fallback to bidPrice or 0 if no bids exist
            
            const highestBidB = b.bidsArray && b.bidsArray.length > 0 
                ? b.bidsArray.reduce((max, bid) => Math.max(max, bid.bidAmount), -Infinity) 
                : b.bidPrice || 0; // Fallback to bidPrice or 0 if no bids exist
        
            // Sort listings based on state.price
            return state.price === "low" 
                ? highestBidA - highestBidB // Ascending order for "low"
                : highestBidB - highestBidA; // Descending order for "high"
        });
    
        // Update state with the processed and sorted listings
        setState((prevState) => ({
            ...prevState,
            listings: sortedListings,
        }));
    }, [state.price]); // Re-run effect when state.price or state.listings change
    
    const findLowestPrice = (arr) => {
        if (arr.length === 0) {
            return 0; // or handle the empty array case as needed
        }
        const lowestPrice = arr.reduce((minPrice, obj) => 
            (obj.bidAmount < minPrice ? obj.bidAmount : minPrice), arr[0].bidAmount);
        return lowestPrice;
    };

    const findHighestBid = (arr) => {
        if (arr.length === 0) {
          return 0; // or handle the empty array case as needed
        }
        const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
        return highestBid;
    }

    const addCommasToNumber = (number) => {
        // Convert the number to a string
        const numberString = number.toString();
        // Use a regular expression to add commas
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');    
        return formattedNumber;
    };

    console.log("stateeeeeeeeeeeeeeeeeeee :", state);
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="min-height">
                <div style={{ paddingTop: 50 }} className="container">
                    <div className="row">
                        <Link to="/current_auc" className="btn btn-priamry" style={width <= 775 ? { color: "#fff", maxWidth: 325, maxHeight: 42.25, marginTop: 5, marginLeft: 10 } : { color: "#fff", float: "right", maxWidth: 325, maxHeight: 42.25, marginTop: 5 }}>Back to auctions</Link>
                        {/* <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6" style={{ marginLeft: 30, marginTop: 2.25 }}>
                            <h1 style={{ color: "rgba(128, 0, 32, 0.65)" }}>{fullMonthName}, {fullYear}</h1>
                        </div> */}
                        {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true ? null : <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6">
                            <Link to="/register" className="btn btn-priamry" style={width <= 775 ? { color: "#fff" } : { color: "#fff", float: "right" }}>Register to bid!</Link>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 card-margin">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row no-gutters" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                                    <Downshift
                                        onChange={selection => {
                                            if (selection && selection.id) {
                                                navigate(`/lot_page/${selection.id}`);
                                            } else {
                                                console.warn("Invalid selection:", selection);
                                            }                                        
                                        }}
                                        itemToString={item => (item ? item.title : '')}
                                    >
                                        {({
                                            getInputProps,
                                            getItemProps,
                                            getLabelProps,
                                            getMenuProps,
                                            isOpen,
                                            inputValue,
                                            highlightedIndex,
                                            selectedItem,
                                            getRootProps
                                        }) => (
                                            <Fragment>
                                                <div
                                                    style={{display: 'inline-block', minWidth: "100%", width: "100%"}}
                                                    {...getRootProps({}, {suppressRefError: true})}
                                                >
                                                    <input style={{ minWidth: "100%", width: "100%" }} className="specialsearchinput form-control" type="text" placeholder="SEARCH CATALOG " {...getInputProps()} />
                                                </div>
                                                <ul {...getMenuProps()}>
                                                    {isOpen ? state.listings.filter(item => !inputValue || item.title.toLowerCase().includes(inputValue.toLowerCase())).map((item, index) => (
                                                        <li
                                                            {...getItemProps({
                                                                key: item.title,
                                                                index,
                                                                item,
                                                                style: {
                                                                    backgroundColor:
                                                                    highlightedIndex === index ? 'lightgray' : 'white',
                                                                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                                },
                                                            })}
                                                            className="list-styled-item"
                                                        >
                                                            {item.title}
                                                        </li>
                                                    )) : null}
                                                </ul>
                                            </Fragment>
                                        )}
                                    </Downshift>
                                </div>
                                <div className="col-12">
                                    <div className="card card-margin">
                                        <div className="card-body">
                                            <div className="row search-body">
                                                <div className="col-lg-12">
                                                <div className="search-result">
                                                    <div className="result-header">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                        <div className="records">
                                                            Showing: <b>1-{typeof state.listings !== "undefined" && state.listings.length > 0 ? state.listings.length : 0}</b> of <b>{typeof state.listings !== "undefined" && state.listings.length > 0 ? state.listings.length : 0}</b> result(s)
                                                        </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                        <div className="result-actions">
                                                            <div className="result-sorting">
                                                                <span>Sort By:</span>
                                                                <select
                                                                    className="form-control border-0"
                                                                    id="exampleOption"
                                                                    onChange={(e) => {
                                                                        console.log("e", e);

                                                                        setState(prevState => {
                                                                            return { ...prevState, price: e.target.value, ending: "" };
                                                                        })
                                                                    }}
                                                                >
                                                                    <option value={"low"}>Low</option>
                                                                    <option value={"high"}>High</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="result-body">
                                                    <div className="table-responsive">
                                                        <table className="table widget-26">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <p className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Listing:</p>
                                                                </td>
                                                                <td>
                                                                    <p className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Title:</p>
                                                                </td>
                                                                <td>
                                                                    <div className="widget-26-job-salary" style={{ fontWeight: "bold" }}># Of Bids:</div>
                                                                </td>
                                                                <td>
                                                                    <div className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Amount:</div>
                                                                </td>
                                                                <td>
                                                                    <div className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Ends in:</div>
                                                                </td>
                                                            </tr>
                                                            {typeof state.listings !== "undefined" && state.listings.length > 0 ? state.listings.map((listing, index) => {
                                                                const highestBidAmount = addCommasToNumber(findHighestBid(listing.bidsArray));
                                                                const highestBidAmountNum = findHighestBid(listing.bidsArray);
                                                                let now = moment(new Date());
                                                                let end = moment(new Date(listing.endDate));
                                                                let duration = moment.duration(end.diff(now));
                                                                let months = duration.months();
                                                                let days = duration.days();
                                                                let hours = duration.hours();
                                                                let minutes = duration.minutes();
                                                                let formattedDuration = `${days}d ${hours}h ${minutes}m`;
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <tr onClick={() => {
                                                                            navigate(`/lot_page/${listing.id}`);
                                                                        }} className="hover">
                                                                            <td>
                                                                                <div className="widget-26-job-emp-img">
                                                                                    <p>Lot #: {listing.lotNumber}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div style={Math.floor(Number(listing.reserveBidPrice)) <= Math.floor(Number(findHighestBid(listing.bidsArray))) ? { color: "green" } : { color: "red" }} className="widget-26-job-title">
                                                                                    {listing.title}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="widget-26-job-salary">{listing.bidsArray.length} bid(s)</div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="widget-26-job-salary">${highestBidAmountNum > 0 ? highestBidAmount : listing.bidPrice}</div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="widget-26-job-salary">{formattedDuration}</div>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                );
                                                            }) : <div style={{ margin: 32.25 }}>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth.data
    }
};

export default connect(mapStateToProps, { })(EntireCatalogMainHelper);