import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { authentication } from "./redux/actions/authentication/auth"; // Your Redux action

const AutoLogout = ({ timeout = 1800000 }) => { // Default to 30 minutes
    const dispatch = useDispatch();
    let logoutTimer;
    const resetTimer = () => resetLogoutTimer(dispatch, timeout);
    
    const resetLogoutTimer = (dispatch, timeout) => {
        if (logoutTimer) clearTimeout(logoutTimer);

        logoutTimer = setTimeout(() => {
            dispatch(authentication({})); // Trigger logout
        }, timeout);
    };

    const addActivityListeners = (dispatch, timeout) => {
        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);
        window.addEventListener("click", resetTimer);
        // Start the initial timer
        resetTimer();
    };

    const removeActivityListeners = () => {
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
        window.removeEventListener("click", resetTimer);

        if (logoutTimer) clearTimeout(logoutTimer);
    };

    useEffect(() => {
        addActivityListeners(dispatch, timeout);

        return () => {
            removeActivityListeners();
        };
    }, [dispatch, timeout]);

    return null;
};

export default AutoLogout;