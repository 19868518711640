import React, { Fragment, useState, useEffect, useRef } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../interceptor";
import { NotificationManager } from 'react-notifications';
import { MultiSelect } from "react-multi-select-component";
import ReCAPTCHA from "react-google-recaptcha";
import Slider from "react-slick";
import LoadingOverlay from 'react-loading-overlay';
import "./styles.css";
import {
    useWindowSize
} from '@react-hook/window-size';
import { Parallax } from "react-parallax";
import image4 from "../../assets/images/homepage.jpeg"
import image5 from "../../assets/images/homepage2.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from "lodash";
import BannerHomepage from "./helpers/banner/index";
import Carousel from "react-multi-carousel";
import { motion, useInView } from "framer-motion";

const optionsssss = [
    { label: "Subscribe to ALL events/news", value: "all-events-everything", disabled: false },
    { label: "Instrument Auction Updates 🎻🎸🎹", value: "instrument-auction-updates", disabled: false },
    { label: "Auction Event Reminders ⏰", value: "auction-event-reminders", disabled: false }
];

const validateEmail = (email) => {
    return String(email).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const LandingPageHelper = (props) => {
    const navigate = useNavigate();
    const [width] = useWindowSize();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, triggerOnce: true })
    const ref2 = useRef(null);
    const isInView2 = useInView(ref2, { once: true, triggerOnce: true })
    
    const [ state, setState ] = useState({
        firstName: "",
        lastName: "",
        email: "",
        isLoading: false,
        preferences: [],
        homepageDetails: {
            adminInputOne: "",
            adminInputTwo: "",
            adminInputThree: "",
        },
        captchaCompleted: false,
        listings: []
    });

    useEffect(() => {
        const configuration = {}

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const desiredLength = 5;

                if (listings.length < 5) {
                    while (listings.length < desiredLength) {
                        listings.push(null);
                    }   
                }               

                setState(prevState => ({ ...prevState, listings }));

                
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })

        const config = {}

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/admin/homepage/details`, config).then((res) => {
            if (res.data.message === "Successfully processed!") {
                console.log("res.data", res.data);

                const { homepageDetails } = res.data;

                setState(prevState => ({ ...prevState, homepageDetails }));  
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        });
    }, [])

    const handleJoinNewsletter = () => {
        console.log("handleJoinNewsletter clicked/ran...");

        const { firstName, lastName, email, preferences, captchaCompleted } = state;

        if (captchaCompleted !== false) {
            if ((typeof firstName !== "undefined" && firstName.length > 0) && (typeof lastName !== "undefined" && lastName.length > 0) && (typeof email !== "undefined" && email.length > 0) && (typeof preferences !== "undefined" && preferences.length > 0) && (validateEmail(email))) {

                setState(prevState => ({ ...prevState, isLoading: true }));

                const configuration = {
                    email,
                    name: `${firstName} ${lastName}`,
                    firstName,
                    lastName,
                    preferences
                };
        
                axios.post(`${process.env.REACT_APP_BASE_URL}/join/newsletter/subscribe`, configuration).then((res) => {
                    if (res.data.message === "Successfully submitted information!") {
                        NotificationManager.success(`We've successfully added you to our newsletter list!`, 'Successfully add to newsletter.', 3000);
        
                        setState(prevState => ({ ...prevState, email: "", firstName: "", lastName: "", preferences: [], captchaCompleted: false, isLoading: false }));
                    } else if (res.data.message === "This email has ALREADY been registered.") {
                        setState(prevState => ({ ...prevState, email: "", firstName: "", lastName: "", preferences: [], captchaCompleted: false, isLoading: false }));
        
                        NotificationManager.warning(res.data.message, 'Cannot re-register this email!', 3000);
                    } else {
                        setState(prevState => ({ ...prevState, isLoading: false }));

                        console.log("err");
                        
                        NotificationManager.error('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
                    }
                })
            } else {
                setState(prevState => ({ ...prevState, isLoading: false }));

                NotificationManager.warning("Fill out the REQUIRED VALUES before proceeding...", 'Cannot process request quite yet!', 3000);
            }
        } else {
            setState(prevState => ({ ...prevState, isLoading: false }));

            NotificationManager.info("Please complete the CAPTCHA before submitting the form.", 'Complete CAPTCHA first!', 3000);
        }
    };

    console.log("state.", state);

    const onSelect = (selectedList) => {
        if (typeof selectedList !== "undefined" && selectedList.length > 0) {
            setState(prevState => ({ ...prevState, preferences: selectedList }))
        } 
    }; 

    const customValueRenderer = (selected, _options) => {
        return selected.length
          ? selected.map(({ label }) => "✔️ " + label) 
          : "😶 No Items Selected"; 
    };
 
    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}
            <BannerHomepage />
            <div style={{ height: 55 }} />
            <div className="container" style={{ display: width <= 775 ? "" : "grid", gridTemplateColumns: '1.5fr 1fr', gap: '20px', padding: '20px' }}>
                {/* Left Section */}
                <div className="boxshadow" style={width <= 775 ? { textAlign: 'center', border: '1px solid #ccc', padding: '10px', borderRadius: '10px', height: "100%", marginBottom: 22.25 } : { textAlign: 'center', maxHeight: 545, border: '1px solid #ccc', padding: '10px', borderRadius: '10px', height: "100%", marginBottom: 22.25 }}>
                    <div className="row" style={{ margin: 0 }}> 
                        <div className="col-md-5 col-lg-5 col-sm-12">
                            <img 
                                src={require("../../assets/images/Calcanius-H2 1.png")}
                                alt="Violin" 
                                style={{ width: '100%', maxWidth: '100%', display: 'block', objectFit: "contain", minHeight: 450, marginLeft: 22.25 }}
                            />
                        </div>
                        <div 
                            className="col-md-7 col-lg-7 col-sm-12" 
                            style={width <= 775 ? { justifyContent: "center", alignContent: "center", alignItems: "center" } : { justifyContent: "center", alignContent: "center", alignItems: "center", paddingRight: 125 }}>
                            <h1 style={{ fontSize: 30, margin: '20px 0', textAlign: "left", marginLeft: 0, fontWeight: "bold" }}>Instrument Auctions</h1>
                            <motion.h2
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 2, ease: "easeInOut" }} style={{ fontSize: 20, color: '#000', textAlign: "left" }}>
                                A new instrument auction catered towards the bowed stringed instrument trade.
                                Click here to explore our catalog once it is published!
                            </motion.h2>
                            <button 
                                className="hoverbtn"
                                style={{
                                    padding: '10px 20px', 
                                    fontSize: '16px', 
                                    backgroundColor: 'rgba(236, 236, 236, 0.55)', 
                                    color: '#31869C',
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    borderRadius: '5px', 
                                    border: "1px solid black", 
                                    cursor: 'pointer',
                                    width: "100%",
                                    marginTop: '10px'
                                }}
                                onClick={() => {
                                    navigate("/current_auc")
                                }}
                            >
                                View Auctions
                            </button>
                        </div>
                    </div>
                </div>

                {/* Right Section */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {/* Buyers Section */}
                    <div className="boxshadow" style={{ border: '1px solid #ccc', borderRadius: '10px', textAlign: 'center', marginBottom: 22.25, minHeight: 250 }}>
                        <div className="row">
                            <div className="col-md-5 col-lg-5 col-sm-12">
                                <img 
                                    src={require("../../assets/images/IMG_37191.png")}
                                    alt="Buyer" 
                                    style={{ width: '100%', maxWidth: '100%', minHeight: 250, objectFit: "cover", borderRadius: 8.25 }}
                                />
                            </div>
                            <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }} className="col-md-7 col-lg-7 col-sm-12">
                                <h2 style={{ fontSize: 30, margin: '10px 0', textAlign: "left", marginLeft: 12.25, fontWeight: "bold" }}>Buyers</h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 2, ease: "easeInOut" }} style={{ fontSize: 20, color: '#000', textAlign: "left" }}>
                                    We strive for a reliable, fair, and transparent process.
                                </motion.p>
                                <button 
                                    className="hoverbtn"
                                    style={{
                                        padding: '10px 15px', 
                                        fontSize: '14px', 
                                        backgroundColor: 'rgba(236, 236, 236, 0.55)', 
                                        color: '#31869C',
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid black", 
                                        width: "90%",
                                        borderRadius: '5px', 
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        navigate("/buyers")
                                    }}
                                >
                                    Read More
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="boxshadow" style={{ border: '1px solid #ccc', borderRadius: '10px', textAlign: 'center',marginBottom: 22.25, minHeight: 250 }}>
                        <div className="row">
                            <div className="col-md-5 col-lg-5 col-sm-12">
                                <img 
                                    src={require("../../assets/images/violinwall.png")}
                                    alt="Seller" 
                                    style={{ width: '100%', maxWidth: '100%', minHeight: 250, objectFit: "cover", borderRadius: 8.25 }}
                                />
                            </div>
                            <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }} className="col-md-7 col-lg-7 col-sm-12">
                                <h2 style={{ fontSize: 30, margin: '10px 0', marginLeft: 12.25, textAlign: "left", fontWeight: "bold" }}>Sellers</h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 2, ease: "easeInOut" }} style={{ fontSize: 20, color: '#000', textAlign: "left" }}>
                                    Selling an instrument? Let us handle every detail with care.
                                </motion.p> 
                                <button 
                                    className="hoverbtn"
                                    style={{
                                        padding: '10px 15px', 
                                        fontSize: '14px', 
                                        backgroundColor: 'rgba(236, 236, 236, 0.55)', 
                                        color: '#31869C',
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid black", 
                                        width: "90s%",
                                        borderRadius: '5px', 
                                        cursor: 'pointer',
                                        position: "relative",
                                        left: 0,
                                        right: 0,
                                        minWidth: "90%",
                                        bottom: 0,

                                    }}
                                    onClick={() => {
                                        navigate("/sellers")
                                    }}
                                >
                                    Read More
                                </button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ paddingTop: 20, paddingBottom: 20 }} className="container" />
            {/* <hr />
            <div style={{ paddingTop: 20, paddingBottom: 20 }} className="container" /> */}
            {/* <div className="container">
                <div className="col-lg-12 mb-4 mb-sm-5">
                    <div>
                        <h3 style={{ color: "#800020", marginLeft: 10, textAlign: "left" }}>Upcoming Auctions</h3>
                        <p className="lead text-left">These are our upcoming auctions - check them out!</p>
                        <Carousel responsive={responsive}>
                            {new Array(10).fill("").map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div style={{ marginBottom: 32.25, marginTop: 32.25, paddingRight: 15 }} className="col-md-12">
                                            <div className="card user-card" style={{ border: "none" }}>
                                                <div className="card-header">
                                                    <h5>Profile</h5>
                                                </div>
                                                <div className="card-block">
                                                    <div className="user-image">
                                                        <img
                                                            src={require("../../assets/images/violin44.png")}
                                                            className="img--custom"
                                                            alt="User-Profile-Image"
                                                        />
                                                    </div>
                                                    <h6 className="f-w-600 m-t-25 m-b-10 text-left" style={{ marginLeft: 15 }}>The Chicago Violin Auction</h6>
                                                    <p className="text-muted text-left">The Chicago Violin Auction</p>
                                                    <hr />
                                                    <h6 className="text-left" style={{ marginLeft: 15 }}>Estimate:</h6>
                                                    <p className="m-t-15 text-muted text-left">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry.
                                                    </p>
                                                    <hr />
                                                    <button 
                                                        style={{
                                                            padding: '10px 20px', 
                                                            fontSize: '16px', 
                                                            backgroundColor: '#fff', 
                                                            color: '#000',
                                                            borderRadius: '5px', 
                                                            border: "1px solid black", 
                                                            cursor: 'pointer',
                                                            width: "100%",
                                                            marginTop: '10px'
                                                        }}
                                                        onClick={() => {}}
                                                    >
                                                        Bid/View Auction
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
            </div> */}
            <hr />
            <div className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "2rem" }}>
                {/* Left Section */}
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12" style={width >= 775 ? { flex: 1, paddingRight: "2rem" } : { paddingRight: "2rem" }}>
                        <h1 className="text-left wow slideInLeft" style={{ fontSize: 40, fontWeight: "bold", marginBottom: "1rem", marginLeft: 10 }}>
                            Welcome to The Chicago Violin Auction
                        </h1>
                        <motion.p
                            ref={ref}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: isInView ? 1 : 0 }}
                            transition={{ duration: 2, ease: "easeInOut" }}
                            className="text-left" style={{ fontSize: 20, lineHeight: "1.6", marginBottom: "1.5rem", color: "#000" }}>
                        Welcome to The Chicago Violin Auction. With a focus on the consignment and sale of violin family instruments and their respective bows, we are a pioneering startup-auction, based in one of the violin trade's historic epicenters - downtown Chicago. The mission of The Chicago Violin Auction is to be an old-school, bare-bones style violin auction, modernized as an internet-based provider. We stand committed to connecting you with exceptional instruments and preserving the rich heritage of the violin trade.
                        </motion.p>
                        <p className="text-left" style={{ fontWeight: "bold", marginBottom: "0.5rem", fontSize: 20 }}>John Ryan</p>
                        <p className="text-left" style={{ color: "#000", marginTop: -12.25, fontSize: 20 }}>Head Auctioneer</p>
                    </div>

                    {/* Right Section */}
                    <div className="col-md-6 col-lg-6 col-sm-12" style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                        <img
                            src={require("../../assets/images/john.png")}
                            alt="John Ryan"
                            id="johnimage"
                            style={{
                                width: "100%",
                                maxWidth: "400px",
                                borderRadius: "10px",
                                objectFit: "cover",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                            }}
                            onClick={() => {
                                navigate("/about_us");
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="container">
                <div style={{ backgroundColor: "#f9ede7", padding: "2rem", textAlign: "center", borderRadius: 17.25 }}>
                    <h2
                    className="text-left"
                    style={{
                        fontWeight: "bold",
                        fontSize: 40,
                        marginBottom: "1rem",
                        marginLeft: 0,
                    }}
                    >
                        Why The Chicago Violin Auction?
                    </h2>
                    <p
                    className="text-left"
                    style={{
                        marginBottom: "2rem",
                        color: "#000",
                        marginLeft: -12.25
                    }}
                    >
                        We specialize in connecting buyers and sellers of violin-family stringed instruments and bows.
                    </p>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-12" style={{ textAlign: "left" }}>
                        <div 
                            style={{ 
                                width: "50px", 
                                height: "50px", 
                                backgroundColor: "#EEE8B0", 
                                borderRadius: "50%", 
                                textAlign: "center", 
                                display: "flex", 
                                 fontSize: "1.25rem",
                                justifyContent: "center", 
                                alignItems: "center",
                                border: "1px solid grey",
                            }}
                        >
                            🔍
                        </div>
                            <h3
                            className="text-left"
                            style={{
                                fontWeight: "bold",
                                margin: "1rem 0",
                                fontSize: 22,
                            }}
                            >
                            Specialized Market
                            </h3>
                            <motion.p
                                ref={ref2}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isInView2 ? 1 : 0 }}
                                transition={{ duration: 2, ease: "easeInOut" }}
                                className="text-left"
                                style={{
                                    color: "#4a4a4a",
                                    marginLeft: -15
                                }}
                            >
                            Our specialists ensure every instrument is expertly handled, providing a seamless experience for buyers and sellers alike.
                            </motion.p>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12" style={{ textAlign: "left" }}>
                        <div 
                            style={{ 
                                width: "50px", 
                                height: "50px", 
                                backgroundColor: "#AFE0ED", 
                                borderRadius: "50%", 
                                textAlign: "center", 
                                fontSize: "1.25rem",
                                display: "flex", 
                                justifyContent: "center", 
                                alignItems: "center",
                                border: "1px solid grey",
                            }}
                        >
                            ✅
                        </div>

                            <h3
                            className="text-left"
                            style={{
                                fontWeight: "bold",
                                margin: "1rem 0",
                                fontSize: 22,
                            }}
                            >
                            Secure & Transparent Process
                            </h3>
                            <motion.p
                                ref={ref2}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isInView2 ? 1 : 0 }}
                                transition={{ duration: 2, ease: "easeInOut" }}
                                className="text-left"
                                style={{
                                    color: "#4a4a4a",
                                    marginLeft: -15
                                }}
                            >
                            We offer a fully transparent bidding process with secure payment methods, so you can confidently bid or sell without worry.
                            </motion.p>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12" style={{ textAlign: "left" }}>
                            <div 
                                style={{ 
                                    width: "50px", 
                                    height: "50px", 
                                    backgroundColor: "#EDD0AF", 
                                    borderRadius: "50%", 
                                    fontSize: "1.25rem",
                                    textAlign: "center", 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    alignItems: "center",
                                    border: "1px solid grey",
                                }}
                            >
                                🌍
                            </div>
                            <h3
                            className="text-left"
                            style={{
                                fontWeight: "bold",
                                margin: "1rem 0",
                                fontSize: 22,
                            }}
                            >
                            Global Reach
                            </h3>
                            <motion.p
                                ref={ref2}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isInView2 ? 1 : 0 }}
                                transition={{ duration: 2, ease: "easeInOut" }}
                                className="text-left"
                                style={{
                                    color: "#4a4a4a",
                                    marginLeft: -15
                                }}
                            >
                                With a global network of buyers and collectors, our auctions provide exposure to the right audience, ensuring that every violin gets the attention it deserves.
                            </motion.p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 50 }} />
            <div className="services-container container">
                <h3 style={{ color: "#000", marginLeft: 10, textAlign: "left", fontWeight: "bold", fontSize: 40 }}>Learn More!</h3>
                {/* <p className="lead text-left">Learn about our services and offerings - we offer a wide array of consignment options, auctions and door-to-door transactions.</p> */}
                <div style={{ height: 35 }} />
                <div className="row">
                    <div onClick={() => {
                        navigate("/about_us")
                    }} className="col-md-5 col-sm-12 col-lg-5">
                        <div className="service-item">
                            <img src={require("../../assets/images/vio1.png")} alt="Violin Scroll" className="service-image" />
                            <h4 className="text-left" style={{ fontWeight: "bold" }}><a href={null}>About Us</a></h4>
                        </div>
                    </div>
                    <div onClick={() => {
                        navigate("/contact")
                    }} className="col-md-3 col-sm-12 col-lg-3">
                        <div className="service-item">
                            <img src={require("../../assets/images/vio2.png")} alt="Violin Body" className="service-image-tall" />
                            <h4 className="text-left" style={{ fontWeight: "bold" }}>Contact Us</h4>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-lg-4">
                        <div className="row">
                            <a href="https://www.instagram.com/chicagoviolinauction/" className="col-md-12 col-sm-12 col-lg-12">
                                <div className="service-item">
                                    <img src={require("../../assets/images/vio3.png")} alt="Violin Bow" className="service-image-short" />
                                    <h4 className="text-left" style={{ color: "#000", fontWeight: "bold" }}>Instagram</h4>
                                </div>
                            </a>
                            <a href="https://www.facebook.com/people/Chicago-Violin-Auction/61568371108590/">
                                <div className="service-item">
                                    <img src={require("../../assets/images/vio4.png")} alt="Violin Case" className="service-image-short" />
                                    <h4 className="text-left" style={{ color: "#000", fontWeight: "bold" }}>Facebook</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row rowbackground" style={{ borderRadius: 17.25 }}>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 mx-auto center centered">
                        <img src={require("../../assets/images/vio5.png")} alt="Violin Case" className="service-image-tall-custom" />
                    </div>
                    <div className="news-content-redone col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <h2 className="text-center" style={{ fontWeight: "bold" }}>Get in The Loop! - Join Our Newsletter</h2>
                        <form id="newsletter-form">
                        <hr />
                        <label className="label" style={{ fontSize: 20 }}>Select which updates you'd like to enroll in:</label>
                        <hr />
                        <MultiSelect
                            options={optionsssss}
                            value={state.preferences}
                            shouldToggleOnHover={true}
                            onChange={onSelect}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select what updates you'd like:"
                        />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={state.email}
                            onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                            placeholder="YOUR EMAIL"
                            required=""
                            style={{ width: "100%", margin: "12.5px 0px" }}
                        />
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={state.firstName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, firstName: e.target.value }))}
                                    placeholder="FIRST NAME"
                                    required=""
                                    style={{ width: "100%", marginBottom: "12.5px" }}
                                />
                            </div>
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={state.lastName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, lastName: e.target.value }))}
                                    placeholder="LAST NAME"
                                    required=""
                                    style={{ width: "100%", marginBottom: "12.5px" }}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <div className="centered">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_KEY}
                                        onChange={(value) => value ? setState(prevState => ({ ...prevState, captchaCompleted: true })) : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div onClick={handleJoinNewsletter}>
                            <p className="btn" style={{ color: "#fff", minWidth: "100%" }}>Join</p>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <div style={{ height: 62.25 }} />
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2024</div>
            <div className="overlay" />
        </Fragment>
    );
} 

export default LandingPageHelper;
