import React, { Fragment, useState, useEffect } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import axios from "../../../interceptor";
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';

const ForgotPasswordHelper = (props) => {
    const navigate = useNavigate();

    const [ state, setState ] = useState({
        email: "",
        isLoading: false
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const requestNewPasswordToEmail = (e) => {
        e.preventDefault();

        const { email } = state;

        if (typeof email !== "undefined" && email.length > 0 && (validateEmail(email))) {
            const { email } = state;

            const configuration = { email };

            setState(prevState => ({ ...prevState, isLoading: true }));

            axios.post(`${process.env.REACT_APP_BASE_URL}/reset/password/temp`, configuration).then((res) => {
                if (res.data.message === "Successfully sent your message!") {
                    console.log("Successfully sent your message!");

                    setState(prevState => ({ ...prevState, isLoading: false }));

                    NotificationManager.success('Successfully sent an email with your new password to the associated email.', 'Successfully sent password via email!', 3000);

                    setTimeout(() => {
                        navigate("/");
                    }, 2450);

                } else {
                    setState(prevState => ({ ...prevState, isLoading: false }));

                    console.log("err sending email password...");

                    NotificationManager.warning('An unknown error has occurred processing your request.', 'Error occurred with request!', 3000);
                }
            })
        } else {
            NotificationManager.warning('You MUST enter a VALID email address before proceeding...', 'Enter a valid email address!', 3000);
        }
    }

    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}
            <div
                style={{ minHeight: "120vh" }}
                className="section"
                data-loaded="true"
            >
                <div className="container spacertop">
                    <div className="row text-center-md text-center-xs d-middle justify-content-start">
                        <div
                            className="col-12 col-lg-6 mb-5 text-white aos-init aos-animate"
                            data-aos="fade-in"
                            data-aos-delay={0}
                            data-aos-offset={0}
                        >
                            {/* main title */}
                            <h1 className="display-4 fw-bold mb-0">
                            <span style={{ color: "#000" }} className="d-inline-block">
                                <span style={{ color: "#000" }} className="h6 fw-normal d-block text-align-end text-center-xs">
                                    Reset your password...
                                </span>
                                <span style={{ color: "#000" }} className="text-dark">Request</span> a new temporary password
                            </span>
                            </h1>
                        </div>
                        <div
                            className="col-12 col-lg-6 text-align-end text-center-md text-center-xs aos-init aos-animate miminumheight"
                            data-aos="fade-in"
                            data-aos-delay={50}
                            data-aos-offset={0}
                        >
                            <div style={{ minHeight: "120vh", borderLeft: "1px solid black" }} className="d-inline-block bg-white shadow-primary-xs rounded w-100 max-w-450 text-align-start miminumheight">
                                <div style={{ paddingTop: "62.5px" }} className="container padding-bottom-3x mb-2">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8 col-md-10">
                                        <h2 style={{ textDecorationLine: "underline" }}>Forgot your password?</h2>
                                        <p className="text-left">
                                            Change your password in three easy steps. This helps to keep your new
                                            password secure.
                                        </p>
                                        <ol className="list-unstyled">
                                            <li>
                                            <span className="text-primary text-medium">1. </span>Fill in your
                                            email address below.
                                            </li>
                                            <br />
                                            <li>
                                            <span className="text-primary text-medium">2. </span>We'll email you a
                                            temporary code.
                                            </li>
                                            <br />
                                            <li>
                                            <span className="text-primary text-medium">3. </span>Use the code to
                                            change your password on our secure website.
                                            </li>
                                        </ol>
                                        <form className="card mt-4">
                                            <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="email-for-pass">Enter your email address</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="email-for-pass"
                                                    required=""
                                                    value={state.email}
                                                    onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                                                />
                                                <br />
                                                <small className="form-text text-muted">
                                                Type in the email address you used when you registered. Then we'll
                                                email a code to this address.
                                                </small>
                                                <br />
                                            </div>
                                            </div>
                                            <div className="card-footer">
                                            <button style={{ width: "100%" }} onClick={requestNewPasswordToEmail} className="btn btn-primary" type="submit">
                                                Get New Password
                                            </button>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default ForgotPasswordHelper;