import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import axios from "../../interceptor";
import { NotificationManager } from 'react-notifications';
import ContentLoader from 'react-content-loader'
import "./styles.css";
import SlidingPane from "react-sliding-pane";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import ImageGallery from "react-image-gallery";
import { Modal } from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert';
import Sheet from 'react-modal-sheet';
import _ from "lodash";
import {
    useWindowSize
} from '@react-hook/window-size';
import * as PureModal from 'react-modal';
import io from 'socket.io-client';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Slider from "react-slick";
import Tour from 'reactour'
import { markTourAsComplete } from "../../redux/actions/tour/tourAction";
import Confetti from 'react-confetti-boom';
import GIF from "../../assets/icons8-star.gif";
import { authentication } from "../../redux/actions/authentication/auth";

const PERCENTAGE_FEE = 0.08;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

const slickSettings = {
    dots: true,
    infinite: false,
    speed: 50,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

const newSocket = io(process.env.REACT_APP_BASE_URL);
const ITEMS_PER_PAGE = 5;
const incrementValues = [
    1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
    110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 225, 250, 275, 300,
    325, 350, 375, 400, 425, 450, 475, 500, 550, 600, 650, 700, 750, 800,
    850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800,
    1900, 2000, 2100, 2200, 2300, 2400, 2500, 2750, 3000, 3250, 3500, 3750,
    4000, 4250, 4500, 4750, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500,
    9000, 9500, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000,
    18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 26000, 27000,
    28000, 29000, 30000, 31000, 32000, 33000, 34000, 35000, 36000, 37000,
    38000, 39000, 40000, 41000, 42000, 43000, 44000, 45000, 46000, 47000,
    48000, 49000, 50000, 52500, 55000, 57500, 60000, 62500, 65000, 67500,
    70000, 72500, 75000, 77500, 80000, 82500, 85000, 87500, 90000, 92500,
    95000, 97500, 100000, 105000, 110000, 115000, 120000, 125000, 130000,
    135000, 140000, 145000, 150000, 155000, 160000, 165000, 170000, 175000,
    180000, 185000, 190000, 195000, 200000
];

const steps = [
    {
      selector: '.first-step',
      content: 'Click this button to place a new bid',
    },
    {
        selector: '.second-step',
        content: 'Scroll through the images by dragging to the left/right',
    },
    {
        selector: '.third-step',
        content: "Place a 'proxy' bid aka. a max *bid with this input field",
    },
    {
        selector: '.fifth-step',
        content: "Bookmark listings through the star by clicking or removing by clicking again!",
    }
];

const LotPageHelper = ({ authData, markTourAsComplete, tourData, authentication }) => {
    const [width, height] = useWindowSize() 
    const [ state, setState ] = useState({
        listing: null,
        isPaneOpenLeft: false,
        currentPage: 0,
        maxBidAmount: 0,
        bidAmount: 0,
        isModalOpen: false,
        totalPages: 0,
        showMoreDesc: false,
        bottomSheet: false,
        intervalId: null,
        socket: null,
        confettiActive: false,
        selected: null,
        listings: [],
        countdown: null,
        highestBidder: -Infinity,
        highestBidderMax: -Infinity,
        modalIsOpen: false,
        isTourOpen: tourData === true ? false : true,
        ready: false,
        index: 1,
        proxyBidSheet: false,
        images: [
            {
              original: "https://picsum.photos/id/1018/1000/600/",
              thumbnail: "https://picsum.photos/id/1018/250/150/"
            },
            {
              original: "https://picsum.photos/id/1015/1000/600/",
              thumbnail: "https://picsum.photos/id/1015/250/150/"
            },
            {
              original: "https://picsum.photos/id/1019/1000/600/",
              thumbnail: "https://picsum.photos/id/1019/250/150/"
            },
            {
                original: "https://picsum.photos/id/1018/1000/600/",
                thumbnail: "https://picsum.photos/id/1018/250/150/",
              },
              {
                original: "https://picsum.photos/id/1015/1000/600/",
                thumbnail: "https://picsum.photos/id/1015/250/150/",
              },
              {
                original: "https://picsum.photos/id/1019/1000/600/",
                thumbnail: "https://picsum.photos/id/1019/250/150/",
              },
        ]
    });
    const navigate = useNavigate();
    const params = useParams();
    const auctionID = params.id;

    const calculateTimeRemaining = (targetDate) => {
        const now = new Date().getTime();
        const targetTime = new Date(targetDate).getTime();
        const timeRemaining = targetTime - now;
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }; 

    console.log("state", state);

    useEffect(() => {
        const now = new Date();
        const tenMinutesFromNow = new Date(now.getTime() + 5 * 60000);
        const isoString = tenMinutesFromNow.toISOString();

        console.log("isoString", isoString);

        if (state.listing !== null && _.has(state.listing, "bidsArray")) {
            // Update highest bidAmount when bids change
            updateBidData(state.listing.bidsArray);
            updateBidDataTwo(state.listing.bidsArray);
        };
    }, [state.listing !== null ? _.has(state.listing, "bidsArray") ? state.listing.bidsArray : [] : null]);

    const updateBidData = (bids) => {
        // Iterate through the bids to find the highest bidAmount and maxBidAmount
        const maxBid = bids.reduce((prev, current) => (prev.bidAmount > current.bidAmount) ? prev : current, 0);
        // Update state with the highest bidAmount and maxBidAmount
        setState(prevState => ({ ...prevState, highestBidder: maxBid }));
    };

    const updateBidDataTwo = (bids) => {
        // Iterate through the bids to find the highest bidAmount and maxBidAmount
        const maxBid = bids.reduce((prev, current) => (prev.maxBidAmount > current.maxBidAmount) ? prev : current, 0);
        // Update state with the highest bidAmount and maxBidAmount
        setState(prevState => ({ ...prevState, highestBidderMax: maxBid }));
    };

    console.log("highestBidder", state.highestBidder, state.highestBidderMax);
    
    useEffect(() => {

        const configuration = {}

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;

                setState(prevState => ({ ...prevState, listings }));
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        });

        const configggg = {
            auctionID,
            uniqueId: authData.uniqueId
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/add/data/views/listing`, configggg).then((res) => {
            if (res.data.message === "Successfully added analytical data!") {
                console.log("res.data", res.data);

                const { listing, index } = res.data;

                const calculateTotalPages = (totalResults, itemsPerPage) => {
                    return Math.ceil(totalResults / itemsPerPage);
                };

                if (listing !== null) {
                    const paginatedPages = calculateTotalPages(typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? listing.bidsArray.length : 0, ITEMS_PER_PAGE);

                    setState(prevState => ({ ...prevState, listing: {
                        ...listing, // ensure you're spreading the previous listing state
                        imagesCustom: listing.images.map((image) => ({
                            original: image.location,
                            thumbnail: image.location
                        }))
                    }, totalPages: paginatedPages, ready: true, index }))
                } else {
                    setState(prevState => ({ ...prevState, ready: true }))
                }
                // NotificationManager.success('Successfully gathered auction/listing data.', 'Successfully gathered listing.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, [auctionID]);

    const startCountdown = (date) => {
        // Clear any existing interval first
        if (state.intervalId) {
            clearInterval(state.intervalId);
        }
    
        // Initialize countdown immediately
        const initialCountdown = calculateTimeRemaining(new Date(date));
        setState(prevState => ({ ...prevState, countdown: initialCountdown }));
    
        // Start the countdown interval
        const intervalId = setInterval(() => {
            const timeRemaining = calculateTimeRemaining(new Date(date));
    
            if (timeRemaining <= 0) {
                clearInterval(intervalId); // Stop the interval if countdown ends
                setState(prevState => ({ ...prevState, countdown: 0, intervalId: null }));
            } else {
                setState(prevState => ({ ...prevState, countdown: timeRemaining }));
            }
        }, 1000);
    
        setState(prevState => ({ ...prevState, intervalId }));
    };
    
    useEffect(() => {
        // Fetch initial listing data
        // ...

        return () => {
            clearInterval(state.intervalId);

            setState(prevState => ({ ...prevState, showMoreDesc: false }))
        };
    }, [auctionID]);

    useEffect(() => {
        // Start the countdown when the listing data changes
        if (state.listing !== null) {
            startCountdown(new Date(state.listing.startDate).getTime() <= 0 ? state.listing.startDate : state.listing.endDate);
        }

        return () => {
            clearInterval(state.intervalId);
        };
    }, [state.listing]);

    const fetchNewListingAndRefresh = () => {
        if (_.has(listing, "datePosted")) {
            const configuration = {
                params: {
                    auctionID,
                    date: listing.datePosted                
                }
            }

            axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/new/live/individual/listing/auction`, configuration).then((res) => {
                if (res.data.message === "Successfully gathered listing!") {
                    console.log("res.data", res.data);

                    const { listing } = res.data;

                    if (typeof listing !== "undefined") {
                        clearInterval(state.intervalId);

                        setState(prevState => ({ ...prevState, countdown: null, ready: false, intervalId: null, listing: null }));
        
                        setTimeout(() => {
                            navigate(`/lot_page/${listing.id}`, { replace: true })
                        }, 1250)
        
                        NotificationManager.success('Successfully gathered auction/listing data.', 'Successfully gathered listing.', 3000);
                    }
                } else if (res.data.message === "No listings more to be found!") {
                    NotificationManager.info('No more listings in this direction, go the other direction.', 'Last listing is this direction!', 3000);
                } else {
                    console.log("err");

                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        }
    };
    
    const fetchNewListingAndRefreshPrevious = () => {
        if (_.has(listing, "datePosted")) {
            const configuration = {
                params: {
                    auctionID,
                    date: listing.datePosted  
                }
            }
    
            axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/new/live/individual/listing/auction/previous`, configuration).then((res) => {
                if (res.data.message === "Successfully gathered listing!") {
                    console.log("res.data", res.data);
    
                    const { listing } = res.data;

                    
                    if (typeof listing !== "undefined") {
                        clearInterval(state.intervalId);
    
                        setState(prevState => ({ ...prevState, countdown: null, ready: false, intervalId: null, listing: null }));
    
                        setTimeout(() => {
                            navigate(`/lot_page/${listing.id}`, { replace: true })
                        }, 1250)
    
                        NotificationManager.success('Successfully gathered auction/listing data.', 'Successfully gathered listing.', 3000);
                    }
                } else if (res.data.message === "No listings more to be found!") {
                    NotificationManager.info('No more listings in this direction, go the other direction.', 'Last listing is this direction!', 3000);
                } else {
                    console.log("err");
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        }
    }

    const deleteListing = () => {
        console.log("deleteListing");

        const configuration = {
            auctionID,
            uniqueId: authData.uniqueId
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/delete/listing/admin`, configuration).then((res) => {
            if (res.data.message === "Successfully deleted listing!") {
                console.log("res.data", res.data);

                NotificationManager.success(`Successfully deleted this listing - going to homepage.`, 'Successfully deleted!', 3000);

                setTimeout(() => {
                    navigate("/admin_panel");
                }, 3000)
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };

    const addToFavoritesListDB = () => {
        console.log("addToFavoritesListDB clicked/ran...");

        const configuration = {
            auctionID,
            uniqueId: authData.uniqueId
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/add/to/favorites/list/personal`, configuration).then((res) => {
            if (res.data.message === "Successfully added to favorites list!") {
                console.log("res.data", res.data);

                const { user } = res.data;
                
                authentication({
                    ...user,
                    token: authData.token
                })

                NotificationManager.success(`Successfully added to your bookmarked list.`, 'Successfully bookmarked!', 3000);
            } else if (res.data.message === "You've already bookmarked this listing." || res.data.message === "You've already 'favorited' this listing.") {

                const { user } = res.data;
                
                authentication({
                    ...user,
                    token: authData.token
                })

                NotificationManager.info("You've ALREADY bookmarked this listing - no action taken.", "Already 'bookmarked' this listing!", 3000);
            } else {
                console.log("err", res.data);

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }

    const addToFavoritesList = () => {
        console.log("addToFavoritesList clicked/ran...1");

        confirmAlert({
            title: `Bookmark Listing?`,
            message: 'Are you sure you want to Bookmark Listing?',
            buttons: [
                {
                    label: 'Yes, Save!',
                    onClick: () => {
                        console.log("yes save");

                        addToFavoritesListDB()
                    }
                },
                {
                    label: `No, Don't save`,
                    onClick: () => {
                        console.log("no dont save");
                    }
                }
            ]
        });
    };

    console.log("state.countdown", state.countdown);

    const renderCountdown = () => {
        const { countdown } = state;

        if (countdown !== null) {
            if (countdown.days <= 0 && countdown.hours <= 0 && countdown.minutes <= 0 && countdown.seconds <= 0) {
                if (countdown !== null) {
                    return <Fragment>
                        Expired/Ended.
                    </Fragment>
                }
            } else {
                if (countdown !== null) {
                    return <Fragment>
                        {countdown.days}d {countdown.hours}h {countdown.minutes}m {countdown.seconds}s
                    </Fragment>
                }
            }
        }
    }

    useEffect(() => {
        if (newSocket !== null) {
            
            console.log("websocket is NO LONGER NULL!");

            const newIntervalChangeTime = (listing) => {

                clearInterval(state.intervalId);
                
                console.log("timeUpdated listening...", listing);

                const listingggggg = JSON.parse(listing);

                if (listingggggg.id === state.listing.id) {
                    setState(prevState => ({
                        ...prevState,
                        listing: {
                            ...listingggggg, // ensure you're spreading the previous listing state
                            imagesCustom: listingggggg
                        }
                    }));                    
                }
            };
          
            newSocket.on('timeUpdated', newIntervalChangeTime);
        
            return () => {
                newSocket.off('timeUpdated', newIntervalChangeTime);
            };
        } else {
            console.log("WS is currently null.");
        }
    }, [newSocket, state]);


    const triggerConfetti = () => {
        setState(prevState => ({ ...prevState, confettiActive: true }));
        // Automatically stop the confetti after the animation duration
        setTimeout(() => setState(prevState => ({ ...prevState, confettiActive: false })), 3000); // Adjust duration if needed
    };

    useEffect(() => {
        if (newSocket !== null) {
            
            console.log("websocket is NO LONGER NULL!");

            const newBidRecievedListener = (listing) => {
                
                console.log("newBidRecievedListener listening...", listing);

                const listingggggg = JSON.parse(listing);

                if (listingggggg.id === state.listing.id) {
                    setState(prevState => ({
                        ...prevState,
                        listing: listingggggg
                    }));                    
                }
            };
          
            newSocket.on('newBidRecieved', newBidRecievedListener);
        
            return () => {
                newSocket.off('newBidRecieved', newBidRecievedListener);
            };
        } else {
            console.log("WS is currently null.");
        }
    }, [newSocket, state]);

    const addCommasToNumber = (number) => {
        // Check if the input is a number
        if (typeof number !== 'number') {
            console.log('Input must be a number');

            return number;
        } else {
            // Convert the number to a string
            const numberString = number.toString();
            // Use a regular expression to add commas
            const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return formattedNumber;
        }
    }

    const renderIfStillActiveListing = () => {
        const { countdown } = state;

        if (countdown !== null) {
            if (countdown.days <= 0 && countdown.hours <= 0 && countdown.minutes <= 0 && countdown.seconds <= 0) {
                if (countdown !== null) {
                    return null;
                }
            } else {
                if (countdown !== null) {
                    return (
                        <Fragment>
                            <input style={{ maxWidth: "100%" }} className="form-control" value={state.maxBidAmount} onChange={(e) => setState(prevState => ({ ...prevState, maxBidAmount: e.target.value }))} placeholder="PLACE YOUR MAX BID VAL:" type="text" />
                                <button style={{ backgroundColor: "#fff", border: "1px solid lightgrey" }} onClick={(e) => {
                                    e.preventDefault();
        
                                    confirmAlert({
                                        title: `Are you sure you'd like to place your bid?`,
                                        message: 'All bids are contractual agreements to pay if you win the auction - placed bids MUST be paid.',
                                        buttons: [
                                            {
                                                label: 'Yes, Bid!',
                                                onClick: () => {
                                                    console.log("yes save");
                            
                                                    if (Object.keys(authData).length > 0 && _.has(authData, "uniqueId")) {
                                                        handleProxyMaxBidPlace();
                                                    } else {
                                                        NotificationManager.warning('MUST be logged-in to take this action!', 'MUST loggin first.', 3000)
                                                    }
                                                }
                                            },
                                            {
                                                label: `No, Don't bid..`,
                                                onClick: () => {
                                                    console.log("no dont save");
                                                }
                                            }
                                        ]
                                    });
                                }} type="submit">
                                    <img style={{ maxHeight: 45, maxWidth: 45, padding: 3.25 }} src={require("../../assets/icons/send.png")} />
                                </button>
                        </Fragment>
                    );
                }
            }
        }
    };

    const renderIfInTimespan = (hasBidded) => {
        const { listing } = state;

        console.log("hasBidded hasBiddedhasBiddedhasBiddedhasBiddedhasBidded", hasBidded);
        if (listing !== null) {

            const maxBidAmounttttttttttt = typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? listing.bidsArray.reduce((max, obj) => ((Number(obj.maxBidAmount) > Number(max.maxBidAmount) && (obj.bidderID === authData.uniqueId)) ? obj : max), listing.bidsArray[0]) : null;
            
            // const maxBidAmountttttt = typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? listing.bidsArray.reduce((max, obj) => ((Number(obj.bidAmount) > Number(max.bidAmount) && (obj.bidderID === authData.uniqueId)) ? obj : max), listing.bidsArray[0]) : null;

            const maxBidAmountttttt = listing.bidsArray && listing.bidsArray.length > 0  ? Math.max(...listing.bidsArray.map(obj => Number(obj.bidAmount))) : null;
            
            const hasExpiredOneDay = (endDate) => {
                // Parse the endDate string into a Date object
                const endDateTime = new Date(endDate);
                // Calculate the difference between the endDate and the current date
                const differenceInTime = endDateTime.getTime() - new Date().getTime();
                // Calculate the difference in days
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                // Check if the difference in days is greater than or equal to 1
                return differenceInDays > 0;
            }
    
            const calculateWetherToShowTwo = (sorted, sortedTwo) => {
                if (typeof sorted !== "undefined" && sorted.length > 0 || typeof sortedTwo !== "undefined" && sortedTwo.length > 0) {
                    return true;
                } else {
                    return false;
                }
            };

            const calculateWhatToShowName = () => {
                const { highestBidderMax, highestBidder } = state;
            
                console.log("currentWinner", listing.currentWinner);
            
                // Ensure `listing.currentWinner` and its properties exist safely
                const isWinnerValid = listing?.currentWinner && _.has(listing.currentWinner, "bidderID");
                const isUserWinning = isWinnerValid && listing.currentWinner.bidderID === authData.uniqueId;
            
                if (isUserWinning) {
                    return (
                        <p className="text-left" style={{ color: "green", fontWeight: "bold", textAlign: "center" }}>
                            {state.confettiActive === true ? <Confetti mode="boom" particleCount={100} colors={['#ff577f', '#ff884b']} /> : null} 
                            <p className="text-center" style={{ marginBottom: -7.5 }}>{`You're winning!`}</p>
                        </p>
                    );
                } else {
                    return (
                        <p className="text-left" style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
                            <p className="text-center">{`You're losing.`}</p>
                        </p>
                    );
                }
            };
            const isExpired = hasExpiredOneDay(listing.endDate);
            const sorted = listing.bidsArray.sort((a, b) => b.bidAmount - a.bidAmount);
            const sortedTwo = listing.bidsArray.sort((a, b) => b.maxBidAmount - a.maxBidAmount);
            const startDate = new Date(listing.startDate).getTime();
            let now = new Date().getTime();
            let timeRemaining = startDate - now;
            if (timeRemaining < 0) {
                if (isExpired === true && maxBidAmountttttt !== null) {
                    return (
                        <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                            <div style={{ marginTop: 22.25 }} className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 row">
                                    <p style={{ fontWeight: "bold", color: "#800020", textAlign: "center" }}>Current Bid: <h4 style={{ marginTop: 5 }}>${typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? addCommasToNumber(listing.currentBid) : addCommasToNumber(listing.currentBid)}</h4></p>
                                </div>
                            </div>
                            <div className="row">
                                {/* {!_.has(authData, "stripeAccountDetails") ? <Fragment>
                                    {calculateWetherToShowTwo(sorted, sortedTwo) ? <div className="row" style={{ flexDirection: "row", display: "flex" }}>
                                        <p className="text-left" style={{ color: "green", fontWeight: "bold" }}>{calculateWhatToShowName()}</p>
                                    </div> : null}
                                </Fragment> : null} */}
                                {_.has(authData, "stripeAccountDetails") ? <Fragment>
                                    {/* {_.has(listing, "currentWinner") && listing.currentWinner.bidderID === authData.uniqueId ? null :  */}
                                    {(_.has(listing, "currentWinner") && listing.currentWinner.bidderID !== authData.uniqueId) || !_.has(listing, "currentWinner") ? <button className="btn btn-primary first-step" style={{ backgroundColor: "#fff", marginLeft: 10, border: "1px solid lightgrey", zIndex: 0, width: "95%" }} onClick={(e) => {
                                        e.preventDefault();
        
                                        setState(prevState => ({ ...prevState, modalIsOpen: true }))   
                                    }} type="submit">
                                        Place a new bid!
                                    </button> : null}
                                    <hr style={{ marginTop: 20 }} />
                                    {calculateWetherToShowTwo(sorted, sortedTwo) ? <div className="row" style={{ flexDirection: "row", display: "flex" }}>
                                            {calculateWhatToShowName()}
                                        </div> : null}
                                    <label style={{ marginBottom: 14.25, fontWeight: "bold", textAlign: "center", fontSize: 20 }}>Place a new proxy bid:</label>
                                    {typeof listing.bidsArray !== "undefined" && listing.bidsArray.length === 0 ? null : <div id="proxybidinput" style={{ marginRight: 20 }} className="input-group third-step">
                                        {/* <input style={{ maxWidth: "100%" }} className="form-control" value={state.maxBidAmount} onChange={(e) => setState(prevState => ({ ...prevState, maxBidAmount: e.target.value }))} placeholder="PLACE YOUR MAX BID VAL:" type="text" /> */}
                                        <select
                                            style={{ maxWidth: "100%" }}
                                            className="form-control"
                                            value={state.maxBidAmount}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Update the state even if the default option is selected
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    maxBidAmount: value !== "default" ? value : null, // Handle default explicitly
                                                }));
                                            }}
                                            name="bids"
                                            id="bids"
                                        >
                                            {/* Default option */}
                                            <option value="default" selected={true}>
                                                Select your max bid
                                            </option>

                                            {/* Dynamically generated options */}
                                            {generateOptions()}
                                        </select>
                                        <button style={{ backgroundColor: "#fff", border: "1px solid lightgrey" }} onClick={(e) => {
                                            e.preventDefault();
        
                                            confirmAlert({
                                                title: `Are you sure you'd like to place your bid?`,
                                                message: 'All bids are contractual agreements to pay if you win the auction - placed bids MUST be paid.',
                                                buttons: [
                                                    {
                                                        label: 'Yes, Bid!',
                                                        onClick: () => {
                                                            console.log("yes save");
                                    
                                                            if (Object.keys(authData).length > 0 && _.has(authData, "uniqueId")) {
                                                                handleProxyMaxBidPlace();
                                                            } else {
                                                                NotificationManager.warning('MUST be logged-in to take this action!', 'MUST loggin first.', 3000)
                                                            }
                                                        }
                                                    },
                                                    {
                                                        label: `No, Don't bid..`,
                                                        onClick: () => {
                                                            console.log("no dont save");
                                                        }
                                                    }
                                                ]
                                            });
                                        }} type="submit">
                                            <img style={{ maxHeight: 45, maxWidth: 45, padding: 3.25 }} src={require("../../assets/icons/send.png")} />
                                        </button>
                                    </div>}
                                </Fragment> : <Fragment>
                                    <button className="btn btn-primary first-step" style={{ backgroundColor: "#fff", border: "1px solid lightgrey", zIndex: 0, width: "95%" }} onClick={(e) => {
                                        e.preventDefault();
        
                                        navigate("/register")
                                    }}>
                                        Register to bid
                                    </button>
                                </Fragment>}
                                {listing.currentMaxBidder.bidder === authData.uniqueId && typeof authData !== "undefined" && Object.keys(authData).length >= 6 && _.has(authData, "uniqueId") ? <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
                                    <p style={{}} className="text-right">Your proxy bid amount: ${state.listing.currentMaxBidder.amount} </p>
                                </div> : null}
                                <hr style={{ margin: "15px 0px" }} />
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <select 
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            setState(prevState => ({ ...prevState, isPaneOpenLeft: true }));
                                        }}
                                        id="bidding-his" 
                                        name="bidding-his" 
                                        style={{
                                            appearance: 'none', // Removes the arrow
                                            WebkitAppearance: 'none', // Ensures compatibility with Safari
                                            MozAppearance: 'none', // Ensures compatibility with Firefox
                                            border: '1px solid #ccc',
                                            backgroundColor: 'white',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            width: '100%',
                                        }}
                                    >
                                        <option disabled="" selected="">
                                            View Bidding History
                                        </option>
                                        <option value="--">------------</option>
                                        <option value="--">------------</option>
                                        <option value="--">------------</option>
                                    </select>
                                    <span style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: 'none',
                                        content: '▼', // Custom arrow if needed
                                        fontSize: '12px',
                                        color: '#000',
                                    }}>
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                            <div style={{ marginTop: 22.25 }} className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p style={{ fontWeight: "bold", color: "#800020" }}>Current: <h4 style={{ marginTop: 5 }}>${typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? addCommasToNumber(listing.currentBid) : addCommasToNumber(listing.bidPrice)}</h4></p>
                                </div>
                            </div>
                            <div className="row">
                                {_.has(authData, "stripeAccountDetails") ? <Fragment>
                                    {/* {_.has(listing, "currentWinner") && listing.currentWinner.bidderID === authData.uniqueId ? null : */}
                                    {(_.has(listing, "currentWinner") && listing.currentWinner.bidderID !== authData.uniqueId) || !_.has(listing, "currentWinner") ? <button className="btn btn-primary first-step" style={{ backgroundColor: "#fff", marginLeft: 10, border: "1px solid lightgrey", zIndex: 0, width: "95%" }} onClick={(e) => {
                                        e.preventDefault();
        
                                        setState(prevState => ({ ...prevState, modalIsOpen: true }))   
                                    }} type="submit">
                                        Place a new bid!
                                    </button> : null}
                                    <hr style={{ marginTop: 20 }} />
                                    {typeof listing.bidsArray !== "undefined" && listing.bidsArray.length === 0 ? null : <div style={{ marginRight: 20 }} className="input-group third-step">
                                        {renderIfStillActiveListing()}
                                    </div>}
                                </Fragment> : <Fragment>
                                    <button className="btn btn-primary first-step" style={{ backgroundColor: "#fff", border: "1px solid lightgrey", zIndex: 0, width: "95%" }} onClick={(e) => {
                                        e.preventDefault();
        
                                        navigate("/register")
                                    }}>
                                        Register to bid
                                    </button>
                                </Fragment>}
                                <div style={{ marginBottom: 17.25 }} className="lot-info-row">
                            </div>
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <select 
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        setState(prevState => ({ ...prevState, isPaneOpenLeft: true }));
                                    }}
                                    id="bidding-his" 
                                    name="bidding-his" 
                                    style={{
                                        appearance: 'none', // Removes the arrow
                                        WebkitAppearance: 'none', // Ensures compatibility with Safari
                                        MozAppearance: 'none', // Ensures compatibility with Firefox
                                        border: '1px solid #ccc',
                                        backgroundColor: 'white',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        width: '100%',
                                    }}
                                >
                                    <option disabled="" selected="">
                                        VIEW BIDDING HISTORY
                                    </option>
                                    <option value="--">------------</option>
                                    <option value="--">------------</option>
                                    <option value="--">------------</option>
                                </select>
                                <span style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'none',
                                    content: '▼', // Custom arrow if needed
                                    fontSize: '12px',
                                    color: '#000',
                                }}>
                                </span>
                            </div>
                            </div>
                        </div>
                    );
                }
            } 
        }
    }

    const findHighestBid = (arr) => {
        if (arr.length === 0) {
          return 0; // or handle the empty array case as needed
        }
        const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
        return highestBid;
    }

    const handlePurchaseListing = (buyPrice) => {
        console.log("handlePurchaseListing clicked/ran...");

        const configuration = {
            buyPrice,
            uniqueId: authData.uniqueId,
            auctionID: listing.id
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/buyitnow/purchase/listing/immediate`, configuration).then((res) => {
            if (res.data.message === "Successfully purchased this item, added to 'won' listings!") {
                console.log("res.data", res.data);

                const { listing } = res.data;

                setState(prevState => ({ ...prevState, isPaneOpenLeft: false, selected: null }));

                setTimeout(() => {
                    navigate("/ended_auctions");
                }, 2750)

                NotificationManager.success(`Successfully purchased and added to 'won' listings page!`, 'Successfully added to won listings page.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }

    const renderConditionalWhenReadyOrNot = () => {
        const startDate = new Date(listing.startDate).getTime();
        let now = new Date().getTime();
        let timeRemaining = startDate - now;
    
        const calculateRemainingTime = () => {
            const now = new Date().getTime();
            const targetTime = new Date(listing.startDate).getTime();
            const remainingTime = targetTime - now;
    
            if (remainingTime < 0) {
                // If the target time has passed, return zeros
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            } else {
                // Calculate days, hours, minutes, and seconds remaining
                const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
                const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
    
                return { days, hours, minutes, seconds };
            }
        };
    
        const { days, hours, minutes, seconds } = calculateRemainingTime();

        return (
            <Fragment>
                {Math.abs(days)}d {Math.abs(hours)}h {Math.abs(minutes)}m {Math.abs(seconds)}s
            </Fragment>
        )
    };

    const handleDeletionBookmark = (item) => {
        console.log("handleDeletionBookmark clicked/ran...", item);

        const configuration = {
            uniqueId: authData.uniqueId,
            bookmark: item.id
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/remove/previous/bookmark`, configuration).then((res) => {
            if (res.data.message === "Successfully executed logic!") {
                console.log("res.data correct/success: ", res.data);

                const { user } = res.data;

                authentication({
                    ...user,
                    token: authData.token
                })
                setState(prevState => ({ ...prevState, userDataRestricted: user })); // state.userDataRestricted.favoritesList

                NotificationManager.success(`Successfully changed/modified your bookmarked listings!`, 'Successfully modified bookmark.', 3000);
            } else if (res.data.message === "Original password do NOT match - please try your request again.") {
                NotificationManager.warning(res.data.message, 'Original password is NOT valid or matching.', 3000);
            } else {
                console.log("res data error :", res.data);
            }
        })
    };

    const renderConditional = () => {
        if (new Date(listing.startDate).getTime() > new Date().getTime()) {
            return (
                <Fragment>
                    <p  className="lineheight" style={{ color: "red" }}><b>Starts In</b>: {renderConditionalWhenReadyOrNot()}</p>
                    <hr />
                    <button className="btn btn-primary first-step" style={{ backgroundColor: "#fff", border: "1px solid lightgrey", zIndex: 0, width: "95%" }} onClick={(e) => {
                        e.preventDefault();

                        navigate("/register")
                    }}>
                        Register to bid
                    </button>
                </Fragment>
            );
        } else {
            return <p  className="lineheight" style={{ color: "red" }}><b>Ends</b>: {renderCountdown()}</p>;
        }
    };

    console.log("listinfffffg", state.listing);

    const renderContentMain = () => {
        const { listing, listings } = state;

        if (listing !== null) {
            const maxBidAmounttttttttttt = typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? listing.bidsArray.reduce((max, obj) => ((Number(obj.maxBidAmount) > Number(max.maxBidAmount) && (obj.bidderID === authData.uniqueId)) ? obj : max), listing.bidsArray[0]) : null;
            const maxBidAmountttttt = typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? listing.bidsArray.reduce((max, obj) => ((Number(obj.bidAmount) > Number(max.bidAmount) && (obj.bidderID === authData.uniqueId)) ? obj : max), listing.bidsArray[0]) : null;
            const hasBidded = typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? listing.bidsArray.some((item) => item.bidderID === authData.uniqueId) : false;
            return (
                <Fragment>
                    <header>
                        <div className="lot-container" style={{ marginBottom: "-45px" }}>
                            <p className="specialtitle">{listing.title}</p>
                        </div>
                        <div className="lot-container" style={{ marginBottom: "-45px" }}>
                            <p className="specialtitle" style={{ color: "#000", fontSize: "1.125rem" }}>Lot #: {typeof listing.lotNumber !== "undefined" && listing.lotNumber !== null ? listing.lotNumber : "Loading."}</p>
                            {typeof authData.favoritesList !== "undefined" && authData.favoritesList.find((item) => item.listingID === listing.id) ? <img className="fifth-step" onClick={() => handleDeletionBookmark(listing)} src={require("../../assets/icons8-star-100 copy.png")} style={{ maxWidth: 40, maxHeight: 40, position: "absolute", right: 35, top: 40 }} alt="A descriptive text for the GIF" /> : <img className="fifth-step" onClick={addToFavoritesListDB} src={require("../../assets/icons8-star-100.png")} style={{ maxWidth: 40, maxHeight: 40, position: "absolute", right: 35, top: 40 }} alt="A descriptive text for the GIF" />}
                        </div>
                        </header>
                        <div className="row">
                            <div style={{ paddingBottom: 22.25 }} className="col-md-5 col-lg-5 col-xl-5 col-sm-12">
                                <div style={{ margin: 20 }} className="auc-info">
                                    {state?.listing?.images?.length > 0 && (
                                        <ImageGallery 
                                            items={
                                                state?.listing?.images?.length > 0 
                                                ? state.listing.images.map((image) => ({
                                                    original: image.location,
                                                    thumbnail: image.location
                                                    })) 
                                                : []
                                            }
                                            className="auc-info"
                                            thumbnailPosition={"left"}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-7 col-xl-7 col-sm-12">
                                <div className="lot-info">
                                    <div className="row">
                                        <div style={{ marginTop: 32.25 }} className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            <p className="lineheight"><b>Estimate</b>: ${typeof listing.retailValue !== "undefined" ? listing.retailValue : 0}</p>
                                            {renderConditional()}
                                            {new Date(listing.startDate).getTime() > new Date().getTime() ? null : <p className="lineheight"><b>Reserve met?</b> <b style={typeof listing.reserveBidPrice !== "undefined" && Number(listing.reserveBidPrice) <= Number(listing.currentBid) || Math.floor(Number(listing.reserveBidPrice)) === 0 ? { color: "green" } : { color: "red" }}>{typeof listing.reserveBidPrice !== "undefined" && Number(listing.reserveBidPrice) <= Number(listing.currentBid) || Math.floor(Number(listing.reserveBidPrice)) === 0 ? "Reserve Met!" : "Reserve NOT Met."}</b></p>}
                                        </div>
                                        {renderIfInTimespan(hasBidded, maxBidAmountttttt, maxBidAmounttttttttttt)}
                                    </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: 32.25 }}/>
                            <div className="row">
                                <div className="col-md-8 col-lg-8 col-sm-12">
                                    <Markdown remarkPlugins={[remarkGfm]} className="markdownstyle">{listing.itemDescription}</Markdown>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12">
                                    <img src={require("../../assets/bidincrements.png")} className="image-increments" />
                                </div>
                            </div>
                            <hr style={{ marginTop: 22.25 }}/>
                        </div>
                        <div style={{ marginTop: 32.25 }}>
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            
                                    <div className="lot-info-row">
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    <div className="input-group">
                                    </div>
                                </div>
                            </div>
                            {Number(listing.buyItNowPrice) !== 0 ? <a onClick={() => {
                                const buyPriceSecondary = Number(listing.buyItNowPrice); // Ensure buyPrice is a number
                                const shippingCost = Number(listing.shippingCost); // Ensure shippingCost is a number
                                const totalCost = Math.floor((buyPriceSecondary + shippingCost + (buyPriceSecondary * PERCENTAGE_FEE)));

                                    confirmAlert({
                                    title: `Are you sure you'd like to buy this item outright now for $${totalCost.toFixed(2)}?`,
                                    message: `This action is IRREVERSIBLE, once you 'accept' this option, we will charge your card and purchase this item. This includes tax, shipping and all fee's in addition to the buy-it-now price for a full price.`,
                                    buttons: [
                                        {
                                            label: 'Yes, Purchase!',
                                            onClick: () => {
                                                console.log("yes save");
                        
                                                handlePurchaseListing(Number(listing.buyItNowPrice))
                                            }
                                        },
                                        {
                                            label: `No, Cancel.`,
                                            onClick: () => {
                                                console.log("no dont save");
                                            }
                                        }
                                    ]
                                });
                            }} className="lead hover fourth-step" style={{ fontWeight: "bold", fontSize: "1.225rem", color: "red" }}>Buy-It-Now! ${Number(listing.buyItNowPrice).toFixed(2)}</a> : null}
                            {/* {typeof authData !== "undefined" && Object.keys(authData).length >= 6 && _.has(authData, "uniqueId") ? <a className="hover" style={{ fontWeight: "bold", textDecorationLine: "underline", marginLeft: 23.25 }} onClick={() => addToFavoritesList()}>Bookmark</a> : null} */}
                            {Object.keys(authData).length > 0 && _.has(authData, "uniqueId") && _.has(authData, "admin") && authData.admin === true ? <a className="hover" style={{ fontWeight: "bold", color: "red", textDecorationLine: "underline", marginLeft: 23.25 }} onClick={() => {
                                confirmAlert({
                                    title: `Are you sure you'd like to PERMENANTLY delete this listing?`,
                                    message: 'This action is IRREVERSIBLE, use your power wisely. Make sure this is really what you want to do.',
                                    buttons: [
                                        {
                                            label: 'Yes, DELETE!',
                                            onClick: () => {
                                                console.log("yes save");
                        
                                                deleteListing()
                                            }
                                        },
                                        {
                                            label: `No, Don't delete`,
                                            onClick: () => {
                                                console.log("no dont save");
                                            }
                                        }
                                    ]
                                });
                            }}> Delete this listing (Admin View Only) </a> : null}
                            </div>
                            <div className="section-title-wr">
                                {Number(listing.buyItNowPrice) !== 0 ? <hr /> : null}
                                <Slider {...slickSettings}>
                                    {typeof listings !== "undefined" && listings.length > 0 ? listings.slice(0, 28).map((listing, index) => {
                                        if (typeof listing.bidsArray !== "undefined") {
                                            const highestBidAmount = findHighestBid(listing.bidsArray);
                                            return (
                                                <div key={index}>
                                                    <div style={{ padding: 11.25 }} className="col-12 col-sm-12 col-lg-12">
                                                        <div
                                                            className="single_advisor_profile wow fadeInUp"
                                                            data-wow-delay="0.2s"
                                                            style={{
                                                                visibility: "visible",
                                                                animationDelay: "0.2s",
                                                                animationName: "fadeInUp"
                                                            }}
                                                        >
                                                            {/* Team Thumb*/}
                                                            <div className="advisor_thumb">
                                                            <img src={listing.images[0].location} style={{ maxHeight: "250px", objectFit: "contain" }} alt="" />
                                                            </div>
                                                            {/* Team Details*/}
                                                            <div className="single_advisor_details_info">
                                                            <h6>{listing.lotType}</h6>
                                                            <p className="text-left" style={{ float: "left", textAlign: "left", position: "absolute", left: 0, bottom: 0 }}>$ {Math.floor(Number(listing.reserveBidPrice)) <= Math.floor(Number(highestBidAmount)) ? <em style={{ color: "green" }}>{typeof highestBidAmount !== "undefined" && highestBidAmount !== null && highestBidAmount > 0 ? addCommasToNumber(highestBidAmount) : addCommasToNumber(Math.floor(Number(listing.bidPrice)))}</em> : <em style={{ color: "red" }}>{typeof highestBidAmount !== "undefined" && highestBidAmount !== null && highestBidAmount > 0 ? addCommasToNumber(highestBidAmount) : addCommasToNumber(Math.floor(Number(listing.bidPrice)))}</em>}</p>
                                                            <p className="designation">{listing.title}</p>
                                                            <hr />
                                                            <button onClick={() => {
                                                                navigate(`/lot_page/${listing.id}`, { replace: true })
                                                            }} className="btn btn-primary">View</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }) : null}
                                </Slider>
                            </div>
                </Fragment>
            );
        } else {
            if (state.ready === true) {
                return (
                    <Fragment>
                        <div className="row">
                            <section
                                className="p-0 bg-img cover-background"
                                style={{ backgroundImage: "url(https://bootdey.com/img/Content/bg1.jpg)" }}
                            >
                                <div className="container-fluid d-flex flex-column">
                                    <div className="row align-items-center justify-content-center min-vh-100">
                                    <div className="col-md-12 col-lg-12 my-5">
                                        <div className="text-center error-page">
                                        <h1 className="mb-0 text-secondary" id="customheadedended">Ended.</h1>
                                        <h2 className="mb-4 text-white">Listing has expired/ended...</h2>
                                        <p className="text-center w-sm-80 mx-auto mb-4 text-white">
                                            This listing has already expired/ended. You may no longer access the data from this listing. Sorry for the inconvience.
                                        </p>
                                        <div>
                                            <Link to="/" style={{ color: "#fff" }} className="btn btn-info btn-lg me-sm-2 mb-2 mb-sm-0">
                                                Return Home
                                            </Link>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                    </Fragment>
                );
            }
        }
    }

    const generateOptions = () => {
        if (typeof listing !== "undefined" && listing !== null) {
            const valuesss = incrementValues.filter(value => value > Number(listing.currentBid))
            if (typeof state.listing.currentMaxBidder.amount !== "undefined" && state.listing.currentMaxBidder.bidder === authData.uniqueId) {
                return valuesss.filter((value) => Number(value) > Number(state.listing.currentMaxBidder.amount)).map(value => (
                    <option key={value} value={value}>
                        ${value.toLocaleString()}
                    </option>
                ));
            } else {
                return valuesss.filter((value) => value).map(value => (
                    <option key={value} value={value}>
                        ${value.toLocaleString()}
                    </option>
                ));
            }
        }
    };

    const handleProxyMaxBidPlace = () => {
        console.log("proxy bid :..  clicked", authData);

        const getMaxBidForUser = (listing, bidderID) => {
            // Check if bidsArray exists and is not empty
            if (!Array.isArray(listing.bidsArray) || listing.bidsArray.length === 0) {
                return { maxBidAmount: 0 };
            }
        
            // Filter bids for the specific bidderID
            const filteredBids = listing.bidsArray.filter(bid => bid.bidderID === bidderID);
        
            // Find the max bid amount from the filtered bids
            if (filteredBids.length > 0) {
                return filteredBids.reduce((max, bid) => {
                    const currentBidAmount = Number(bid.maxBidAmount);
                    return currentBidAmount > max.maxBidAmount ? bid : max;
                }, { maxBidAmount: 0 });  // Initialize with 0 if there are no bids from this bidder
            }
        
            // If no bids match the bidderID, return 0
            return { maxBidAmount: 0 };
        };        

        const maxBid = getMaxBidForUser(listing, authData.uniqueId);

        if (Number(state.maxBidAmount) !== 0) {
            // run logic to place bid
            const configuration = {
                auctionID,
                uniqueId: authData.uniqueId,
                bidAmount: state.maxBidAmount
            };

            axios.post(`${process.env.REACT_APP_BASE_URL}/place/proxy/max/bid/auction/listing`, configuration).then((res) => {
                if (res.data.notEnough === true) {
                    NotificationManager.info(res.data.message, 'Enter larger "bid amount"!', 3500);
                } else if (res.data.message === `You're already the high bidder, no bid placed.`) {
                    console.log("res.data", res.data);

                    setState(prevState => ({ ...prevState, maxBidAmount: 0, isPaneOpenLeft: false, proxyBidSheet: false }));

                    NotificationManager.warning(res.data.message, "You're already the HIGHEST bidder.", 3000);
                } else if (res.data.message === "Enter a larger bid to place a valid bid.") {
                    setState(prevState => ({ ...prevState, maxBidAmount: 0, isPaneOpenLeft: false, proxyBidSheet: false }));

                    NotificationManager.warning(res.data.message, "Place a higher value bid!", 3000);
                } else if (res.data.message === `You must enter a value more than the minumum start bid price.`) {
                    console.log("res.data", res.data);

                    setState(prevState => ({ ...prevState, maxBidAmount: 0, isPaneOpenLeft: false, proxyBidSheet: false }));

                    NotificationManager.warning("You must enter a value more than the minimum start bid price", "Place a HIGHER bid amount!", 3000);
                } else if (res.data.message === "Successfully sent your bid!") {
                    console.log("res.data", res.data);

                    const { listing } = res.data;

                    if (listing.currentWinner.bidderID === authData.uniqueId) {
                        triggerConfetti();
                    }
                    
                    setState(prevState => ({ ...prevState, listing, maxBidAmount: 0, isPaneOpenLeft: false, proxyBidSheet: false }));

                    newSocket.emit('newBidRecieved', JSON.stringify(listing));

                    NotificationManager.success('Successfully placed new bid on auction/listing.', 'Successfully placed bid.', 3000);
                } else {
                    console.log("err");

                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        } else {
            NotificationManager.warning('Select a proxy bid first', 'Select a bid!', 3000);
        }
    }

    const handleBidPlace = (e) => {
        if (typeof e !== "undefined") {
            e.preventDefault();
        };

        console.log("handleBidPlace clicked", authData);

        // if (listing.endDate <= new Date()) {
            const configuration = {
                auctionID,
                uniqueId: authData.uniqueId
            };
    
            axios.post(`${process.env.REACT_APP_BASE_URL}/place/bid/on/auction`, configuration).then((res) => {
                if (res.data.notEnough === true) {
    
                    NotificationManager.info(res.data.message, 'Enter larger "bid amount"!', 3500);
                } else if (res.data.message === "Bidding on this listing has come to an end.") {
                    console.log("res.data", res.data);
    
                    const { listing } = res.data;
                    
                    if (new Date(listing.endDate) >= new Date()) {
                        setState(prevState => ({ ...prevState, listing, bidAmount: 0, isPaneOpenLeft: false, bottomSheet: false }));
                    } else {
                        NotificationManager.warning('This listing has ended, no more bids are allowed.', 'Listing has ended, no more bidding/bids.', 3000);
                    }
                    // NotificationManager.warning('This listing has ended, no more bids are allowed.', 'Listing has ended, no more bidding/bids.', 3000);
                } else if (res.data.message === "Successfully sent your bid!") {
                    console.log("res.data", res.data);
    
                    const { listing } = res.data;

                    if (listing.currentWinner.bidderID === authData.uniqueId) {
                        triggerConfetti();
                    }
                    
                    if (new Date(listing.endDate) >= new Date()) {
                        setState(prevState => ({ ...prevState, listing, bidAmount: 0, isPaneOpenLeft: false, bottomSheet: false }));
                    } else {
                        NotificationManager.warning('This listing has ended, no more bids are allowed.', 'Listing has ended, no more bidding/bids.', 3000);
                    }

                    // startCountdown(listing.endDate);
    
                    newSocket.emit('newBidRecieved', JSON.stringify(listing));
    
                } else if (res.data.message === `You're already the high bidder, no bid placed.`) {
                    console.log("res.data", res.data);
    
                    const { listing } = res.data;
                    
                    if (new Date(listing.endDate) >= new Date()) {
                        setState(prevState => ({ ...prevState, listing, bidAmount: 0, isPaneOpenLeft: false, bottomSheet: false }));
                    } else {
                        NotificationManager.warning('This listing has ended, no more bids are allowed.', 'Listing has ended, no more bidding/bids.', 3000);
                    }
                    // NotificationManager.warning(res.data.message, "You're already the HIGHEST bidder.", 3000);
                } else if (res.data.message === `You must enter a value more than the minumum start bid price.`) {
                    console.log("res.data", res.data);
    
                    const { listing } = res.data;
                    
                    if (new Date(listing.endDate) >= new Date()) {
                        setState(prevState => ({ ...prevState, listing, bidAmount: 0, isPaneOpenLeft: false, bottomSheet: false }));
                    } else {
                        NotificationManager.warning('This listing has ended, no more bids are allowed.', 'Listing has ended, no more bidding/bids.', 3000);
                    }
                    // NotificationManager.warning("You must enter a value more than the minimum start bid price", "Place a HIGHER bid amount!", 3000);
                } else {
                    console.log("err");
    
                    // NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        // }
    }

    const { listing } = state;

    const handleBidRemoval = () => {
        console.log("handleBidRemoval", state.selected);

        const configggg = {
            bidId:  state.selected,
            uniqueId: authData.uniqueId
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/remove/bid/auction`, configggg).then((res) => {
            if (res.data.message === "Successfully removed your bid!") {
                console.log("res.data", res.data);

                const { listing } = res.data;

                setState(prevState => ({ ...prevState, listing, isPaneOpenLeft: false, selected: null }))

                // NotificationManager.success('Successfully gathered auction/listing data.', 'Successfully gathered listing.', 3000);
            } else if (res.data.message === "You are NOT an admin - cannot remove bid.") {
                NotificationManager.warning(res.data.message, 'Cannot remove bid - not admin.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }

    const closeTour = () => {
        setState(prevState => ({ ...prevState, isTourOpen: false }))

        markTourAsComplete(true);
    }

    const handleBidAmount = (currentAmountNum) => {
        let minBidIncrease;
        const bidAmountNum = Number(currentAmountNum);
        if (bidAmountNum < 5) {
            minBidIncrease = 1;
        } else if (bidAmountNum < 50) {
            minBidIncrease = 5;
        } else if (bidAmountNum < 200) {
            minBidIncrease = 10;
        } else if (bidAmountNum < 500) {
            minBidIncrease = 25;
        } else if (bidAmountNum < 1000) {
            minBidIncrease = 50;
        } else if (bidAmountNum < 2500) {
            minBidIncrease = 100;
        } else if (bidAmountNum < 5000) {
            minBidIncrease = 250;
        } else if (bidAmountNum < 10000) {
            minBidIncrease = 500;
        } else if (bidAmountNum < 50000) {
            minBidIncrease = 1000;
        } else if (bidAmountNum < 100000) {
            minBidIncrease = 2500;
        } else if (bidAmountNum < 250000) {
            minBidIncrease = 5000;
        } else {
            // Handle other bid ranges or set a default value
            minBidIncrease = 1;
        }

        return minBidIncrease;
    };

    console.log("listing", listing)

    let currentMaxCalc;
    let currentMax = listing !== null && _.has(listing, "currentBid") ? listing.currentBid : listing !== null ? listing.bidPrice : 0;

    if (typeof listing !== "undefined" && listing !== null) {
        currentMaxCalc = listing !== null && typeof listing.bidsArray !== "undefined" && listing.bidsArray.length > 0 ? currentMax + handleBidAmount(currentMax) : currentMax; // Update currentMaxCalc by adding the result of handleBidAmount.
    } else {
        currentMaxCalc = null;
    };

    console.log("listing.bidPrice", currentMax, currentMaxCalc);

    return (
        <Fragment>
            {typeof listing !== "undefined" && listing !== null && currentMax !== null && currentMaxCalc !== null ? <PureModal
                isOpen={state.modalIsOpen}
                onAfterOpen={() => {}}
                onRequestClose={() => setState(prevState => ({ ...prevState, modalIsOpen: false }))}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2>Please confirm your bid</h2>
                <p>All bids are contractual agreements to pay if you win the auction - placed bids MUST be paid</p>
                <table style={{ backgroundColor: "#ddd", width: "100%", margin: 12.25 }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Price</th>
                            <th>Calculation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ backgroundColor: "#eee" }}>
                            <td style={{ paddingLeft: 10 }}>Your Bid</td>
                            <td>${typeof currentMax === "undefined" ? listing.bidPrice : addCommasToNumber(currentMax)}</td>
                            <td>${typeof currentMaxCalc === "undefined" ? listing.bidPrice : addCommasToNumber(currentMaxCalc)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingLeft: 10 }}>Premium (20%)</td>
                            <td>${isNaN(Math.floor(currentMaxCalc * 0.20)) ? "----" : addCommasToNumber(Math.floor(currentMaxCalc * 0.20))}</td>
                            <td>${(isNaN(Math.floor(currentMaxCalc * 0.20) + Math.floor(currentMaxCalc)) ? "----" : addCommasToNumber(Math.floor(currentMaxCalc * 0.20) + Math.floor(currentMaxCalc)))}</td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={() => {
                    setState(prevState => ({ ...prevState, modalIsOpen: false }));

                    handleBidPlace();
                }} style={{ width: "100%" }} className="btn btn-primary">Submit your bid</button>
            </PureModal> : null}
            <SlidingPane
                closeIcon={<img src={require("../../assets/icons/closeicon.png")} style={{ width: 40, height: 40, maxHeight: 40, maxWidth: 40 }} />}
                isOpen={state.isPaneOpenLeft}
                title={width >= 775 ? "View previous bids and/or place a new bid!" : "View Bid History"}
                from="right"
                width={width <= 775 ? "100vw" : "80vw"}
                onRequestClose={() => setState(prevState => ({ ...prevState, isPaneOpenLeft: false }))}
            >
                <div className={width >= 775 ? "container" : ""} style={width >= 775 ? { zIndex: 999999999999 } : {  width: "100vw",
                    margin: "0 auto",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    zIndex: 999999999999 
                }}>
                    {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true && authData.admin === true ? <div className="row">
                        <div className="col-lg-12">
                            <p className="ptitleheader">Remove a bid by clicking the desired checkbox to the left of the bidding row and we'll remove it. You may only remove 5 bids throughout the lifespan of your account.</p>
                            {state.selected !== null ? <button onClick={() => handleBidRemoval()} className="btn btn-primary">Remove Bid</button> : null}
                        </div>
                    </div> : null}
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="">
                            <div className="table-responsive">
                            <table className="table project-list-table table-nowrap align-middle table-borderless">
                                <thead>
                                <tr>
                                    {width >= 775 ? <th scope="col" className="ps-4" style={{ width: 50 }}>
                                    {/* <div className="form-check font-size-16">
                                        <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="contacusercheck"
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="contacusercheck"
                                        />
                                    </div> */}
                                    </th> : null}
                                    {/* <th scope="col">Profile</th> */}
                                    <th scope="col">{width <= 775 ? "Name" : "Username"}</th>
                                    <th scope="col">{width <= 775 ? "Date" : "Bid Time/Date"}</th>
                                    <th scope="col">{width <= 775 ? "Amt." : "Bid Amount"}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {typeof state.listing !== "undefined" && state.listing !== null && _.has(state.listing, "bidsArray") && typeof state.listing.bidsArray !== "undefined" && state.listing.bidsArray.length > 0 ? 
                                    state.listing.bidsArray
                                        .sort((a, b) => new Date(a.datePlaced) - new Date(b.datePlaced)) // Sort by datePlaced, newest first
                                        .sort((a, b) => new Date(b.datePlaced) - new Date(a.datePlaced))
                                        .map((bidding, index) => {
                                            // if (!isNaN(bidding.bidAmount)) {
                                                return (
                                                    <Fragment key={index}>
                                                        <tr>
                                                            {width >= 775 ? <th scope="row" className="ps-4">
                                                                <div className="form-check font-size-16">
                                                                    {index === 0 ? <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id={"contactusercheck" + bidding.id} // Assuming bidding.id is unique for each checkbox
                                                                        checked={state.selected !== null && state.selected === bidding.id}
                                                                        value={state.selected !== null && state.selected === bidding.id}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setState(prevState => ({ ...prevState, selected: bidding.id }));
                                                                            } else if (state.selected === bidding.id) {
                                                                                setState(prevState => ({ ...prevState, selected: null }));
                                                                            }
                                                                        }}
                                                                    /> : null}
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={"contactusercheck" + bidding.id}
                                                                    />
                                                                </div>
                                                            </th> : null}
                                                            <td>
                                                                <span className="badge badge-soft-success mb-0">
                                                                    {bidding.bidderUsername}
                                                                </span>
                                                            </td>
                                                            <td>{width >= 775 ? `${moment(bidding.datePlaced).format("YYYY-MM-DD HH:mm:ss a")} ~ ` : ""} {moment(bidding.datePlaced).fromNow()}</td>
                                                            <td>${Number(bidding.bidAmount).toFixed(2)}</td>
                                                        </tr>
                                                    </Fragment>
                                                );
                                            // }
                                        }) 
                                    : null}
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </SlidingPane>
            <NavigationUniversalHelper />
            <Modal classNames="modalstretch" open={state.isModalOpen} onClose={() => setState(prevState => ({ ...prevState, isModalOpen: false }))} center>
                {state?.listing?.images?.length > 0 && (
                    <ImageGallery 
                        items={
                            state?.listing?.images?.length > 0 
                            ? state.listing.images.map((image) => ({
                                original: image.location,
                                thumbnail: image.location
                                })) 
                            : []
                        }
                    />
                )}
            </Modal>
            <header id="lot-nav">
                <div className="lot-container">
                    <div onClick={() => navigate("/current_auc")}>
                        <a style={{ color: "#fff" }} className="btn">Back</a>
                    </div>
                </div>
                {listing !== null ? <div className="header-right">
                    <div className="foot-links">
                        <li onClick={fetchNewListingAndRefreshPrevious} className="row hover">
                            <img src={require("../../assets/icons/arrow-left.png")} className="iconed-arrow" />
                        </li>
                        <li onClick={fetchNewListingAndRefresh} className="row hover" style={{ marginLeft: 10 }}>
                            <img src={require("../../assets/icons/arrow-right.png")} className="iconed-arrow" />
                        </li>
                    </div>
                </div> : null}
            </header>
            <form className="container card" style={{ paddingBottom: 62.5, paddingTop: 32.25, marginBottom: 45, marginTop: 45 }} id="lot1">
                {renderContentMain()}
                {Object.keys(authData).length > 0 && _.has(authData, "uniqueId") ? <Tour
                    steps={steps}
                    isOpen={state.isTourOpen}
                    onRequestClose={closeTour} 
                /> : null}
            </form>
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2025</div>
            <div className="overlay" />
        </Fragment>
    );
} 
const mapStateToProps = (state) => {
    return {
        authData: state.auth.data,
        tourData: state.tour.data
    }
};

export default connect(mapStateToProps, { markTourAsComplete, authentication })(LotPageHelper)
