import React, { Fragment, useCallback, useRef, useEffect, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import {
    useWindowSize
} from '@react-hook/window-size'                         
import {useDropzone} from 'react-dropzone';
import axios from "../../interceptor";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";

const ConsignWithUsComponent = (props) => {
    const [width, height] = useWindowSize()
    const navigate = useNavigate();
    const sectionRef = useRef(null);

    const [ state, setState ] = useState({
        acceptedFiles: [],
        zoomLevel: null,
        formData: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            emailAddress: "",
            itemTitle: "",
            itemDescription: "",
            itemCondition: "",
            additionalNotes: ""
        }
    })

    const onDrop = useCallback(acceptedFiles => {
        console.log("acceptedFiles", acceptedFiles);

        return new Promise(async (resolve, reject) => {
            const formData = new FormData();
    
            for (let index = 0; index < acceptedFiles.length; index++) {
                const image = acceptedFiles[index];

                formData.append(`files`, image);

                if ((acceptedFiles.length - 1) === index) {
                    try {
                        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload/misc/file/wo/saving/array`, formData);
                        
                        if (res.data.message === "Successfully uploaded images!") {
                            console.log(res.data);
        
                            const { passedValues } = res.data;

                            resolve(passedValues);
                        } else {
                            console.log("Err", res.data);
                            
                            NotificationManager.warning('Error attempting to upload your image/photo!', 'Try the action again - image upload failed.', 3000);

                            reject("Image upload failed");
                        }
                    } catch (err) {
                        console.log(err.message);
                        
                        reject(err);
                    }
                }
            }
        }).then((passedValues) => {
            NotificationManager.success("Successfully uploaded/updated your images!", "Successfully processed.", 3250);
            
            setState(prevState => ({ ...prevState, acceptedFiles: [...prevState.acceptedFiles, ...passedValues], loading: false }));
        })
    }, [])
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

    console.log("state", state);
    
    const handleSubmitConsignment = async (e) => {
        e.preventDefault();

        console.log("ran...");

        const {
            firstName,
            phoneNumber,
            lastName,
            emailAddress,
            itemTitle,
            itemDescription,
            itemCondition,
            additionalNotes
        } = state.formData;    

        const { acceptedFiles, formData } = state;

        if ((typeof itemCondition !== "undefined" && itemCondition.length > 0) && (typeof firstName !== "undefined" && firstName.length > 0)  && (typeof lastName !== "undefined" && lastName.length > 0) && (typeof phoneNumber !== "undefined" && phoneNumber.length > 0) && (typeof emailAddress !== "undefined" && emailAddress.length > 0) && (typeof itemTitle !== "undefined" && itemTitle.length > 0) && (typeof itemDescription !== "undefined" && itemDescription.length > 0) && (typeof additionalNotes !== "undefined" && additionalNotes.length > 0)) {
            console.log("this ran");
            
            const configuration = {
                acceptedFiles,
                formData
            }
    
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/process/consignment/form/data`, configuration);
                            
            if (res.data.message === "Consignment submission saved successfully.") {
                console.log(res.data);

                setState(prevState => ({
                    ...prevState,
                    acceptedFiles: [],
                    formData: {
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        emailAddress: "",
                        itemCondition: "",
                        itemTitle: "",
                        itemDescription: "",
                        additionalNotes: ""
                    }
                }))
                NotificationManager.success('Successfully processed your request and we will be in contact!', 'Successfully processed.', 3000);
            } else {
                console.log("Err", res.data);
                
                NotificationManager.warning('Error attempting to process your logic - please correct the inputs and try again!', 'Try the action again!', 3000);
            }
        } else {
            NotificationManager.warning("Complete each & every field before proceeding...", 'Fill out the form first', 3000);
        }
    };

    const detectZoom = () => {
        // Calculate zoom as percentage based on screen width and window inner width
        const zoom = Math.round((window.innerWidth / window.screen.width) * 100);

        setState(prevState => ({ ...prevState, zoomLevel: zoom }));
    };

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth', top: 0 });
        // Detect zoom on load
        detectZoom();

        // Add event listener for resizing (zoom changes trigger this)
        window.addEventListener("resize", detectZoom);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener("resize", detectZoom);
    }, []);

    console.log("zoomLevel", state.zoomLevel);

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className={state.zoomLevel >= 87 ? "container" : "container-fluid"}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                        margin: 20,
                        width: "100%"
                    }}
                >
                    {/* Left Section */}
                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div
                            style={{
                                flex: 1,
                                width: '100%',
                                height: '100%',
                                textAlign: 'left',
                                borderRadius: '10px',
                                backgroundColor: '#F7F7F7',
                                minHeight: state.zoomLevel >= 87 ? "100%" : "50vh",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                padding: width <= 775 ? "12.25px" : '0px 30px'
                            }}
                        >
                            <div style={{ height: 32.25 }} />
                            <h1 style={{ fontSize: '36px', marginBottom: '10px' }}>Welcome to</h1>
                            <h1
                                style={{
                                    fontSize: '36px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px',
                                }}
                            >
                                Chicago Violin Auction
                            </h1>
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginBottom: '20px',
                                    textAlign: 'left',
                                }}
                            >
                                Thank you for considering selling your bowed stringed instruments with us! Our team is dedicated to ensuring the best possible outcomes for your items, maximizing their market potential. 
                            </p>
                            <button
                                className="btn btn-blue"
                                style={{
                                    padding: '10px 75px',
                                    fontSize: '16px',
                                    backgroundColor: '#1E88E5',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    width: "100%"
                                }}
                                onClick={() => navigate("/sellers")}
                            >
                                {width <= 775 ? "Learn More" : "How to Get Involved"}
                            </button>
                            <div style={{ height: 32.25 }} />
                        </div>
                    </div>
                    {/* Right Section */}
                    <div
                        style={{ backgroundColor: '#fff', padding: 20 }}
                        className="col-md-8 col-lg-8 col-sm-12"
                    >
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={require('../../assets/images/CalcanusEditedPhoto1.png')}
                                alt="Violin Front"
                                style={width <= 775 ? {
                                    display: "none"
                                } : {
                                    height: state.zoomLevel >= 87 ? '100%' : '550px',
                                    maxWidth: "100%",
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={sectionRef} className="middle-content-backgrounded-custom container" style={{ padding: "25px 20px", marginBottom: "40px" }}>
                <div className="container mt-5">
                    <h2 className="text-center mb-5">Consignment Form</h2>
                    <div className="row" style={{ margin: "32.25px 0px" }}>
                        <div className="container">
                            <h3 className="text-center">Step 1</h3>
                            <h5 className="text-center">- Personal Details</h5>
                        </div>
                    </div>
                    <form>
                        <div className="row">
                            {/* Name Field */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="name" className="form-label">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={state.formData.firstName}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            firstName: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="name" className="form-label">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={state.formData.lastName}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            lastName: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                />
                            </div>

                            {/* Email Field */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="email" className="form-label">
                                Email Address
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={state.formData.emailAddress}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            emailAddress: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="phone" className="form-label">
                                Phone Number
                                </label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    value={state.formData.phoneNumber}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            phoneNumber: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                />
                            </div>
                        </div>
                        <div className="row" style={{ margin: "32.25px 0px" }}>
                            <div className="container">
                                <h3 className="text-center">Step 2</h3>
                                <h5 className="text-center">- Upload Files</h5>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 22.25 }}>
                            <div className="container">
                                <div style={{ padding: 15, paddingTop: 50, backgroundColor: "#fff", paddingBottom: 50 }}>
                                    <div style={{ border: "1.5px solid grey", padding: 22.25 }} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                            <p>Drop the files here ...</p> :
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                        }
                                    </div>
                                    <div className="row" style={{ marginTop: 22.25 }}>
                                        {typeof state.acceptedFiles !== "undefined" && state.acceptedFiles.length > 0 ? state.acceptedFiles.map((file, index) => {
                                            console.log("file", file);
                                            return (
                                                <Fragment key={index}>
                                                    <img onClick={() => {
                                                        setState(prevState => ({ ...prevState, acceptedFiles: prevState.acceptedFiles.filter((item) => {
                                                            return item.location !== file.location;
                                                        })}))
                                                    }} src={file.location} className="imageimage" />
                                                </Fragment>
                                            );
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ margin: "32.25px 0px" }}>
                            <div className="container">
                                <h3 className="text-center">Step 3</h3>
                                <h5 className="text-center">- Describe Item</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="item-title" className="form-label">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="item-title"
                                    value={state.formData.itemTitle}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            itemTitle: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="item-title" className="form-label">
                                    Item Condition
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="item-title"
                                    value={state.formData.itemCondition}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            itemCondition: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                />
                            </div>
                            {/* Item Description Field */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="item-description" className="form-label">
                                Item Description
                                </label>
                                <textarea
                                    className="form-control"
                                    id="item-description"
                                    rows="6"
                                    value={state.formData.itemDescription}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            itemDescription: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                    required
                                ></textarea>
                                </div>
                                {/* Auction Notes Field */}
                                <div className="col-md-6 mb-3">
                                <label htmlFor="notes" className="form-label">
                                Additional Notes
                                </label>
                                <textarea
                                    className="form-control"
                                    id="notes"
                                    rows="6"
                                    value={state.formData.additionalNotes}
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, formData: {
                                            ...prevState.formData,
                                            additionalNotes: e.target.value
                                        }}))
                                    }}
                                    placeholder=""
                                ></textarea>
                            </div>
                        </div>
                        <hr />
                        <div className="text-center">
                        <button onClick={handleSubmitConsignment} type="submit" style={{ borderRadius: 5, width: "100%" }} className="btn btn-primary btn-lg mt-3">
                            Submit Consignment
                        </button>
                        <div style={{ height: 50 }} />
                    </div>
                    </form>
                    </div>
            </div>
            <div className="container">
                <div className={width <= 775 ? "row" : "footer row"}>
                    {/* <div className="social-link"> */}
                        <div className="col-md-6 col-sm-12 col-lg-6">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/people/Chicago-Violin-Auction/61568371108590">
                                <img className="image-bottom" src={require("../../assets/images/johnagain.png")} alt="Facebook" />
                            </a>
                            <div style={{ height: 20 }} />
                            <span style={{ fontWeight: "bold" }}>Facebook</span>
                        </div>
                    {/* </div> */}
                    {/* <div className="social-link"> */}
                        <div className="col-md-6 col-sm-12 col-lg-6">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/chicagoviolinauction">
                                <img className="image-bottom" src={require("../../assets/images/consign.png")} alt="Facebook" />
                            </a>
                            <div style={{ height: 20 }} />
                            <span style={{ fontWeight: "bold" }}>Instagram</span>
                        </div>
                    {/* </div> */}
                </div>
            </div>
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2024</div>
            <div className="overlay" />
        </Fragment>
    );
} 

export default ConsignWithUsComponent;