import React, { Fragment } from "react";
import { motion, useInView } from "framer-motion";

const FeesAccordionHelper = ({ width, ref, isInView }) => {
    return (
        <Fragment>
            <div className={width <= 775 ? "" : "team-container"}>
                <div className="team-header">
                    <h1 style={{ fontSize: 52.25, marginLeft: 10, textAlign: "left" }}>Fees</h1>
                </div>
                <div>
                    <div className="middle-content-backgrounded" style={{  }}>
                        <div className="container" style={width <= 775 ? { margin: 0, padding: 0, backgroundColor: "#F7F7F7" } : { backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                            <div>
                                <div style={{ backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Buyer's Premium</p>
                                        <motion.p
                                            ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }} className="text-left">
                                            In addition to the hammer price (winning bid), buyers are required to pay a buyer's premium, set at 20% of the hammer price. The final purchase price billed to users includes the sum of the hammer price and the buyer's premium. This sum is calculated during the ‘Place a new bid!’ confirmation.                                         
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Taxes</p>
                                        <motion.p
                                            ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }}  className="text-left">
                                            The applicable sales tax rate for each transaction is determined by the location where the buyer takes possession of the item. Per Illinois tax regulations, our auction house collects sales tax on all items either picked up within the state or shipped to an Illinois address. Buyers should note that purchases shipped outside Illinois may incur additional taxes based on the destination; however, our auction house does not remit these taxes.                                  
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default FeesAccordionHelper;