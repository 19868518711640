import React, { Fragment } from "react";
import { motion, useInView } from "framer-motion";

const PaymentAccordionHelper = ({ width, ref, isInView}) => {
    return (
        <Fragment>
            <div className={width <= 775 ? "" : "team-container"}>
                <div className="team-header">
                    <h1 style={{ fontSize: 52.25, marginLeft: 10, textAlign: "left" }}>Payment</h1>
                </div>
                <div>
                    <div className="middle-content-backgrounded">
                        <div className="container" style={width <= 775 ? { margin: 0, padding: 0, backgroundColor: "#F7F7F7" } : { backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                            <div>
                                <div style={{ backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Payment Options</p>
                                        <motion.p ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }}  className="text-left">
                                            When you win an auction at Chicago Violin Auction, you will receive an invoice to be settled within seven days of the auction’s close. Invoices will be sent through email, but can also be viewed in the ‘Won/Lost Auctions’ page under ‘Profile & More.’ You can choose from several convenient payment methods including bank transfer, credit card, cashier's check from a bank, money order, or Zelle. Please note that if you pay by check, the item will only be released once the check has cleared. For those opting to use a credit card, a 2.9% service fee will be added to cover transaction costs.
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default PaymentAccordionHelper;