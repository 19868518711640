import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { connect } from "react-redux";
import "./styles.css";
import { authentication } from "../../../redux/actions/authentication/auth";
import _ from "lodash";
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size';
import { Box, Button, Typography } from "@mui/material";
import Dropdown from 'react-dropdown';


const options = [
    { label: "Profile", value: "/profile_settings" },
    { label: "Notices", value: "/notifications" },
    { label: "Won/Lost", value: "/ended_auctions" },
];

const NavigationUniversalHelper = ({ authData, authentication }) => {
    const [width, height] = useWindowSize()
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(true);
    const [ isOpen, setIsOpen ] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
  
    const handleNavIconClick = () => {
        toggleMenu();
    };

    const handleSelect = (selectedOption) => {
        navigate(selectedOption.value);
    };    

    const renderMainContent = () => {
        if (width <= 650) {
            return (
                <Fragment>
                    <style>
                        {`
                        .underline-hover {
                            font-weight: bold; /* Makes the text bold */
                            text-decoration: none; /* Default state with no underline */
                            transition: border-bottom 0.3s ease; /* Smooth transition for hover */
                        }

                        .underline-hover:hover {
                            border-bottom: 2px solid currentColor; /* Adds underline on hover */
                        }
                        `}
                    </style>
                            <header>
                                <div style={{ paddingTop: 12.5, paddingBottom: 12.5 }} className="logo-container">
                                    <Link to="/">
                                        <img
                                        src={require("../../../assets/CVA Logo Bold Text.jpg")}
                                        alt="CVA logo"
                                        className="logo"
                                        />
                                    </Link>
                                </div>
                                <div className={menuOpen === true ? "header-right" : "header-right-full"}>
                                    {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true ? <div className="display-none" id="absolutebtn" onClick={() => {
                                            authentication({});

                                            navigate("/");
                                        }}>
                                        <p id="redbutton" className="btn" style={{ color: "#fff", marginTop: -10 }}>Logout</p>
                                    </div> : <Link style={{ marginTop: 15 }} to="/login" id="absolutebtnlogin"><p style={{ color: "#fff", marginTop: -15 }} className="btn">Login</p></Link>}
                                    {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true ? <a id="absolutebtntwo" onClick={() => navigate("/profile_settings")} className="profile-icon-container hover">
                                        <img className="display-none" src={require("../../../assets/icons/profile.png")} style={{ width: 45, height: 45, marginTop: -10 }} />
                                    </a> : null}
                                    <nav id="nav-bar">
                                        <div className="nav-menu">
                                            <div className="nav-icon" onClick={handleNavIconClick}>
                                                <img
                                                src={require("../../../assets/Navigation Menu.png")}
                                                alt="Nav icon"
                                                />
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </header>
                            <Sidebar collapsedWidth={0} toggled={menuOpen} collapsed={menuOpen}>
                                <Menu
                                    menuItemStyles={{
                                        button: {
                                            [`&.active`]: {
                                                backgroundColor: '#13395e',
                                                color: '#b6c8d9',
                                            },
                                        }
                                    }}
                                >
                                    <div style={{ marginBottom: "25px" }} onClick={toggleMenu}>
                                        <img style={{ maxWidth: 50, maxHeight: 50 }} src={require("../../../assets/icons/closeicon.png")} />
                                    </div>
                                    <MenuItem component={<Link to="/" />}> Homepage</MenuItem>
                                    <MenuItem component={<Link to="/buyers" />}> Buyers</MenuItem>
                                    <MenuItem component={<Link to="/sellers" />}> Sellers</MenuItem>
                                    <SubMenu label="About & Contact">
                                        {/* <MenuItem component={<Link to="/messaging" />}> Messaging/Chat</MenuItem> */}
                                        <MenuItem component={<Link to="/about_us" />}> About Us</MenuItem>
                                        <MenuItem component={<Link to="/contact" />}> Contact Us</MenuItem>
                                    </SubMenu>
                                    <SubMenu label="Profile & More">
                                        <MenuItem component={<Link to="/profile_settings" />}>Profile & Settings</MenuItem>
                                        <MenuItem component={<Link to="/notifications" />}> Notifications</MenuItem>
                                        <MenuItem component={<Link to="/ended_auctions" />}> Won/Lost Auctions</MenuItem>
                                    </SubMenu>
                                    <SubMenu label="Terms of Use">
                                        {/* <MenuItem component={<Link to="/messaging" />}> Messaging/Chat</MenuItem> */}
                                        <MenuItem component={<Link to="/terms_and_conditions" />} className="underline-hover"> Terms of Use</MenuItem>
                                        <MenuItem component={<Link to="/privacy_policy" />} className="underline-hover"> Privacy Policy</MenuItem>
                                        <MenuItem component={<Link to="/cookie_policy" />} className="underline-hover"> Cookie Policy</MenuItem>
                                    </SubMenu>
                                    <MenuItem component={<Link to="/consign" />}> Consign with us!</MenuItem>
                                    <MenuItem component={<Link to="/current_auc" />}> Current Auction</MenuItem>
                                    {_.has(authData, "admin") && authData.admin === true ? <MenuItem component={<Link to="/admin_panel" />}> Admin Panel</MenuItem> : null}
                                    {width <= 775 ? (typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true) ? <Fragment>
                                        <MenuItem onClick={() => {
                                            authentication({});

                                            navigate("/");
                                        }}> Sign-Out</MenuItem>
                                    </Fragment> : <Fragment>
                                        <MenuItem component={<Link to="/login" />}> Sign-Up/In</MenuItem>
                                    </Fragment> : null}
                                    <div style={{ height: 25 }} />
                                    {width <= 775 ? <Link to="/">
                                        <img src={require("../../../assets/CVA Logo Bold Text.jpg")} alt="CVA logo" className="logo" />
                                    </Link> : null}
                                </Menu>
                            </Sidebar>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#F7F7F7" }}>
                        <div className="container">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <div style={{ display: "inline-flex", alignItems: "center", gap: "50px" }}>
                                    
                                        <span style={{ fontFamily: "Poppins, Helvetica", fontSize: "16px" }}>
                                            <Dropdown
                                                options={options}
                                                onChange={handleSelect}
                                                value={options[0]}
                                                placeholder="Profile Options"
                                            />
                                        </span>
                                        <span style={{ fontFamily: "Poppins, Helvetica", fontSize: "16px" }}>
                                            <Link style={{ color: "#000" }} to="/about_us">
                                                About Us
                                            </Link>
                                        </span>
                                        <span style={{ fontFamily: "Poppins, Helvetica", fontSize: "16px" }}>
                                            <Link style={{ color: "#000" }} to="/contact">
                                                Contact Us
                                            </Link>
                                        </span>
                                        {typeof authData !== "undefined" &&
                                        _.has(authData, "verficationCompleted") &&
                                        authData.verficationCompleted === true &&
                                        Object.keys(authData).length >= 6 ? (
                                            <button
                                            style={{
                                                background: "darkred",
                                                borderRadius: "5.5px",
                                                width: "149px",
                                                height: "45px",
                                                fontFamily: "Poppins, Helvetica",
                                                fontWeight: "600",
                                                fontSize: "16.1px",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                authentication({});
                                            }}
                                            >
                                            Log Out
                                            </button>
                                        ) : (
                                            <button
                                            style={{
                                                background: "#2C849A",
                                                borderRadius: "5.5px",
                                                width: "149px",
                                                height: "45px",
                                                fontFamily: "Poppins, Helvetica",
                                                fontWeight: "600",
                                                fontSize: "16.1px",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => navigate("/login")}
                                            >
                                            Login
                                            </button>
                                        )}
                                        </div>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#fff" }}>
                        <div className="container-fluid">
                            {/* Logo Section */}
                            <a className="navbar-brand" onClick={() => navigate("/")}>
                            <img
                                src={require("../../../assets/CVA Logo Bold Text.jpg")}
                                alt="Logo"
                                className="d-inline-block align-text-top"
                                style={{ height: '71px', width: "133px", objectFit: "contain" }} // Adjust size as needed
                            />
                            </a>

                            {/* Navigation Links */}
                            <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link nav-link-spacing" onClick={() => navigate("/")}>
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-link-spacing" onClick={() => navigate("/current_auc")}>
                                        Auctions
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-link-spacing" onClick={() => navigate("/buyers")}>
                                        Buyers
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-link-spacing" onClick={() => navigate("/sellers")}>
                                        Sellers
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-link-spacing" onClick={() => navigate("/consign")}>
                                        Consign
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-link-spacing" onClick={() => navigate("/calendar")}>
                                        Calendar
                                    </a>
                                </li>
                                {_.has(authData, "admin") && authData.admin === true ? <li className="nav-item">
                                    <a style={{ fontWeight: "bold" }} className="nav-link nav-link-spacing" onClick={() => navigate("/admin_panel")}>
                                        Admin Panel
                                    </a>
                                </li> : null}
                            </ul>
                            {/* Search Bar */}
                            {/* <form className="d-flex">
                                <input
                                    className="form-control me-2"
                                    type="search"
                                    style={{ width: "100%", minWidth: "25vw" }}
                                    placeholder="Search"
                                    aria-label="Search"
                                />
                                <button style={{ borderRadius: 5, color: "#fff", padding: "4.25px 12.15px" }} className="btn btn-outline-secondary" type="submit">
                                    Search
                                </button>
                            </form> */}
                            </div>
                        </div>
                        </nav>
                </Fragment>
            );
        }
    }
    return (
        <Fragment>
            {renderMainContent()}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
    }
};

export default connect(mapStateToProps, { authentication })(NavigationUniversalHelper);
