import React, { useEffect } from 'react';
import 'react-notifications/lib/notifications.css';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-tabs/style/react-tabs.css';
import 'react-phone-number-input/style.css';
import 'react-responsive-modal/styles.css';
import "react-image-gallery/styles/css/image-gallery.css";
import "easymde/dist/easymde.min.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import "react-multi-carousel/lib/styles.css";
import "@fontsource/abhaya-libre";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "animate.css";
import 'rc-collapse/assets/index.css';
import 'react-dropdown/style.css';
import 'react-spring-bottom-sheet/dist/style.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LandingPageMainPage from "./pages/landing/index";
import LoginPage from "./pages/auth/login/index";
import ContactPage from "./pages/contact/index";
import BuyersPage from "./pages/buyers/index";
import AboutPage from "./pages/about/index";
import AuctionIndividualPage from "./pages/auction/individual/index";
import LotPage from "./pages/lotpage/index";
import SellersPage from "./pages/sellers/index";
import { NotificationContainer } from 'react-notifications';
import RegisterPage from "./pages/auth/register/index";
// import MessagingMainPage from "./pages/messaging/index";
import AdminPanelPage from "./pages/admin/index";
import CreateNewAuctionPage from "./pages/auction/create/index";
import 'react-responsive-pagination/themes/classic.css';
import "react-sliding-pane/dist/react-sliding-pane.css";
import ConfirmEmailPage from "./pages/auth/email/confirm";
import ForgotPasswordPage from "./pages/auth/forgotPassword/forgot";
import { connect } from "react-redux";
import _ from "lodash";
import ProfileSettingsPage from './pages/profile/main';
import EndedWonLostAuctionsPage from "./pages/auction/ended/index";
import 'react-credit-cards/es/styles-compiled.css';
import { StripeProvider } from 'react-stripe-elements';
import "react-datetime/css/react-datetime.css";
import NotificationsPage from "./pages/notifications/index";
import EntireCatalogMainPage from "./pages/auction/entire/index";
import TermsAndConditionsPage from "./pages/termsConditions/index";
import PrivacyPolicyPage from "./components/privacyPolicy/index.js";
import CookiePolicyPage from "./components/cookiesPolicy/index.js";
import ManageListingAuctionEndedPage from "./pages/auction/ended/manage.js";
import ShippingLabelsPage from "./pages/auction/shipping/labels.js";
import RestrictedPage from "./pages/restricted/restricted.js";
import LotActiveEditPagePage from "./pages/auction/edit/editListing.js";
import ReactGA from "react-ga4";
import ConsignWithUs from "./pages/consign/index.js";
import CalendarPage from "./pages/calendar/index.js";
import Clarity from '@microsoft/clarity';
import AutoLogout from './autoLogout.js';
import ModifyCalendarEventsPage from "./pages/addToCalendar/index.js"
import "./interceptor.js";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";



if (process.env.NODE_ENV !== "development") console.log = () => {};

const App = ({ authData }) => {
  const checkAuthed = (num) => {
    if (typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true) {
      if (authData.admin === true) {
        switch (num) {
          case 1:
            return <EndedWonLostAuctionsPage />;
            break;
          case 2:
            return <AdminPanelPage />;
            break;
          case 3:
            return <NotificationsPage />;
            break;
          case 5:
            return <CreateNewAuctionPage />;
            break;
          case 6:
            return <ShippingLabelsPage />;
            break;
          case 7:
            return <ManageListingAuctionEndedPage />;
            break;
          case 8:
            return <LotActiveEditPagePage />;
            break;  
          case 9:
            return <ProfileSettingsPage />
            break;  
          default:
            break;
        }
      } else {
        switch (num) {
          case 1:
            return <EndedWonLostAuctionsPage />;
            break;
          case 3:
            return <NotificationsPage />;
            break;
          case 8:
            return <Navigate to="/restricted" />;
            break;  
          case 9:
            return <ProfileSettingsPage />;
            break;
          default:
            return <Navigate to="/restricted" />;
            break;
        }
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    ReactGA.send("pageview");

    const projectId = "pjthznjqnq"

    Clarity.init(projectId);
  });
  return (
    <div className="App">
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
        <div>
          <NotificationContainer/>
          <AutoLogout timeout={14400000} />  {/* 4 hours of inactivty then log out */}
          <BrowserRouter basename={'/'}>
            <Routes>
              <Route exact path={`/`} element={<LandingPageMainPage />}/>
              <Route exact path={`/register`} element={<RegisterPage />}/>
              <Route exact path={`/login`} element={<LoginPage />}/>
              <Route exact path={`/confirm-email`} element={<ConfirmEmailPage />}/>
              <Route exact path={`/contact`} element={<ContactPage />}/>
              <Route exact path={`/forgot-password`} element={<ForgotPasswordPage />}/>
              <Route exact path={`/lot_page/:id`} element={<LotPage />}/>
              <Route exact path={`/ended_auctions`} element={checkAuthed(1)}/>
              <Route exact path={`/sellers`} element={<SellersPage />}/>
              <Route exact path={`/about_us`} element={<AboutPage />}/>
              <Route exact path={`/entire_catalog`} element={<EntireCatalogMainPage />}/>
              <Route exact path={`/buyers`} element={<BuyersPage />}/>
              <Route exact path={`/terms_and_conditions`} element={<TermsAndConditionsPage />}/>
              <Route exact path={`/privacy_policy`} element={<PrivacyPolicyPage />}/>
              <Route exact path={`/cookie_policy`} element={<CookiePolicyPage />}/>
              <Route exact path={`/consign`} element={<ConsignWithUs />}/>
              <Route exact path={`/modify_calendar_events`} element={<ModifyCalendarEventsPage />}/>
              <Route exact path={`/notifications`} element={checkAuthed(3)}/>
              {/* <Route exact path={`/messaging`} element={checkAuthed() ? <MessagingMainPage /> : <Navigate to="/login" />}/> */}
              <Route exact path={`/admin_panel`} element={checkAuthed(2)}/>
              <Route exact path={`/restricted`} element={<RestrictedPage />}/>
              <Route exact path={`/profile_settings`} element={checkAuthed(9)}/>
              <Route exact path={`/current_auc`} element={<AuctionIndividualPage />}/>
              <Route exact path={`/create_new_auction`} element={checkAuthed(5)}/>
              <Route exact path={`/shipping/labels`} element={checkAuthed(6)}/>
              <Route exact path={`/manage/listing/ended/:id`} element={checkAuthed(7)}/>
              <Route exact path={`/lot_page_edit/:id`} element={checkAuthed(8)}/>
              <Route exact path={`/calendar`} element={<CalendarPage />} />
            </Routes>
          </BrowserRouter>
        </div>
      </StripeProvider>
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log("map state props state : ", state)
  return {
      authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
  }
};

export default connect(mapStateToProps, { })(App);
