import React, { Fragment } from "react";
import { motion, useInView } from "framer-motion";

const BiddingHelper = ({ width, ref, isInView }) => {
    return (
        <Fragment>
            <div className={width <= 775 ? "" : "team-container"}>
                <div className="team-header">
                    <h1 style={{ fontSize: 52.25, marginLeft: 10, textAlign: "left" }}>Bidding</h1>
                </div>
                <div className={width <= 775 ? "" : "team-content"}>
                    <div className="middle-content-backgrounded">
                        <div className="container" style={width <= 775 ? { margin: 0, padding: 0, backgroundColor: "#F7F7F7" } : { backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                            <div>
                                <div style={{ backgroundColor: "#F7F7F7", margin: 0, padding: 0 }}>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Registration Form</p>
                                        <motion.p ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }} className="text-left">
                                            In order to bid, users must complete a bidder registration form found on our registration page. As part of the bidder registration process, a registration fee of $1.00 will be placed on your card. This serves as a measure to verify the validity of your payment method and will serve as your primary payment card on file. Verification and storage of card details are not handled by our servers, but by our vendor, Stripe™. Lastly, the user is required to provide a valid photo ID as part of the bidder registration process, such as a drivers license or passport, in order to complete the registration process.
                                        </motion.p>
                                    </div>
                                    <div className="faq-item">
                                        <p className="text-left top-faq-text">Bidding Process</p>
                                        <motion.p ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }} className="text-left">
                                            Once you're registered as a bidder with The Chicago Violin Auction, placing a bid is a straightforward process. Here's a step-by-step guide to help you get started:
                                        </motion.p>
                                        <motion.ol ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isInView ? 1 : 0 }}
                                            transition={{ duration: 1, ease: "easeInOut" }}  style={{ margin: 0, padding: 15 }}>
                                            <li>
                                                <strong>1. Browse the Catalog:</strong> Explore our auction catalog to find the instrument or bow that catches your eye. Each item will have descriptions, high-quality photographs, and condition reports to help you make an informed decision. Specific listings can be bookmarked by clicking <em style={{ fontWeight: "bold" }}>'Add to Favorites.'</em> Favorited listings can be found under <em style={{ fontWeight: "bold" }}>'Bookmarked Listing(s)'</em> in the <em style={{ fontWeight: "bold" }}>'Profile Settings'</em> tab. Questions regarding lots can be directed through our <em style={{ fontWeight: "bold" }}>'Contact Us'</em> portal or via email at <a href="mailto:info@chicagoviolinauction.com">info@chicagoviolinauction.com</a>.
                                            </li>
                                            <li>
                                                <strong>2. Proxy Bids:</strong> If you have decided on the maximum amount you're willing to bid for the item, enter this amount into the bidding platform. Our system will then automatically bid on your behalf, incrementally increasing your bid as necessary to keep you in the lead up to your maximum bid amount. It is important to note that proxy bids cannot be retracted.
                                            </li>
                                            <li>
                                                <strong>3. Monitor the Auction:</strong> Keep an eye on the auction to see how the bidding progresses through live updates and the <em style={{ fontWeight: "bold" }}>'Bidding History'</em> menu. You'll also receive email notifications if you've been outbid. Bidding notifications can also be monitored on the <em style={{ fontWeight: "bold" }}>'Notifications'</em> page under <em style={{ fontWeight: "bold" }}>'Profile & More.'</em>
                                            </li>
                                            <li>
                                                <strong>4. Extensions:</strong> Bids placed in the last 10 minutes of the auction will reset the countdown timer to 10 minutes. The timer will refresh automatically, though due to how browsers work, users are encouraged to manually refresh the page to check if they are in the winning position.
                                            </li>
                                            <li>
                                                <strong>5. Winning the Auction:</strong> If you're the highest bidder when the auction closes, congratulations! You'll receive confirmation of your winning bid and instructions on how to proceed with payment and shipping. Unsure whether you won? Visit the <em style={{ fontWeight: "bold" }}>'Won/Lost Auctions'</em> page under <em style={{ fontWeight: "bold" }}>'Profile & More.'</em>
                                            </li>
                                            <li>
                                                <strong>6. Payment and Collection:</strong> Payment options can be found in our shipping and payment section. Once payment is received, you can collect your winning item either in Chicago in person or arrange for shipping.
                                            </li>
                                        </motion.ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default BiddingHelper;