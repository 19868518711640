import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import { NotificationManager } from 'react-notifications';
import axios from "../../../interceptor";
import { Link, useNavigate } from "react-router-dom";
import { authentication } from "../../../redux/actions/authentication/auth";
import { connect } from "react-redux";
import _ from "lodash";
import LoadingOverlay from 'react-loading-overlay';
import "./styles.css";
import { forgotPasswordRemember } from "../../../redux/actions/misc/index";

const LoginHelperComponent = ({ authentication, emailRemembered, forgotPasswordRemember }) => {
    const navigate = useNavigate();

    const [ state, setState ] = useState({
        email: "",
        password: "",
        isLoading: false,
        rememberMe: typeof emailRemembered !== "undefined" && emailRemembered.length > 0 ? true : false
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSubmissionLogin = (e) => {
        e.preventDefault();
        
        setState(prevState => ({ ...prevState, isLoading: true }))

        const { password, email } = state;

        if ((typeof email !== "undefined" && email.length > 0 && validateEmail(email)) && (typeof password !== "undefined" && password.length > 0)) {
            console.log("valid");

            const config = {
                email,
                password
            }

            axios.post(`${process.env.REACT_APP_BASE_URL}/login`, config).then((res) => {
                const { user, token } = res.data;

                if (_.has(user, "verficationCompleted") && user.verficationCompleted === false) {
                    setTimeout(() => {
                        navigate("/confirm-email");
                    }, 1750);
                } else if (res.data.message === "Successfully authenticated!") {
                    authentication({
                        ...user,
                        token
                    });

                    setState(prevState => ({ ...prevState, isLoading: false }))

                    NotificationManager.success('Successfully authenticated/logged-in!', 'Successfully authenticated.', 3000);

                    if (state.rememberMe === true) {
                        forgotPasswordRemember(user.email);
                    };

                    setTimeout(() => {
                        navigate("/");
                    },  3000);
                } else {
                    console.log("err");

                    setState(prevState => ({ ...prevState, isLoading: false }))

                    NotificationManager.warning('Your credentials did not match, please try again.', 'Invalid credentials.', 3000);
                }
            })
        } else { 
            console.log("invalid.");

            setState(prevState => ({ ...prevState, isLoading: false }))
            
            NotificationManager.error('An error occurred while processing your credentials...', 'Error during authentication', 3000);
        }
    }

    useEffect(() => {
        if (typeof emailRemembered !== "undefined" && emailRemembered.length > 0) {
            setState(prevState => ({ ...prevState, email: emailRemembered }));
        }
    }, []);

    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}
            <div className="login-content">
                <form id="login-form">
                    <h2>Log-in to Your Account</h2>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="YOUR EMAIL"
                        required=""
                        value={state.email}
                        onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                    />
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="YOUR PASSWORD"
                        required=""
                        value={state.password}
                        onChange={(e) => setState(prevState => ({ ...prevState, password: e.target.value }))}
                    />
                    <div className="remember-forgot">
                        <label>
                            <input value={state.rememberMe} onChange={(e) => {
                                setState(prevState => ({ ...prevState, rememberMe: e.target.checked }))
                            }} type="checkbox" id="remember-me" name="remember-me" />
                            Remember Me
                        </label>
                        <Link to="/forgot-password" className="forgot-password">
                            Forgot Password?
                        </Link>
                    </div>
                    <a onClick={handleSubmissionLogin}>
                        <p style={{ color: "#fff", width: "100%" }} className="btn">Login</p>
                    </a>
                    <Link to="/register" style={{ fontWeight: "bold" }} onClick={null}>
                        <p style={{ color: "#00", width: "100%", marginTop: -17.5, backgroundColor: "#fff" }} className="btn btn-white">Create new account</p>
                    </Link>
                </form>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth !== "undefined" && typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null,
        emailRemembered: typeof state.misc !== "undefined" && typeof state.misc.data !== "undefined" ? state.misc.data : null,
    }
};

export default connect(mapStateToProps, { authentication, forgotPasswordRemember })(LoginHelperComponent);