import React from 'react';
import backgroundImage from '../../../../assets/images/Rectangle-21.png';
import { Link, useNavigate } from "react-router-dom";
import {
    useWindowSize
} from '@react-hook/window-size';
import { motion } from "framer-motion";


const BannerHomepage = (props) => {
    const navigate = useNavigate();
    const [width, height] = useWindowSize();
    

    return (
        <div
            className={width <= 1800 ? 'container-fluid' : 'container'}>
            <div className="row h-100">
                {/* Left Section */}
                <div
                    className="col-md-4 d-flex flex-column justify-content-center align-items-start"
                    style={{
                        background: '#F8EBE0', // White column
                        padding: 80,
                    }}
                >
                    <motion.h2
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.75 }} className="mb-4 text-dark text-left" style={{ fontSize: 40, fontWeight: "bold" }}>
                        Thank You for Our Debut Sale
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.75 }} className="mb-4 text-dark text-left" style={{ marginLeft: -13.25, fontSize: 20 }}>
                        Thank you to all our bidders and participants in our recent auction and debut sale. Now accepting consignments for our next sale!
                    </motion.p>
                    <p className="mb-4 text-dark text-left">
                        <img
                            src={require("../../../../assets/icons/icons8-calendar-100.png")}
                            className="image-custom-landing"
                            alt="Calendar"
                        />
                        <span> Anytime</span>
                        <br />
                        <hr />
                        <img
                            src={require("../../../../assets/icons/icons8-location-94.png")}
                            className="image-custom-landing"
                            alt="Location"
                        />
                        <span> Anywhere</span>
                    </p>
                    <button className="btn btn-blue" onClick={() => navigate("/consign")} style={{ maxWidth: "250px", color: "#fff" }}>
                        Consign Now
                    </button>
                </div>

                {/* Right Section */}
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12">
                    <img
                        src={backgroundImage}
                        alt="Background"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default BannerHomepage;
