import React, { Fragment, useState, useEffect } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import "./styles.css";
import Select from 'react-select';
import ImageUploading from 'react-images-uploading';
import { NotificationManager } from 'react-notifications';
import api from "../../../interceptor";
import Datetime from 'react-datetime';
import Switch from "react-switch";
import SimpleMdeReact from "react-simplemde-editor";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

const shippingOptions = [
    { "label": "USPS", "value": "Postal Services" },
    { "label": "UPS", "value": "Standard Ground Shipping" },
    { "label": "FedEx", "value": "Standard Ground Shipping" },
    { "label": "DHL", "value": "Standard Ground Shipping" },
    { "label": "LTL Freight", "value": "Freight Shipping" },
    { "label": "White Glove Delivery", "value": "Specialized Moving Companies" },
    { "label": "Local Courier", "value": "Courier Services" },
    { "label": "International Courier", "value": "International Shipping" },
    { "label": "Custom Packaging Services", "value": "Custom Crating" },
    { "label": "Palletized Freight", "value": "Freight Services with Palletization" },
    { "label": "Shipping Insurance", "value": "Insurance Options" },
    { "label": "Customer Pickup", "value": "Local Pickup" }
];

const optionsCategory = [{
    label: "Violin",
    value: "violin"
}, {
    label: "Viola",
    value: "viola"
}, {
    label: "Cello",
    value: "cello"
}, {
    label: "Violin Bow",
    value: "violin-bow"
}, {
    label: "Viola Bow",
    value: "viola-bow"
}, {
    label: "Cello Bow",
    value: "cello-bow"
}, {
    label: "Bass Bow",
    value: "bass-bow"
}, {
    label: "Miscellaneous",
    value: "miscellaneous"
}];


const LotActiveEditPageHelper = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ state, setState ] = useState({
        lotType: "",
        title: "",
        retailValue: "",
        selectedCategory: null,
        lotNumber: "",
        itemDescription: "",
        bidPrice: 0,
        reserveBidPrice: 0,
        listing: null,
        user: null,
        active: false,
        buyItNowPrice: 0,
        isLoading: false,
        shippingCost: 0,
        shippingOption: null,
        images: [],
        imagesUploaded: [],
        startDate: null,
        endDate: null
    });

    console.log("state", state);

    useEffect(() => {
        const configuration = {
            params: {
                uniqueId: id
            }
        }
    
        api.get(`${process.env.REACT_APP_BASE_URL}/fetch/listing/individual/active`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listing!") {
                console.log("res.data", res.data);
    
                const { listing, user } = res.data;
    
                setState(prevState => ({ ...prevState, listing, 
                    lotType: typeof listing !== "undefined" && _.has(listing, "lotType") ? listing.lotType : state.lotType,
                    retailValue: typeof listing !== "undefined" && _.has(listing, "retailValue") ? listing.retailValue : state.retailValue,
                    lotNumber: typeof listing !== "undefined" && _.has(listing, "lotNumber") ? listing.lotNumber : state.lotNumber,
                    itemDescription: typeof listing !== "undefined" && _.has(listing, "itemDescription") ? listing.itemDescription : state.itemDescription,
                    bidPrice: typeof listing !== "undefined" && _.has(listing, "bidPrice") ? listing.bidPrice : state.bidPrice,
                    title: typeof listing !== "undefined" && _.has(listing, "title") ? listing.title : state.title,
                    active: typeof listing !== "undefined" && _.has(listing, "active") ? listing.active : state.active,
                    reserveBidPrice: typeof listing !== "undefined" && _.has(listing, "reserveBidPrice") ? listing.reserveBidPrice : state.reserveBidPrice,
                    buyItNowPrice: typeof listing !== "undefined" && _.has(listing, "buyItNowPrice") ? listing.buyItNowPrice : state.buyItNowPrice,
                    shippingCost: typeof listing !== "undefined" && _.has(listing, "shippingCost") ? listing.shippingCost : state.shippingCost,
                    shippingOption: typeof listing !== "undefined" && _.has(listing, "shippingOption") ? listing.shippingOption : state.shippingOption,
                    images: typeof listing !== "undefined" && _.has(listing, "images") ? listing.images : state.images,
                    startDate: typeof listing !== "undefined" && _.has(listing, "startDate") ? listing.startDate : state.startDate,
                    endDate: typeof listing !== "undefined" && _.has(listing, "endDate") ? listing.endDate : state.endDate,
                    selectedCategory: typeof listing !== "undefined" && _.has(listing, "selectedCategory") ? listing.selectedCategory : state.selectedCategory
                }));
    
                // NotificationManager.success('Successfully fetched/gathered ended listing!', 'Successfully gathered listing.', 3000);
            } else {
                console.log("err");
    
                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, [])

    const handleCreationListing = async (e) => {
        e.preventDefault();

        console.log("handleCreationListing");

        setState(prevState => ({ ...prevState, isLoading: true }))

        handleImageUpload().then(async passedValues => {
            // Do something with passedValues
            console.log('Passed Values images --- :', passedValues);

            NotificationManager.success('Successfully uploaded/updated image(s)!', 'Uploaded image(s) successfully.', 3000);

            const {
                lotType,
                retailValue,
                lotNumber,
                itemDescription,
                bidPrice,
                title,
                active,
                reserveBidPrice,
                buyItNowPrice,
                shippingCost,
                shippingOption,
                startDate,
                endDate,
                selectedCategory
            } = state;

            const configuration = {
                lotType,
                retailValue,
                lotNumber,
                itemDescription,
                bidPrice,
                title,
                active,
                reserveBidPrice,
                buyItNowPrice,
                shippingCost,
                shippingOption,
                images: passedValues,
                startDate,
                selectedCategory,
                endDate,
                listingID: id
            };

            const res = await api.post(`${process.env.REACT_APP_BASE_URL}/save/auction/details/individual/listing/modification`, configuration);
                        
            if (res.data.message === "Successfully modified listing details!") {
                console.log(res.data);
                
                navigate("/admin_panel");
            } else {
                console.log("Err", res.data);

                setState(prevState => ({ ...prevState, isLoading: false }))
            }
        }).catch(error => {
            // Handle errors
            console.error('Error:', error);

            setState(prevState => ({ ...prevState, isLoading: false }))
        });
    };

    const calculateDisabled = (e) => {

        const {
            lotType,
            retailValue,
            itemDescription,
            bidPrice,
            title,
            reserveBidPrice,
            buyItNowPrice,
            shippingCost,
            startDate,
            selectedCategory,
            endDate,
            shippingOption,
            images
        } = state;

        if ((selectedCategory !== null) && (typeof lotType !== "undefined" && lotType.length > 0) && (typeof title !== "undefined" && title.length > 0) && (typeof retailValue !== "undefined" && retailValue.length > 0) && (typeof itemDescription !== "undefined" && itemDescription.length >= 35) && (typeof bidPrice !== "undefined" && bidPrice !== 0) && (typeof reserveBidPrice !== "undefined" && reserveBidPrice !== 0) && (typeof buyItNowPrice !== "undefined" && buyItNowPrice !== 0) && (typeof shippingOption !== "undefined" && shippingOption !== null) && (typeof images !== "undefined" && images.length > 0) && (startDate !== null) && (endDate !== null)) {
            return true;
        } else {
            return false;
        }
    };

    console.log("state", state);

    const handleImageUpload = () => {
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            const filtered = state.images.filter((image) => typeof image['data_url'] !== "undefined" ? image : null);

            console.log("filtered", filtered);
    
            if (typeof filtered !== "undefined" && filtered.length > 0) {
                for (let index = 0; index < filtered.length; index++) {
                    const image = filtered[index];
                    formData.append(`files`, image.file);
        
                    if ((filtered.length - 1) === index) {
                        try {
                            const res = await api.post(`${process.env.REACT_APP_BASE_URL}/upload/misc/file/wo/saving/array`, formData);
                            
                            if (res.data.message === "Successfully uploaded images!") {
                                console.log(res.data);
            
                                const { passedValues } = res.data;
        
                                setState(prevState => ({ ...prevState, imagesUploaded: passedValues }));
            
                                resolve(passedValues.concat(state.images.filter((image) => typeof image['data_url'] === "undefined" ? image : null)));
                            } else {
                                console.log("Err", res.data);
                                
                                NotificationManager.warning('Error attempting to upload your image/photo!', 'Try the action again - image upload failed.', 3000);
            
                                setState(prevState => ({ ...prevState, loading: false }));
                                reject("Image upload failed");
                            }
                        } catch (err) {
                            console.log(err.message);
                            setState(prevState => ({ ...prevState, loading: false }));
                            reject(err);
                        }
                    }
                }
            } else {
                resolve(state.images);;
            }
        });
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="register-content container">
                <form id="login-form">
                    <h2>Edit an existing auction listing</h2>
                    <div class="line-container">
                        <div class="line"></div>
                        <div class="text-container">
                            <p class="centered-text">Listing main details:</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <label className="label-custom">Lot Type (Item/Instrument Type)</label>
                            <input
                                type="text"
                                id="text"
                                className="form-control spacing-input"
                                autoComplete="off"
                                name="text"
                                placeholder="LOT TYPE"
                                required=""
                                value={state.lotType}
                                onChange={(e) => setState(prevState => ({ ...prevState, lotType: e.target.value }))}
                            />
                            <label className="label-custom">Listing Title</label>
                            <input
                                type="text"
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                placeholder="LISTING TITLE"
                                required=""
                                value={state.title}
                                onChange={(e) => setState(prevState => ({ ...prevState, title: e.target.value }))}
                            />
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <label className="label-custom">Price Estimate (Retail Value):</label>
                            <input
                                type="text"
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                placeholder="PRICE VALUE RETAIL RANGE"
                                required=""
                                value={state.retailValue}
                                onChange={(e) => setState(prevState => ({ ...prevState, retailValue: e.target.value }))}
                            />
                            <label className="label-custom">Lot Number (#):</label>
                            <input
                                type="text"
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                placeholder="Lot Number (#)"
                                required=""
                                value={state.lotNumber}
                                onChange={(e) => setState(prevState => ({ ...prevState, lotNumber: e.target.value }))}
                            />
                        </div>
                    </div>
                    <label className="label-custom">Description</label>
                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                        <SimpleMdeReact 
                            type="text"
                            id="text"
                            style={{ marginTop: 32.25, textAlign: "left" }}
                            name="username"
                            autoComplete="off"
                            placeholder="DESCRIPTION OF GOODS/ITEMS (MUST BE AT LEAST 35 CHARACTERS IN LENGTH!)"
                            required=""
                            value={state.itemDescription}
                            onChange={(val) => setState(prevState => ({ ...prevState, itemDescription: val }))} 
                        />
                        </div>
                    <div class="line-container">
                        <div class="line"></div>
                        <div class="text-container">
                            <p class="centered-text">Auction Specifics:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <label className="label-custom">Starting Bid Price ($):</label>
                            <input
                                type="number"
                                id="text"
                                className="form-control spacing-input"
                                autoComplete="off"
                                name="text"
                                placeholder="STARTING BID PRICE"
                                required=""
                                value={state.bidPrice}
                                onChange={(e) => setState(prevState => ({ ...prevState, bidPrice: e.target.value }))}
                            />
                            <label className="label-custom">Reserve Bid Price ($):</label>
                            <input
                                type="number"
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                placeholder="RESERVE PRICE"
                                required=""
                                value={state.reserveBidPrice}
                                onChange={(e) => setState(prevState => ({ ...prevState, reserveBidPrice: e.target.value }))}
                            />
                            <label className="label-custom">Buy It Now Price (optional fixed price for immediate purchase):</label>
                            <input
                                type="number"
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                placeholder="BUY-IT-NOW PRICE"
                                required=""
                                value={state.buyItNowPrice}
                                onChange={(e) => setState(prevState => ({ ...prevState, buyItNowPrice: e.target.value }))}
                            />
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                                    <label style={{ marginTop: 10 }} className="label-custom">Auction Duration (Start Date)</label>
                                    <br />
                                    <br />
                                    <div className="float-left">
                                        <Datetime minDate={new Date()} onChange={(date) => setState(prevState => ({ ...prevState, startDate: date }))} value={state.startDate} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                                    <label style={{ marginTop: 10 }} className="label-custom">Auction Duration (End Date)</label>
                                    <br />
                                    <br />
                                    <div className="float-left">
                                        <Datetime minDate={new Date()} onChange={(date) => setState(prevState => ({ ...prevState, endDate: date }))} value={state.endDate} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                                    <label style={{ marginTop: 10 }} className="label-custom">Category</label>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                                <Select
                                        value={state.selectedCategory}
                                        className="form-control spacing-input"
                                        placeholder={"CATEGORY"}
                                        onChange={(value) => setState(prevState => ({ ...prevState, selectedCategory: value }))}
                                        options={optionsCategory}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-container">
                        <div class="line"></div>
                        <div class="text-container">
                            <p class="centered-text">Shipping & Payment:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <label className="label-custom">Shipping Options:</label>
                            <br />
                            <Select
                                value={state.shippingOption}
                                className="form-control spacing-input"
                                placeholder={"SHIPPING OPTIONS"}
                                onChange={(value) => setState(prevState => ({ ...prevState, shippingOption: value }))}
                                options={shippingOptions}
                            />
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <label className="label-custom">Shipping Cost ($):</label>
                            <input
                                type="number"
                                id="text"
                                className="form-control spacing-input"
                                name="username"
                                autoComplete="off"
                                placeholder="SHIPPING COST"
                                required=""
                                value={state.shippingCost}
                                onChange={(e) => setState(prevState => ({ ...prevState, shippingCost: e.target.value }))}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="centered">
                            <label className="label-custom" style={{ paddingRight: 10 }}>Would you like to post this listing NOW or right after completing this form! Check to immediately post.</label>
                            <Switch onChange={(value) => setState(prevState => ({ ...prevState, active: value }))} checked={state.active} />
                        </div>
                    </div>
                    <ImageUploading
                        multiple={true}
                        value={state.images}
                        onChange={(images) => setState(prevState => ({ ...prevState, images }))}
                        maxNumber={8}
                        dataURLKey="data_url"
                    >
                        {({
                            onImageUpload,
                            onImageRemove,
                            isDragging,
                            imageList,
                            onImageUpdate,
                            dragProps
                        }) => (
                            // write your building UI
                            <Fragment>
                                <div style={{ marginTop: "22.25px" }} className="upload__image-wrapper">
                                    <div style={isDragging ? { color: 'red' } : undefined} {...dragProps} onClick={() => {
                                        onImageUpload();
                                        
                                        setState(prevState => ({ ...prevState, images: imageList }))
                                    }}><img className="imageupload" src={require("../../../assets/icons/dragdrop.jpg")} /></div>
                                    &nbsp;
                                </div>
                                <div style={{ marginTop: "22.25px" }} className="row">
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item col-md-2 col-lg-2 col-sm-12">
                                            <img src={typeof image['data_url'] !== "undefined" ? image["data_url"] : image.location} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">
                                                <button onClick={(e) => {
                                                    e.preventDefault();

                                                    onImageRemove(index);
                                                }}>Remove</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Fragment>
                        )}
                    </ImageUploading>
                    <hr />
                    <div className="btn" id={calculateDisabled() === true ? "greenbtn" : "greybtn"}>
                        <a style={{ color: "#fff" }} onClick={(e) => calculateDisabled() === false ? null : handleCreationListing(e)}>Update Your Listing</a>
                    </div>
                </form>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
}

export default LotActiveEditPageHelper;