import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../interceptor";
import { connect } from "react-redux";
import _ from "lodash";
import { NotificationManager } from 'react-notifications';
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';
import * as PureModal from 'react-modal';
import Downshift from 'downshift';
import ReactPaginate from 'react-paginate';
import ResponsivePagination from 'react-responsive-pagination';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'
import { BottomSheet } from 'react-spring-bottom-sheet'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

const findHighestBid = (arr) => {
    if (arr.length === 0) {
      return 0; // or handle the empty array case as needed
    }
    const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
    return highestBid;
};

const handleEditSelected = (listing, navigate) => {
    console.log("handleEditSelected", listing);

    navigate(`/lot_page_edit/${listing.id}`)
}
         
const Items = ({ listings, currentPage, pendingListing, navigate, state, whichToShow, expired, start, end }) => {
    const [ expiredAuctions, setExpired ] = useState([]);
    useEffect(() => {
        setExpired(expired.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)));
    }, []);

    if (whichToShow === 0) {
        console.log("pendingListing", pendingListing, currentPage);
        return (
            <>
                {typeof listings !== "undefined" && listings.length > 0 ? listings
                    // .filter((item) => item.active === true)
                    .slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5)
                    .map((listing, index) => {
                        const highestBidAmount = findHighestBid(listing.bidsArray);
                        return (
                            <Fragment key={index}>
                                <tr>
                                    <td>{listing.title.slice(0, 35)}{typeof listing.title !== "undefined" && listing.title.length >= 35 ? "..." : ""}</td>
                                    <td>{moment(listing.startDate).format("MM/DD/YYYY hh:mm a")}</td>
                                    <td>{moment(listing.endDate).format("MM/DD/YYYY hh:mm a")}</td>
                                    <td>${highestBidAmount}</td>
                                    <td>{listing.bidsArray.length} bid(s)</td>
                                    <td className="hover" onClick={() => handleEditSelected(listing, navigate)}><img src={require("../../assets/images/media/edit.png")} style={{ maxHeight: 35, maxWidth: 35 }} /></td>
                                    <td className="hover" onClick={() => {
                                        navigate(`/lot_page/${listing.id}`);
                                    }}><img src={require("../../assets/images/media/show.png")} style={{ maxHeight: 35, maxWidth: 35 }} /></td>
                                </tr>
                            </Fragment>
                        );
                    }) : 
                    <div className="mx-auto row">
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <p className="lead text-left" style={{ textAlign: "left" }}>You currently do NOT have any active listings.</p>    
                        </div>
                    </div>
                }
            </>
        );
    } else if (whichToShow === 1) {
        return (
            <>
                {typeof state.pendingListings !== "undefined" && state.pendingListings.length > 0 ? state.pendingListings.slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5).map((listing, index) => {
                    const highestBidAmount = findHighestBid(listing.bidsArray);
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td>{listing.title.slice(0, 35)}{typeof listing.title !== "undefined" && listing.title.length >= 35 ? "..." : ""}</td>
                                <td>{moment(listing.startDate).format("MM/DD/YYYY hh:mm a")}</td>
                                <td>{moment(listing.endDate).format("MM/DD/YYYY hh:mm a")}</td>
                                <td>${highestBidAmount}</td>
                                <td>{listing.bidsArray.length} bid(s)</td>
                                <td className="hover" onClick={() => handleEditSelected(listing, navigate)}><img src={require("../../assets/images/media/edit.png")} style={{ maxHeight: 35, maxWidth: 35 }} /></td>
                                <td className="hover" onClick={() => {
                                    navigate(`/lot_page/${listing.id}`);
                                }}><img src={require("../../assets/images/media/show.png")} style={{ maxHeight: 35, maxWidth: 35 }} /></td>
                            </tr>
                        </Fragment>
                    );
                }) : <div className="mx-auto row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <p className="lead text-left" style={{ textAlign: "left" }}>You current do NOT have any pending listings.</p>    
                    </div>
                </div>}
            </>
        );
    } else if (whichToShow === 2) {

        console.log("start end", start, end);
        
        const handleRedirectExpired = (id) => {
            navigate(`/manage/listing/ended/${id}`)
        };
        return (
            <>
                {typeof expiredAuctions !== "undefined" && expiredAuctions.length > 0 ? expiredAuctions.slice(start, end).map((listing, index) => {
                    const highestBidAmount = findHighestBid(listing.bidsArray);
                    return (
                        <Fragment key={index}>
                            <tr className="hover" onClick={() => handleRedirectExpired(listing.id)}>
                                <td>{listing.title.slice(0, 35)}{typeof listing.title !== "undefined" && listing.title.length >= 35 ? "..." : ""}</td>
                                <td>{moment(listing.startDate).format("MM/DD/YYYY hh:mm a")}</td>
                                <td>{moment(listing.endDate).format("MM/DD/YYYY hh:mm a")}</td>
                                <td>${highestBidAmount}</td>
                                <td>{listing.bidsArray.length} bid(s)</td>
                                <td className="hover" onClick={() => {
                                    navigate(`/lot_page/${listing.id}`);
                                }}><img src={require("../../assets/images/media/show.png")} style={{ maxHeight: 35, maxWidth: 35 }} /></td>
                            </tr>
                        </Fragment>
                    );
                }) : <div className="mx-auto row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <p className="lead text-left" style={{ textAlign: "left" }}>You current do NOT have any active listings.</p>    
                    </div>
                </div>}
            </>
        );
    };
}

const PaginatedItems = ({ pendingListings, itemsPerPage, state, navigate, whichToShow, currentPage, setCurrentPage }) => {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    console.log("itemOffset", itemOffset);

    console.log("pageCount", pageCount);
    const handlePageClick = (event) => {
        console.log("event", event);
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    console.log("state.listings", state.listings);

    const calculatePageCount = (whichToShow) => {
        console.log("state.expired", state.expired);
        switch (whichToShow) {
            case 0:
                if (typeof state.listings === "undefined" || state.listings.length === 0) {
                    return 0;
                } else {
                    return Math.ceil(state.listings.length / 5);
                }
            case 1:
                if (typeof state.pendingListings === "undefined" || state.pendingListings.length === 0 || !state.pendingListings.some((listing) => listing.active === false)) {
                    return 0;
                } else {
                    return Math.ceil(state.pendingListings.length / 5);
                }
            case 2:
                if (typeof state.expired === "undefined" || state.expired.length === 0) {
                    return 0;
                } else {
                    return Math.ceil(state.expired.length / 5);
                }
            default:
                return 0;
        }
    };
    return (
        <>
            <Items state={state} pendingListings={pendingListings} setCurrentPage={setCurrentPage} currentPage={currentPage} whichToShow={whichToShow} expired={state.expired} listings={state.listings} navigate={navigate} start={itemOffset} end={endOffset} currentItems={currentItems} />
            {whichToShow === 2 ? <Fragment>
                <ReactPaginate
                    activeClassName='pagination-active' // The class name for the active page
                    breakClassName='pagination-break' // The class name for the break ('...')
                    containerClassName='pagination-container' // The class name for the pagination container
                    initialPage={0} // The initial page
                    marginPagesDisplayed={3} // The number of pages displayed at the margins
                    nextClassName='pagination-next-prev' // The class name for the next button
                    onPageChange={handlePageClick} // The function to call when the page changes
                    pageCount={Math.round(calculatePageCount(whichToShow))} // The total number of pages
                    onClick={(val1, val2) => console.log("val1, val2", val1, val2)}
                    // pageRangeDisplayed={-} // The range of pages displayed
                    pageClassName='pagination-page' // The class name for the page
                    previousClassName='pagination-next-prev' // The class name for the previous button
                />    
            </Fragment> : <ResponsivePagination
                current={currentPage}
                total={Math.round(calculatePageCount(whichToShow))}
                onPageChange={(page) => {
                    setCurrentPage(page);
                }}
            />}
        </>
    );
}

const AdminPanelHelper = ({ authData }) => {
    const navigate = useNavigate();
    const [ width, height ] = useWindowSize()     
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ state, setState ] = useState({
        amountOfAuctions: 0,
        listings: [],
        revenueSalesVal: 0,
        users: [],
        completedAuctions: 0,
        selectedLicenseLink: null,
        modalIsOpen: false,
        expired: [],
        pendingListings: [],
        activePanel: false,
        adminInputOne: "", 
        adminInputTwo: "", 
        adminInputThree: "",
        adminInputOneTop: "", 
        adminInputTwoTop: "", 
        adminInputThreeTop: ""
    });
                
    useEffect(() => {
        const config = {
            params: {
                uniqueId: authData.uniqueId
            }
        };

        api.get(`${process.env.REACT_APP_BASE_URL}/fetch/general/information/admin/panel`, config).then((res) => {
            if (res.data.message === "Successfully gathered information!") {
                console.log("res.data", res.data);

                const { documentCount, completedAuctions, totalPaymentVal, listings, users, expired } = res.data;

                const filteredPending = listings.filter((listing) => listing.active === false);

                setState(prevState => ({ ...prevState, amountOfAuctions: documentCount, pendingListings: filteredPending, completedAuctions, revenueSalesVal: totalPaymentVal, listings, users, expired }))
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, []);

    const addCommasToNumber = (number) => {
        // Convert the number to a string
        const numberString = number.toString();
        // Use a regular expression to add commas
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');    
        return formattedNumber;
    }

    const changePostsToActive = (e) => {
        e.preventDefault();

        const config = {
            uniqueId: authData.uniqueId,
            pendingListings: state.listings
        };

        api.post(`${process.env.REACT_APP_BASE_URL}/change/pending/listings/live`, config).then((res) => {
            if (res.data.message === "Successfully posted information!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })

        console.log("confirmed! function inner call.");
    }
    
    const deleteUserAccount = (uniqueId) => {
        console.log("deleteUserAccount account clicked.");

        const config = {
            userToDeleteID: uniqueId
        };

        api.post(`${process.env.REACT_APP_BASE_URL}/delete/the/user/account`, config).then((res) => {
            if (res.data.message === "Successfully deleted the users account!") {
                console.log("res.data", res.data);

                setState(prevState => ({ ...prevState, users: prevState.users.filter((item) => item.uniqueId !== uniqueId) }))

                NotificationManager.success("Successfully deleted the user account!", "Successfully deleted the desired account.", 3250);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };

    const handleToggle = () => {
        setState(prevState => ({ ...prevState, activePanel: !prevState.activePanel })); // Toggle between open and closed states
    };

    const renderBottomSheetContent = () => {
        const handleSubmission = () => {
            console.log("clicked");

            const { adminInputOne, adminInputTwo, adminInputThree, adminInputOneTop, adminInputTwoTop, adminInputThreeTop } = state;

            const config = { adminInputOne, adminInputTwo, adminInputThree, adminInputOneTop, adminInputTwoTop, adminInputThreeTop }
    
            api.post(`${process.env.REACT_APP_BASE_URL}/update/homepage/data/info`, config).then((res) => {
                if (res.data.message === "Successfully added default homepage data!") {
                    console.log("res.data", res.data);
                    NotificationManager.success('Successfully updated your homepage information!', 'Successfully Updated.', 3000);

                    handleToggle();
                } else {
                    console.log("err");
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);

                    handleToggle();
                }
            })
        };

        return (
            <Fragment>
                <div className="container">
                    <hr />
                    <div className="row">
                        {/* First Input Column */}
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="input1" className="form-label">
                                Far Left Banner Input
                            </label>
                            <input
                                type="text"
                                id="input1"
                                value={state.adminInputOneTop}
                                onChange={(e) => setState(prevState => ({ ...prevState, adminInputOneTop: e.target.value }))}
                                className="form-control"
                                placeholder="This is the header LABEL (FAR LEFT)"
                            />
                            <hr />
                            <input
                                type="text"
                                id="input1"
                                value={state.adminInputOne}
                                onChange={(e) => setState(prevState => ({ ...prevState, adminInputOne: e.target.value }))}
                                className="form-control"
                                placeholder={"This is the smaller/lower text for banner..."}
                            />
                        </div>
    
                        {/* Second Input Column */}
                        <div className="col-md-4 col-sm-12">
                        <label htmlFor="input1" className="form-label">
                                Middle Banner Input
                            </label>
                            <input
                                type="text"
                                id="input1"
                                value={state.adminInputTwoTop}
                                onChange={(e) => setState(prevState => ({ ...prevState, adminInputTwoTop: e.target.value }))}
                                className="form-control"
                                placeholder={"This is the header LABEL (MIDDLE)"}
                            />
                            <hr />
                            <input
                                type="text"
                                value={state.adminInputTwo}
                                onChange={(e) => setState(prevState => ({ ...prevState, adminInputTwo: e.target.value }))}
                                id="input2"
                                className="form-control"
                                placeholder={"This is the smaller/lower text for banner..."}
                            />
                        </div>
    
                        {/* Third Input Column */}
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="input1" className="form-label">
                                Far Right Banner Input
                            </label>
                            <input
                                type="text"
                                id="input1"
                                value={state.adminInputThreeTop}
                                onChange={(e) => setState(prevState => ({ ...prevState, adminInputThreeTop: e.target.value }))}
                                className="form-control"
                                placeholder={"This is the header LABEL (FAR RIGHT"}
                            />
                            <hr />
                            <input
                                type="text"
                                id="input3"
                                value={state.adminInputThree}
                                onChange={(e) => setState(prevState => ({ ...prevState, adminInputThree: e.target.value }))}
                                className="form-control"
                                placeholder={"This is the smaller/lower text for banner..."}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row mx-auto center centered">
                        <button onClick={handleSubmission} style={{ width: "75%", borderRadius: 5 }} type="button" className="btn btn-primary">Submit all three changes</button>
                    </div>
                </div>
            </Fragment>
        );
    };
    

    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.selectedLicenseLink !== null ? <PureModal
                isOpen={state.modalIsOpen}
                onAfterOpen={() => {}}
                onRequestClose={() => setState(prevState => ({ ...prevState, modalIsOpen: false }))}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h4>Below you'll find the DL of this user..</h4>
                <img
                    src={`${process.env.REACT_APP_BASE_ASSET_URL}/${state.selectedLicenseLink}`}
                    className="modalimage"
                    onClick={() => setState(prevState => ({ ...prevState, modalIsOpen: true }))}
                    alt="User-Profile-Image"
                />
            </PureModal> : null}
            <BottomSheet snapPoints={({ minHeight, maxHeight }) => [minHeight + 300]}
            // Opens the largest snap point by default, unless the user selected one previously
            defaultSnap={({ lastSnap, snapPoints }) =>
                lastSnap ?? Math.max(...snapPoints)
            } onDismiss={handleToggle} open={state.activePanel}>
                {renderBottomSheetContent()}
            </BottomSheet>
            <div className="content-custom">
                <div className="container card card-custom card-custom-box-shadow" style={{ padding: 12.25 }}>
                    <div className="row">
                        <div className="col-sm-12">
                            {/* meta */}
                            <div className="profile-user-box card-box bg-custom">
                            <div className="row">
                                <div className="col-sm-3">
                                <span className="float-left mr-3">
                                    <img
                                    style={{ objectFit: "contain" }}
                                    src={require("../../assets/CVA Logo Bold Text.jpg")}
                                    alt=""
                                    className="thumb-lg"
                                    />
                                </span>
                                </div>
                                <div className="col-sm-9">
                                    <div className="text-right" style={{ float: "right" }}>
                                        <button onClick={handleToggle} style={{ marginRight: 10, marginTop: 12.25, borderRadius: 5 }} type="button" id="custombtnbtndates" className="">Manage Homepage Dates</button>
                                        <Link to="/create_new_auction" style={{ color: "#fff", marginTop: 12.25, borderRadius: 5 }} type="button" className="btn btn-light waves-effect">Add new auction listing</Link>
                                        <button onClick={(e) => {
                                            confirmAlert({
                                                title: `Are you sure you want to 'post' all listings?`,
                                                message: `This will make all of your previously added 'pending' listings live and viewable in the public marketplace!`,
                                                buttons: [
                                                    {
                                                        label: 'Yes, Post!',
                                                        onClick: () => {
                                                            console.log("yes save");
                                    
                                                            changePostsToActive(e)
                                                        }
                                                    },
                                                    {
                                                        label: `No, Don't post.`,
                                                        onClick: () => {
                                                            console.log("no dont save");
                                                        }
                                                    }
                                                ]
                                            });
                                        }} style={width <= 775 ? { marginRight: 10, marginTop: 12.25, borderRadius: 5 } : { marginLeft: 10, marginRight: 10, marginTop: 12.25, borderRadius: 5 }} type="button" id="custombtnbtn" className="">List Pending Listings!</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            {/*/ meta */}
                        </div>
                    </div>
                    {/* end row */}
                    <div className="row" style={{ marginBottom: 25 }}>
                        <div className="col-xl-4">
                            <button className="btn btn-primary" style={{ borderRadius: 5, width: "100%" }} onClick={(e) => {
                                e.preventDefault();

                                navigate("/modify_calendar_events")
                            }}>Modify Calendar Events</button>
                        </div>
                        <div className="col-xl-4">

                        </div>
                        <div className="col-xl-4">

                        </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-4">
                        {/* Personal-Information */}
                        <div className="card-box">
                            <h4 className="header-title mt-0">User's Drivers Licenses</h4>
                            <Downshift
                                onChange={selection => {
                                    confirmAlert({
                                        title: `Areyou sure you want to delete this account?`,
                                        message: `This will PERMANENTLY delete this user's account, this action is not reversible and is a serious action resulting in the deletion of the user's entire account.`,
                                        buttons: [
                                            {
                                                label: 'Yes, Delete!',
                                                onClick: () => {
                                                    console.log("yes delete", selection.uniqueId);
                            
                                                    deleteUserAccount(selection.uniqueId)
                                                }
                                            },
                                            {
                                                label: `CANCEL ACTION!`,
                                                onClick: () => {
                                                    console.log("no dont delete");
                                                }
                                            }
                                        ]
                                    });
                                }}
                                itemToString={item => (item ? item.title : '')}
                            >
                                {({
                                    getInputProps,
                                    getItemProps,
                                    getLabelProps,
                                    getMenuProps,
                                    isOpen,
                                    inputValue,
                                    highlightedIndex,
                                    selectedItem,
                                    getRootProps
                                }) => (
                                    <div className="col-12">
                                        <div
                                            style={{display: 'inline-block'}}
                                            {...getRootProps({}, {suppressRefError: true})}
                                        >
                                            <input className="specialsearchinput form-control" type="text" placeholder="SEARCH BY USERNAME" {...getInputProps()} />
                                        </div>
                                        <ul {...getMenuProps()}>
                                            {isOpen ? state.users.filter(item => !inputValue || item.username.toLowerCase().includes(inputValue.toLowerCase())).map((item, index) => (
                                                <li
                                                    {...getItemProps({
                                                        key: item.title,
                                                        index,
                                                        item,
                                                        style: {
                                                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                        },
                                                    })}
                                                    className="list-styled-item"
                                                >
                                                    {item.username}
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                )}
                            </Downshift>
                            <div className="panel-body">
                                {typeof state.users !== "undefined" && state.users.length > 0 ? state.users.map((user, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div style={{ marginBottom: 10 }} className="card user-card">
                                                <div className="card-block">
                                                    <div className="user-image">
                                                        <img
                                                            src={`${process.env.REACT_APP_BASE_ASSET_URL}/${user.driversLicenseImage.link}`}
                                                            className="img-radius"
                                                            onClick={() => setState(prevState => ({ ...prevState, selectedLicenseLink: user.driversLicenseImage.link, modalIsOpen: true }))}
                                                            alt="User-Profile-Image"
                                                        />
                                                    </div>
                                                    <h6 className="f-w-600 m-t-25 m-b-10">{user.firstName} {user.lastName}</h6>
                                                    <p className="text-muted">{user.username} | {moment(user.dateOfBirth).format("MM/DD/YY")} | Registered {moment(user.registrationDate).fromNow(true)}</p>
                                                    <button className="btn btn-danger" style={{ borderRadius: 5, width: "100%" }} onClick={(e) => {
                                                        e.preventDefault();

                                                        confirmAlert({
                                                            title: `Areyou sure you want to delete this account?`,
                                                            message: `This will PERMANENTLY delete this user's account, this action is not reversible and is a serious action resulting in the deletion of the user's entire account.`,
                                                            buttons: [
                                                                {
                                                                    label: 'Yes, Delete!',
                                                                    onClick: () => {
                                                                        console.log("yes delete");
                                                
                                                                        deleteUserAccount(user.uniqueId)
                                                                    }
                                                                },
                                                                {
                                                                    label: `CANCEL ACTION!`,
                                                                    onClick: () => {
                                                                        console.log("no dont delete");
                                                                    }
                                                                }
                                                            ]
                                                        });
                                                    }}>Delete User</button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                }) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="row">
                        <div className="col-sm-4">
                            <div className="card-box tilebox-one">
                                <i className="icon-layers float-right text-muted" />
                                <h6 className="text-muted text-uppercase mt-0">Total Auctions</h6>
                                <h2 className="" data-plugin="counterup">
                                    {state.amountOfAuctions} listings
                                </h2>
                                {/* <span className="badge badge-custom">+11% </span> */}
                                <span className="text-muted" style={{ paddingLeft: "10px" }}>{`${state.completedAuctions + state.amountOfAuctions} lifetime listings`}</span>
                            </div>
                        </div>
                        {/* end col */}
                        <div className="col-sm-4">
                            <div className="card-box tilebox-one">
                                <i className="icon-paypal float-right text-muted" />
                                <h6 className="text-muted text-uppercase mt-0">Revenue</h6>
                                <h2 className="">
                                    $<span data-plugin="counterup">{addCommasToNumber(state.revenueSalesVal)}</span>
                                </h2>
                                <span className="badge badge-custom" style={{ width: "100%", marginBottom: 10 }}>Lifetime sales</span>
                                <span className="text-muted" style={{ paddingLeft: "10px" }}>{"Last 30 days"}</span>
                            </div>
                        </div>
                        {/* end col */}
                        <div className="col-sm-4">
                            <div className="card-box tilebox-one">
                                <i className="icon-rocket float-right text-muted" />
                                <h6 className="text-muted text-uppercase mt-0">Auctions Sold</h6>
                                <h2 className="" data-plugin="counterup">
                                    {state.completedAuctions}
                                </h2>
                                {/* <span className="badge badge-custom">110</span> */}
                                <span className="text-muted" style={{ paddingLeft: "10px" }}>Listings that ended.</span>
                            </div>
                        </div>
                        {/* end col */}
                        </div>
                        {/* end row */}
                        {/* <div className="card-box">
                        <h4 className="header-title mt-0 mb-3">Experience</h4>
                        <div className="row">
                            <div className="col-md-6 col-xl-6 col-sm-12 col-xl-6">
                                <div className="card bg-c-blue order-card">
                                    <div className="card-block">
                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="400px"
                                            data={data}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-6 col-sm-12 col-xl-6">
                                <div className="card bg-c-blue order-card">
                                    <div className="card-block">
                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="400px"
                                            data={data2}
                                            options={options2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div> */}
                        {/* <div className="center" style={{ marginBottom: 22.25 }}>
                            <button className="btn btn-primary" onClick={handleShippedRedirect}>View already shipped</button>
                        </div> */}
                        <div className="card-box">
                        <Tabs>
                            <TabList>
                                <Tab onClick={() => setCurrentPage(1)}>Active Auctions</Tab>
                                <Tab onClick={() => setCurrentPage(1)}>Pending Auctions</Tab>
                                <Tab onClick={() => setCurrentPage(1)}>Ended/Expired Auctions</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="table-responsive">
                                        <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Listing Title</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Current</th>
                                                <th>Bid(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PaginatedItems pendingListing={state.pendingListings} whichToShow={0} navigate={navigate} state={state} itemsPerPage={5} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                        </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="table-responsive">
                                        <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Listing Title</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Current Bid</th>
                                                <th>Total Bid(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeof state.listings !== "undefined" && state.listings.some((listing) => listing.active === false) ? <PaginatedItems whichToShow={1} navigate={navigate} state={state} pendingListing={state.pendingListings} itemsPerPage={5} currentPage={currentPage} setCurrentPage={setCurrentPage} /> : null}
                                        </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="table-responsive">
                                        <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Listing Title</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Current</th>
                                                <th>Bid(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PaginatedItems pendingListing={state.pendingListings} whichToShow={2} navigate={navigate} currentPage={currentPage} setCurrentPage={setCurrentPage} state={state} itemsPerPage={5} />
                                        </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                    {/* end col */}
                    </div>
                    {/* end row */}
                </div>
                <div style={{ height: 50 }} />
                {/* container */}
                </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
      authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
  }
};

export default connect(mapStateToProps, { })(AdminPanelHelper);