import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import { Link, useNavigate } from "react-router-dom";
import ResponsivePagination from 'react-responsive-pagination';
import { useEffect } from "react";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import axios from "../../../interceptor";
import ContentLoader from 'react-content-loader'
import {
    useWindowSize
} from '@react-hook/window-size';
import "./styles.css";
import moment from "moment";
import SelectSearch from 'react-select-search';
// import Magnifier from "react-magnifier";
import { confirmAlert } from 'react-confirm-alert';
import Datetime from 'react-datetime';
import { Range } from 'react-range';
import Select from 'react-select';
import ImageGallery from "react-image-gallery";
import { Modal } from 'react-responsive-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-select-search/style.css'

const optionsCategory = [{
    label: "All Listings",
    value: "all"
}, {
    label: "My Bookmarks",
    value: "my-bookmarks"
}, {
    label: "Violin",
    value: "violin"
}, {
    label: "Viola",
    value: "viola"
}, {
    label: "Cello",
    value: "cello"
}, {
    label: "Violin Bow",
    value: "violin-bow"
}, {
    label: "Viola Bow",
    value: "viola-bow"
}, {
    label: "Cello Bow",
    value: "cello-bow"
}, {
    label: "Bass Bow",
    value: "bass-bow"
}, {
    label: "Miscellaneous",
    value: "miscellaneous"
}];

const ITEMS_PER_PAGE = 15;

const calculateTotalPages = (totalResults, itemsPerPage) => {
    return Math.ceil(totalResults / itemsPerPage);
}

const SearchComponent = ({ state, navigate }) => {
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (value) => {
        if (typeof value === "string") {
            navigate(`/lot_page/${value}`);
        } else {
            navigate(`/lot_page/${state.allListings[value - 1].id}`);
        }
    };

    const filteredOptions = state.allListings
        .filter((listing) => 
            listing.title.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map((listing) => ({
            name: listing.title,
            value: listing.id, // Use a unique identifier if available
        }));

    return (
        <SelectSearch
            options={filteredOptions}
            value={searchValue}
            backgroundColor={"lightgrey"}
            onChange={handleSearchChange}
            search // Enables freeform search
            name="search"
            className="select-search"
            style={{
                backgroundColor: "white", // Ensure background stays white
                color: "black", // Ensure text color is readable
            }}
            placeholder="SEARCH CATALOG"
        />
    );
};

const IndividualAuctionViewHelper = ({ authData }) => {
    const navigate = useNavigate();
    const [width, height] = useWindowSize()
    const [ state, setState ] = useState({
        currentPage: 0,
        listings: [],
        allListings: [],
        totalPages: 0,
        checked: 0,
        isModalOpen: false,
        images: [
            {
              original: "https://picsum.photos/id/1018/1000/600/",
              thumbnail: "https://picsum.photos/id/1018/250/150/"
            },
            {
              original: "https://picsum.photos/id/1015/1000/600/",
              thumbnail: "https://picsum.photos/id/1015/250/150/"
            },
            {
              original: "https://picsum.photos/id/1019/1000/600/",
              thumbnail: "https://picsum.photos/id/1019/250/150/"
            },
            {
                original: "https://picsum.photos/id/1018/1000/600/",
                thumbnail: "https://picsum.photos/id/1018/250/150/",
              },
              {
                original: "https://picsum.photos/id/1015/1000/600/",
                thumbnail: "https://picsum.photos/id/1015/250/150/",
              },
              {
                original: "https://picsum.photos/id/1019/1000/600/",
                thumbnail: "https://picsum.photos/id/1019/250/150/",
              },
        ],
        selectedCategory: null,
        priceValuesTwo: [50000],
        searchValueInput: "",
        priceValuesOne: [1],
        startDate: null,
        highestAvailValue: 0,
        endDate: null
    })

    console.log("state", state);

    useEffect(() => {
        const configuration = {}

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                const highestBids = listings.map(listing => {
                    if (!listing.bidsArray || listing.bidsArray.length === 0) {
                      // Handle cases where there are no bids
                      return { listingId: listing.id, highestBid: null };
                    }
                  
                    // Find the highest bidAmount in the bidsArray
                    const highestBid = listing.bidsArray.reduce((max, bid) => {
                      return bid.bidAmount > max ? bid.bidAmount : max;
                    }, 0); // Initial max value set to 0 (or use -Infinity if negative bids are possible)
                  
                    return { listingId: listing.id, highestBid };
                });
            
                const highestBidOverall = highestBids.reduce((max, listing) => {
                    return listing.highestBid !== null && listing.highestBid > max ? listing.highestBid : max;
                }, 0); // Initial max value set to 0
                
                // Log or use the result
                console.log("highestBidOverall", highestBidOverall);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages, highestAvailValue: highestBidOverall, priceValuesTwo: [highestBidOverall], checked: width <= 775 ? 1 : 0 }));

                // NotificationManager.success('Successfully fetched/gathered live auctions!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        });
    }, []);

    const handleSelectionChangeCategory = (value) => {
        console.log("handleSelectionChangeCategory clicked/ran...", value);

        if (value.value === "my-bookmarks") {
            console.log("true", true);
            
            const configuration = {
                params: {
                    uniqueId: authData.uniqueId
                }
            }
    
            axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/my/bookmarked/listings`, configuration).then((res) => {
                if (res.data.message === "Successfully gathered listings!") {
                    console.log("res.data", res.data);
    
                    const { listings } = res.data;
    
                    const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);
    
                    console.log("paginatedPages", paginatedPages, listings);
    
                    setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));
                } else {
                    console.log("err", res.data);
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })   
        } else {
            const configuration = {
                params: {
                    selectedCategory: value
                }
            }
    
            axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/listings/by/category`, configuration).then((res) => {
                if (res.data.message === "Successfully gathered listings!") {
                    console.log("res.data", res.data);
    
                    const { listings } = res.data;
    
                    const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);
    
                    console.log("paginatedPages", paginatedPages, listings);
    
                    setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));
                } else {
                    console.log("err");
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        }
    };

    useEffect(() => {
        // const itemsPerPage = 10;
        // const indexOfLastItem = state.currentPage * itemsPerPage;
        // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentListings = state.allListings.slice(state.currentPage * ITEMS_PER_PAGE, (state.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);

        setState(prevState => ({ ...prevState, listings: currentListings }));
    }, [state.currentPage]);

    console.log("listings", state.listings);

    const findHighestBid = (arr) => {
        if (arr.length === 0) {
          return 0; // or handle the empty array case as needed
        }
        const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
        return highestBid;
    }

    const addToFavoritesListDB = (auctionID) => {
        console.log("addToFavoritesListDB clicked/ran...");

        const configuration = {
            auctionID,
            uniqueId: authData.uniqueId
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/add/to/favorites/list/personal`, configuration).then((res) => {
            if (res.data.message === "Successfully added to favorites list!") {
                console.log("res.data", res.data);

                NotificationManager.success(`Successfully added to your 'favorites' list.`, 'Successfully added to favorites list!', 3000);
            } else if (res.data.message === "You've already 'favorited' this listing.") {
                NotificationManager.info("You've ALREADY bookmarked/favorited this listing - no action taken.", "Already 'bookmarked' this listing!", 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }

    const handleUpdate = (listing) => {
        const promise = new Promise((resolve, reject) => {
            const imageArr = [];

            for (let index = 0; index < listing.images.length; index++) {
                const image = listing.images[index];
                
                imageArr.push({
                    original: image.location,
                    thumbnail: image.location
                })
                if ((listing.images.length - 1) === index) {
                    resolve(imageArr);
                }
            }
        })

        promise.then((passedImages) => {
            setState(prevState => ({ ...prevState, isModalOpen: true, images: passedImages }))
        })
    }

    const addCommasToNumber = (number) => {
        // Convert the number to a string
        const numberString = number.toString();
        // Use a regular expression to add commas
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');    
        return formattedNumber;
    }

    const renderMainContent = () => {

        const renderConditionalWhenReadyOrNot = (listing, formattedDuration) => {
            const startDate = new Date(listing.startDate).getTime();
            let now = new Date().getTime();
            let timeRemaining = startDate - now;
        
            const calculateRemainingTime = () => {
                const now = new Date().getTime();
                const targetTime = new Date(listing.startDate).getTime();
                const remainingTime = targetTime - now;
        
                if (remainingTime < 0) {
                    // If the target time has passed, return zeros
                    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
                } else {
                    // Calculate days, hours, minutes, and seconds remaining
                    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        
                    return { days, hours, minutes, seconds };
                }
            };
        
            const { days, hours, minutes, seconds } = calculateRemainingTime();
        
            if (timeRemaining > 0) {
                // Countdown is active
                return (
                    <Fragment>
                        <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}>Starts in:</p>
                        <p className="text-left" style={{ float: "left", textAlign: "left" }}>
                            {days}d {hours}h {minutes}m {seconds}s
                        </p>
                    </Fragment>
                );
            } else {
                // Countdown is not active
                return (
                    <Fragment>
                        <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}>Time Left</p>
                        <p className="text-left" style={{ float: "left", textAlign: "left" }}>
                            {formattedDuration}
                        </p>
                    </Fragment>
                );
            }
        };
        console.log("state.allListings", state.allListings);

        const handleRightClick = (event, path) => {
            event.preventDefault(); // Prevent the default context menu
            window.open(path, '_blank', 'noopener noreferrer'); // Open the path in a new tab
        };
        
        if (state.checked === 0) {
            return (
                <div id="table" className="auc-table-container">
                    <table className="auc-table">
                        <tbody>
                        <p style={{ fontWeight: "bold", textAlign: "left" }}>(<em style={{ color: "green" }}>GREEN = reserve met</em> | <em style={{ color: "red" }}>RED = reserve not met</em>)</p>
                        {/* Rows will be dynamically added based on your data */}
                        {typeof state.allListings !== "undefined" && state.allListings.length > 0 ? state.allListings.slice(state.currentPage * ITEMS_PER_PAGE, (state.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE).map((listing, index) => {
                            const highestBidAmount = findHighestBid(listing.bidsArray);
                            console.log("highestBidAmount", listing.bidPrice);
                            let now = moment(new Date());
                            let end = moment(new Date(listing.endDate));
                            let duration = moment.duration(end.diff(now));
                            let days = duration.days();
                            let hours = duration.hours();
                            let minutes = duration.minutes();
                            let formattedDuration = `${days}d ${hours}h ${minutes}m`;

                            return (
                                <Fragment key={index}>
                                    <tr onContextMenu={(e) => handleRightClick(e, `/lot_page/${listing.id}`)} onClick={() => navigate(`/lot_page/${listing.id}`)} className="row hover" id="lot1">
                                        <div style={{ float: "left" }} className="row">
                                            <p className="text-left" style={{ textAlign: "left", fontWeight: "bold" }}>Lot #: {listing.lotNumber}</p>
                                        </div>
                                        <div onClick={() => {
                                            handleUpdate(listing);
                                        }} className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12" key={index}>
                                            <LazyLoadImage
                                                alt={`image${index}`}
                                                height={"100%"}
                                                src={listing.images[0].location}
                                                width={"100%"} 
                                                className="avatar-md img-thumbnail"
                                            />
                                        </div>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            <Link className="hover" to={`/lot_page/${listing.id}`}>
                                                {listing.title}
                                            </Link>
                                            {/* <p>(GREEN = reserve met | RED = reserve not met)</p> */}
                                        </td>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}># Bids</p>
                                            <p className="text-left" style={{ float: "left", textAlign: "left" }}>{listing.bidsArray.length} bid(s)</p>
                                        </td>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            <p className="text-left" style={{ float: "left", textAlign: "left", fontWeight: "bolder" }}>Amount</p>
                                            <p className="text-left" style={{ float: "left", textAlign: "left" }}>$ {Math.floor(Number(listing.reserveBidPrice)) <= Math.floor(Number(highestBidAmount)) ? <em style={{ color: "green" }}>{typeof highestBidAmount !== "undefined" && highestBidAmount !== null && highestBidAmount > 0 ? addCommasToNumber(highestBidAmount) : addCommasToNumber(Math.floor(Number(listing.bidPrice)))}</em> : <em style={{ color: "red" }}>{typeof highestBidAmount !== "undefined" && highestBidAmount !== null && highestBidAmount > 0 ? addCommasToNumber(highestBidAmount) : addCommasToNumber(Math.floor(Number(listing.bidPrice)))}</em>}</p>
                                        </td>
                                        <td className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                            {renderConditionalWhenReadyOrNot(listing, formattedDuration)}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        }) : <div style={{ margin: 32.25 }}>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                        </div>}
                        {/* Add more rows as needed */}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            let timer = null;

            const handleTouchStart = (event, listing) => {
                // Start a timer when the user touches the element
                timer = setTimeout(() => {
                    console.log("Long press detected!");

                    window.open(`/lot_page/${listing.id}`, "_blank");
                }, 500); // Long press duration (500ms)
            };
        
            const handleTouchEnd = (event) => {
                // Clear the timer if the touch ends before the long press duration
                clearTimeout(timer);
            };

            const handleRightClick = (event, listing) => {
                event.preventDefault();
                console.log("Right-click detected!");

                window.open(`/lot_page/${listing.id}`, "_blank");
            };

            return (
                <Fragment>
                    <div className="row">
                    {typeof state.allListings !== "undefined" && state.allListings.length > 0 ? state.allListings.slice(state.currentPage * ITEMS_PER_PAGE, (state.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE).map((listing, index) => {
                            const highestBidAmount = findHighestBid(listing.bidsArray);
                            return (
                                <Fragment key={index}>
                                    <div style={{ paddingTop: 15 }} className="col-xl-6 col-md-6 col-lg-6 col-sm-12">
                                        <div className="card cardddddcustom">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <img className="avatar-md img-thumbnail" style={width <= 775 ? { width: "125px", height: "125px", objectFit: "cover", borderRadius: 12.25 } : { width: "185px", height: "185px", objectFit: "cover", borderRadius: 12.25 }} src={listing.images[0].location} />
                                                    </div>
                                                    <div className="flex-1 ms-3">
                                                    <h5 className="font-size-16 mb-1">
                                                        <a className="text-dark">
                                                            {listing.title.slice(0, 55)}{listing.title.length >= 55 ? "..." : ""}
                                                        </a>
                                                    </h5>
                                                    {/* <span className="badge badge-soft-success mb-0">
                                                        {index % 2 === 0 ? "Bidding!" : "Buy-it-now."}
                                                    </span> */}
                                                    </div>
                                                </div>
                                                <div className="mt-3 pt-1 row">
                                                    <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                                        <p style={{ textAlign: "left", marginLeft: -10 }} className="mb-0">
                                                        Price: <b style={{ color: "red" }}><span style={{ color: "black", textAlign: "left" }}>${typeof highestBidAmount !== "undefined" && highestBidAmount !== null ? Number(highestBidAmount).toFixed(2) : "0"}</span></b>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                                        <p style={{ textAlign: "left", marginLeft: -10 }} className="mb-0">
                                                        Bid(s): <b style={{ color: "red" }}><span style={{ color: "black", textAlign: "left" }}>{listing.bidsArray.length}</span></b>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                                        <p style={{ textAlign: "left", marginLeft: -10 }} className="mb-0">
                                                        Ends: <b style={{ color: "red" }}><span style={{ color: "black", textAlign: "left" }}>{moment(listing.endDate).fromNow()}</span></b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-2 pt-4">
                                                    <button 
                                                        onTouchStart={(event) => handleTouchStart(event, listing)}
                                                        onTouchEnd={handleTouchEnd}
                                                        onMouseDown={(event) => handleTouchStart(event, listing)} // For mouse compatibility
                                                        onMouseUp={handleTouchEnd} 
                                                        onContextMenu={(event) => handleRightClick(event, listing)} 
                                                        style={{ minHeight: 42.25, border: "1.25px solid black" }} 
                                                        type="button" 
                                                        onClick={() => navigate(`/lot_page/${listing.id}`)} className="btn-primary-custom w-50 btn-blue"
                                                    >
                                                        View Listing
                                                    </button> 
                                                    <button type="button" onClick={() => {
                                                        confirmAlert({
                                                            title: `Save to your 'favorite list'?`,
                                                            message: 'Are you sure you want to save this as a favorite?',
                                                            buttons: [
                                                                {
                                                                    label: 'Yes, Save!',
                                                                    onClick: () => {
                                                                        console.log("yes save");
                                                
                                                                        addToFavoritesListDB(listing.id)
                                                                    }
                                                                },
                                                                {
                                                                    label: `No, Don't save`,
                                                                    onClick: () => {
                                                                        console.log("no dont save");
                                                                    }
                                                                }
                                                            ]
                                                        });
                                                    }} className="btn-soft-primary btn-sm w-50" style={{ backgroundColor: "#fff", color: "#000" }}>
                                                        Bookmark
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        }) : <div style={{ margin: 32.25 }}>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                    <ContentLoader />
                                </div>
                            </div>
                        </div>}
                    </div>
                </Fragment>
            );
        }
    }

    console.log("state.currentPage", state.currentPage);

    const searchLowerValues = (values) => {
        console.log("onFinalChange values: ", values);

        const configuration = {
            params: {
                lowestValue: values[0],
                highestValue: state.priceValuesTwo[0]
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions/by/price`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };
    
    const searchHigherValues = (values) => {
        console.log("onFinalChange values: ", values);

        const configuration = {
            params: {
                lowestValue: state.priceValuesOne[0],
                highestValue: values[0]
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions/by/price`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;
                const paginatedPages = calculateTotalPages(listings.length, ITEMS_PER_PAGE);

                setState(prevState => ({ ...prevState, listings, allListings: listings, totalPages: paginatedPages }));
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };

    

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <Modal classNames="modalstretch" open={state.isModalOpen} onClose={() => setState(prevState => ({ ...prevState, isModalOpen: false }))} center>
                <ImageGallery items={state.images} />
            </Modal>
            <div className="container-fluid">
                <h2 className="text-center" id="onlyshowonmobile">Current Lots/Listings</h2>{" "}
                <div className="auc-header">
                    <form id="auc-form">
                    <h2 className="text-center" id="movemobile" style={{ position: "absolute", right: 50 }}>Current Lots/Listings</h2>{" "}
                    </form>
                    <div
                        className="view-form col-md-12 col-sm-12"
                        style={{
                            ...(width <= 775
                            ? {
                                minWidth: "100vw", // Full width on mobile
                                position: "relative", // Relative positioning to center it
                                left: "67.5%", // Offset from left
                                transform: "translateX(-50%)", // Center horizontally
                                padding: "10px", // Optional padding for better visuals
                                marginTop: -35
                                }
                            : {}),
                        }}
                        >
                        <p style={{ marginTop: 27.5 }}>View Type:</p>
                        <div className="radio-container">
                            <input
                            type="radio"
                            id="type-table"
                            checked={state.checked === 0}
                            onChange={() => setState((prevState) => ({ ...prevState, checked: 0 }))}
                            name="view-type"
                            defaultValue=""
                            defaultChecked="true"
                            />
                            <label htmlFor="type-table" />
                        </div>
                        <div className="radio-container">
                            <input
                            type="radio"
                            id="type-cards"
                            checked={state.checked === 1}
                            onChange={() => setState((prevState) => ({ ...prevState, checked: 1 }))}
                            name="view-type"
                            defaultValue=""
                            />
                            <label htmlFor="type-cards" />
                        </div>
                        <p className="hover" style={{ marginTop: 27.5 }}>
                            <Link to={"/entire_catalog"}>
                            {width <= 775 ? "View Catalog" : "View Entire Catalog"}
                            </Link>
                        </p>
                    </div>
                </div>
                <div style={{ width: "100%", minHeight: "100vh" }} className="container-fluid">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-12" style={width <= 775 ? { padding: 7.25 } : { padding: 24.25 }} id={width <= 775 ? "" : "fixside"}>
                                <h2 className="grid-title">
                                    <img src={require("../../../assets/icons/filter.png")} style={{ maxWidth: 35, maxHeight: 35, height: 35, width: 35 }} /> Filters
                                </h2>
                                    <div style={{ marginLeft: 15 }} className="col-md-4 col-lg-4 col-xl-4 col-sm-12">
                                        <SearchComponent navigate={navigate} state={state} />
                                    </div>
                                    {/* <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                        <Link to="/entire_catalog" className="forgot-password row" style={{ width: "20vw", marginLeft: 0, marginTop: 15 }}>
                                            View Entire Catalog
                                        </Link>
                                    </div> */}
                                {/* <hr /> */}
                                <div className="padding" />
                                <input type="hidden" id="dtp_input2" defaultValue="" />
                                {/* END FILTER BY DATE */}
                                <div className="padding" />
                                <div className="row" style={{ width: "20vw", marginLeft: 0, marginTop: 15 }}>
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                    <h4 style={{ paddingTop: 12.5 }}>Category:</h4>
                                    <Select
                                        value={state.selectedCategory}
                                        className="form-control spacing-input"
                                        id="selectmobile"
                                        placeholder={"CATEGORY"}
                                        onChange={(value) => {
                                            setState(prevState => ({ ...prevState, selectedCategory: value }))

                                            handleSelectionChangeCategory(value);
                                        }}
                                        options={optionsCategory}
                                    />
                                        {/* Between ${state.priceValuesOne[0]} to ${state.priceValuesTwo[0]} */}
                                    </div>
                                </div>
                                {/* BEGIN FILTER BY PRICE */}
                                <div className="row" style={width <= 1150 ? { width: "100%", marginLeft: 0, marginTop: 15 } : { width: "20vw", marginLeft: 0, marginTop: 15 }}>
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                    <h4 style={{ paddingTop: 12.5 }}>Price:</h4>
                                        Between ${state.priceValuesOne[0]} to ${state.priceValuesTwo[0]}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <input className="form-control" type="number" onChange={(e) => {
                                                if (Number(state.highestAvailValue) >= Number(e.target.value)) {
                                                    setState(prevState => ({ ...prevState, priceValuesOne: [Number(e.target.value)] }));

                                                    searchLowerValues([Number(e.target.value)])
                                                }
                                            }} placeholder="LOW VALUE" />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <input className="form-control" type="number"  onChange={(e) => {
                                                if (Number(state.highestAvailValue) >= Number(e.target.value)) {
                                                    setState(prevState => ({ ...prevState, priceValuesTwo: [Number(e.target.value)] }));

                                                    searchHigherValues([Number(e.target.value)])
                                                }
                                            }} placeholder="HIGH VALUE" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {state.highestAvailValue !== 0 ? <Range
                                    step={1}
                                    min={1}
                                    max={state.highestAvailValue}
                                    values={state.priceValuesOne}
                                    onChange={(values) => setState(prevState => ({ ...prevState, priceValuesOne: values }))}
                                    onFinalChange={(values) => searchLowerValues(values)}
                                    renderTrack={({ props, children }) => (
                                        <div>
                                            <p className="leadfive" style={{ color: "black" }}>Lowest:</p>

                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: '6px',
                                                    width: '100%',
                                                    backgroundColor: '#ccc'
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#999'
                                        }}
                                    />
                                    )}
                                /> : null}
                                <hr />
                                {state.highestAvailValue !== 0 ? <Range
                                    step={1}
                                    min={1}
                                    max={state.highestAvailValue}
                                    values={state.priceValuesTwo}
                                    onFinalChange={(values) => searchHigherValues(values)}
                                    onChange={(values) => state.priceValuesTwo[0] >= state.priceValuesOne[0] ? setState(prevState => ({ ...prevState, priceValuesTwo: values })) : null}
                                    renderTrack={({ props, children }) => (
                                        <div>
                                            <p className="leadfour" style={{ color: "black" }}>Highest:</p>
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: '6px',
                                                    width: '100%',
                                                    backgroundColor: '#ccc'
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#999'
                                        }}
                                    />
                                    )}
                                /> : null}
                                <hr />
                            </div>
                            <div className="mx-auto col-md-9 col-sm-12 col-lg-9">
                                <div id="fixside" style={{ minHeight: "92.5vh" }}>
                                    {renderMainContent()}
                                    <div className="position-bottom">
                                        <ResponsivePagination
                                            current={state.currentPage}
                                            total={state.totalPages}
                                            onPageChange={(page) => setState(prevState => ({ ...prevState, currentPage: page === 1 ? 0 : page - 1 }))}
                                        />
                                    </div>
                                </div>
                            </div>           
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        authData: state.auth.data
    }
};

export default connect(mapStateToProps, { })(IndividualAuctionViewHelper);
