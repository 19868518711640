import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import { NotificationManager } from 'react-notifications';
import axios from "../../../interceptor";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Switch from "react-switch";
import { authentication } from "../../../redux/actions/authentication/auth";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-number-input'
import _ from "lodash";
import LoadingOverlay from 'react-loading-overlay';
import { loadStripe } from '@stripe/stripe-js';
import {
    useStripe,
    useElements,
    CardElement
} from '@stripe/react-stripe-js';
import PlacesAutocomplete, {
    geocodeByAddress
} from 'react-places-autocomplete';
import ImageUploader, { FileObjectType as FileUploaderProps } from "react-image-upload";
import "react-image-upload/dist/index.css";
import {
    useWindowSize
} from '@react-hook/window-size';
import MultiStep from 'react-multistep';
import Confetti from 'react-confetti'
import debounce from 'lodash/debounce';


const StepOne = ({ state, setState, handlePasswordChange, renderMoreContent, width, handleSubmission }) => {
    return (
        <Fragment>
            <form autoComplete="off" id="register-form">
                <h2>Register New Account:</h2>
                <div className="row" style={width <= 775 ? { margin: 0 } : { margin: 100 }}>
                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                        <label className="reg-label">Email:</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control spacing-input"
                            autoComplete="off"
                            name="email"
                            placeholder="YOUR EMAIL"
                            required=""
                            tabIndex="1"
                            value={state.email}
                            onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                        />
                        <label className="reg-label">Bidder Identifier:</label>
                        <input
                            type="text"
                            id="text"
                            className="form-control spacing-input"
                            name="username"
                            autoComplete="off"
                            tabIndex="2"
                            placeholder="USERNAME/BIDDER ID"
                            required=""
                            value={state.username} 
                            onChange={(e) => setState(prevState => ({ ...prevState, username: e.target.value }))}
                        />
                        <label className="reg-label">Initial Password:</label>
                        <input
                            type={"password"}
                            className="form-control spacing-input"
                            id="passwordinitial"
                            autoComplete="off"
                            name="passwordinitial"
                            tabIndex="3"
                            placeholder="INITIAL PASSWORD (8 CHAR LENGTH MIN + SPECIAL CHARACTERS)"
                            required=""
                            value={state.initialPassword}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                        />
                        {state.notificationDisplayed === true ? <p style={{ color: "red", marginTop: 20 }} className="">{"Password MUST be at least 8 characters and MUST include a special character"}</p> : null}
                        {width <= 774 ? <Fragment><label className="reg-label">Initial Password:</label><input
                            type={"password"}
                            className="form-control spacing-input"
                            id="passwordconfirm"
                            autoComplete="off"
                            tabIndex="4"
                            name="passwordconfirm"
                            placeholder="CONFIRMATION PASSWORD"
                            required=""
                            value={state.confirmationPassword}
                            onChange={(e) => setState(prevState => ({ ...prevState, confirmationPassword: e.target.value }))}
                        /></Fragment> : null}
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                        {width >= 775 ? <Fragment><label className="reg-label">Confirm Password:</label><input
                            type={"password"}
                            className="form-control spacing-input"
                            id="passwordconfirm"
                            autoComplete="off"
                            tabIndex="4"
                            name="passwordconfirm"
                            placeholder="CONFIRMATION PASSWORD"
                            required=""
                            value={state.confirmationPassword}
                            onChange={(e) => setState(prevState => ({ ...prevState, confirmationPassword: e.target.value }))}
                        /></Fragment> : null}
                    </div>
                </div>
                <div style={{ margin: "22.5px 0px" }} className="text-center">
                    <Link to="/login" style={{ fontWeight: "bold" }} className="forgot-password">
                        Already have an account? Login!
                    </Link>
                </div>
            </form>
        </Fragment>
    );
};
const StepTwo = ({ state, setState, handlePasswordChange, renderMoreContent, width, calculateFirstPageDone }) => {
    const debouncedNotification = debounce(() => {
        NotificationManager.warning(
            "You must complete the prior page first before proceeding with these inputs...",
            "Complete the previous page first!",
            3750
        );
    }, 725);

    const handleInputChange = (e, inputKey) => {
        if (calculateFirstPageDone()) {
            setState((prevState) => ({
                ...prevState,
                [inputKey]: e.target.value,
            }));
        } else {
            debouncedNotification(); // Show notification with debounce
        }
    };
    
    return (
        <Fragment>
            <form autoComplete="off" id="register-form">
                <h2>Register New Account:</h2>
                <div className="row" style={width >= 775 ? { margin: 100, marginBottom: 25 } : {}}>
                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <label className="reg-label">First Name</label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control spacing-input"
                                    name="text"
                                    autoComplete="off"
                                    tabIndex="5"
                                    placeholder="FIRST NAME"
                                    required=""
                                    value={state.firstName}
                                    onChange={(e) => handleInputChange(e, "firstName")}
                                />
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <label className="reg-label">Last Name:</label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control spacing-input"
                                    name="text"
                                    autoComplete="off"
                                    placeholder="LAST NAME"
                                    required=""
                                    tabIndex="6"
                                    value={state.lastName}
                                    onChange={(e) => handleInputChange(e, "lastName")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ float: "left" }}>
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            <label className="reg-label">Month Of Birth:</label>
                            <input
                                type="number"
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                style={{ width: "100%" }}
                                placeholder="MONTH OF BIRTH"
                                tabIndex="8"
                                required=""
                                value={state.monthOfYearBirth}
                                onChange={(e) => handleInputChange(e, "monthOfYearBirth")}
                            />
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            <label className="reg-label">Day Of Birth:</label>
                            <input
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                style={{ width: "100%" }}
                                type="number"
                                placeholder="DAY OF BIRTH"
                                tabIndex="9"
                                required=""
                                value={state.dayOfMonthBirth}
                                onChange={(e) => handleInputChange(e, "dayOfMonthBirth")}
                            />
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            <label className="reg-label">Year Of Birth:</label>
                            <input
                                id="text"
                                className="form-control spacing-input"
                                name="text"
                                autoComplete="off"
                                style={{ width: "100%" }}
                                type="number"
                                tabIndex="10"
                                placeholder="YEAR OF BIRTH"
                                required=""
                                value={state.yearOfBirth}
                                onChange={(e) => handleInputChange(e, "yearOfBirth")}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                        <label className="reg-label">Phone Number:</label>
                        <PhoneInput
                            placeholder="PHONE NUMBER"
                            className="spacing-input"
                            defaultCountry="US"
                            tabIndex="7"
                            value={state.phoneNumber}
                            onChange={(value) => setState(prevState => ({ ...prevState, phoneNumber: value }))}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4" />
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4">
                        <div className="form-group" style={{ marginTop: "20px", textAlign: "left" }}>
                            <label style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center", fontSize: "14px", color: "#333", textAlign: "left", fontSize: 17.25 }}>
                                {/* <input
                                    type="checkbox"
                                    id="terms"
                                    checked={state.agreedToTerms}
                                    onChange={(e) => setState(prevState => ({ ...prevState, agreedToTerms: e.target.value === "on" ? true : false }))}
                                    style={{ marginRight: "10px", width: "16px", height: "16px", cursor: "pointer" }}
                                    required
                                /> */}
                                {/* I accept the <span style={{ margin: "0 3px" }}></span><Link to="/terms_and_conditions" style={{ color: "#2C849A", textDecoration: "underline" }}>Terms of Use </Link><span style={{ margin: "0 3px" }}></span> and <Link to="/privacy_policy" style={{ color: "#2C849A", textDecoration: "underline" }}><span style={{ margin: "0 3px" }}></span> Privacy Policy</Link>. */}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4" />
                </div>
                <div style={{ margin: "22.5px 0px" }} className="text-center">
                    <Link to="/login" style={{ fontWeight: "bold" }} className="forgot-password">
                        Already have an account? Login!
                    </Link>
                </div>
            </form>
        </Fragment>
    );
};
const StepThree = ({ state, setState, handlePasswordChange, renderMoreContent, width, handleSubmission }) => {
    return (
        <Fragment>
            <form autoComplete="off" id="register-form">
                <h2>Register New Account:</h2>
                {renderMoreContent()}
                <div style={{ margin: "22.5px 0px" }} className="text-center">
                    <Link to="/login" style={{ fontWeight: "bold" }} className="forgot-password">
                        Already have an account? Login!
                    </Link>
                </div>
                <div className="btn btn-blue">
                    <a style={{ color: "#fff" }} onClick={handleSubmission}>Register My Account!</a>
                </div>
            </form>
        </Fragment>
    );
};

const explicitWords = [
    'fuck', 'fucker', 'fucking', 'fucked', 'motherfucker', 'motherfucking', 'mf', "penis",
    'cunt', 'bitch', 'bitches', 'whore', 'slut', 'sluts', 'ass', 'asses', 'asshole', 'assholes', 
    'dick', 'dicks', 'dickhead', 'dickheads', 'pussy', 'pussies', 'shit', 'shits', 'shitty', 
    'damn', 'damned', 'goddamn', 'goddamned', 'hell', 'pissed', 'pissed off', 'bullshit', 'bs', 
    'bastard', 'bastards', 'motherfuckers', 'bich', 'bichs', 'biching', 'fuckboy', 'fuckboys', 
    'fuckgirl', 'fuckgirls', 'douche', 'douches', 'douchebag', 'douchebags', 'prick', 'pricks', 
    'twat', 'twats', 'wank', 'wanker', 'wankers', 'wanking', 'jackass', 'jackasses', 'shithead', 
    'shitheads', 'cock', 'cocks', 'cockhead', 'cockheads', 'cum', 'cumshot', 'cumshots', 'clit', 
    'clits', 'dildo', 'dildos', 'fag', 'fags', 'faggot', 'faggots', 'queer', 'queers', 'nigger', 
    'niggers', 'nigga', 'niggas', 'retard', 'retards', 'retarded', 'spic', 'spics', 'chink', 
    'chinks', 'gook', 'gooks', 'kike', 'kikes', 'wetback', 'wetbacks', 'beaner', 'beaners', 
    'jap', 'japs', 'dyke', 'dykes', 'homo', 'homos', 'tranny', 'trannies', 'skank', 'skanks', 
    'skanky', 'slag', 'slags', 'tits', 'titty', 'titties', 'boob', 'boobs', 'booby', 'boobies', 
    'balls', 'balling', 'titfuck', 'titfucks', 'jerk', 'jerks', 'jerkoff', 'jerking', 'jizz', 
    'jizzed', 'jizzing', 'pecker', 'peckers', 'schlong', 'schlongs', 'schmuck', 'schmucks', 
    'snatch', 'snatches', 'poon', 'poons', 'poonani', 'poonanis', 'puta', 'putas', 'puto', 
    'putos', 'choad', 'choads', 'creampie', 'creampies', 'ejaculate', 'ejaculates', 'ejaculated', 
    'ejaculating', 'jizzum', 'jizzums', 'fudgepacker', 'fudgepackers', 'gooch', 'gooches', 
    'nutsack', 'nutsacks', 'piss', 'pissing', 'pissed off', 'shitfaced', 'shitbag', 'shitbags', 
    'shitblimp', 'shitblimps', 'cumslut', 'cumsluts', 'felch', 'felched', 'felching', 'felcher', 
    'felchers', 'foreskin', 'foreskins', 'gayass', 'gayasses', 'dickhole', 'dickholes', 
    'jackoff', 'jackoffs', 'jack', 'jacked', 'jacking', 'nutter', 'nutters', 'scrote', 
    'scrotes', 'splooge', 'splooges', 'spooge', 'spooges', 'dingleberry', 'dingleberries', 
    'fart', 'farts', 'farting', 'shart', 'sharts', 'sharting', 'booger', 'boogers', 'butt', 
    'butts', 'butthole', 'buttholes', 'crap', 'crapping', 'crapped', 'shitstorm', 'shitstorms', 
    'shitty', 'crappy', 'dammit', 'bugger', 'buggers', 'bollocks', 'arse', 'arsehole', 'buggered'
];

const calculateSecondPageDone = (state) => {
    const { email, username, initialPassword, confirmationPassword, firstName, lastName, monthOfYearBirth, dayOfMonthBirth, yearOfBirth, phoneNumber } = state;

    if ((typeof email !== "undefined" && email.length > 0) && (typeof username !== "undefined" && username.length > 0) && (typeof initialPassword !== "undefined" && initialPassword.length > 0) && (typeof confirmationPassword !== "undefined" && confirmationPassword.length > 0) && (typeof firstName !== "undefined" && firstName.length > 0) && (typeof lastName !== "undefined" && lastName.length > 0) && (typeof monthOfYearBirth !== "undefined" && monthOfYearBirth.length > 0) && (typeof dayOfMonthBirth !== "undefined" && dayOfMonthBirth.length > 0) && (typeof yearOfBirth !== "undefined" && yearOfBirth.length > 0) && (typeof phoneNumber !== "undefined" && phoneNumber.length > 0)) {
        return true;
    } else {
        return false;
    }
}

const birthdateDefault = new Date(moment(new Date()).subtract(18, "years"));

const RegisterHelperComponent = ({ authentication, authData }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [width, height] = useWindowSize()
    const [ state, setState ] = useState({
        email: "",
        initialPassword: "",
        confirmationPassword: "",
        driversLicenseImage: null,
        runConfetti: false,
        username: "",
        hidePass: true,
        notificationDisplayed: false,
        firstName: "",
        bidderID: "", 
        lastName: "",
        isLoading: false,
        accountType: 1,
        birthdate: birthdateDefault,
        images: [],
        loading: false,
        phoneNumber: "", 
        captchaCompleted: false,
        selectedAddress: null,
        monthOfYearBirth: "", 
        dayOfMonthBirth: "", 
        yearOfBirth: "",
        token: null,
        isCardReady: false,
        agreedToTerms: true
    });

    console.log("stateeeeeeeeeeeeeeeeeee", state);

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSubmission = async (e) => {
        e.preventDefault();

        const containsWord = (string, array) => {
            for (let word of array) {
                if (string.includes(word)) {
                    return true;
                }
            }
            return false;
        }

        if (state.agreedToTerms === true) {
            if (state.captchaCompleted) {
                setState(prevState => ({ ...prevState, isLoading: true }));
    
                const {
                    email,
                    username,
                    firstName,
                    lastName,
                    birthdate,
                    accountType,
                    phoneNumber,
                    dayOfMonthBirth,
                    monthOfYearBirth,
                    // driversLicenseImage,
                    yearOfBirth,
                    initialPassword,
                    confirmationPassword,
                    token,
                    isCardReady,
                    selectedAddress
                } = state;
        
                console.log("handleSubmission clicked/ran."); 
        
        
                if (containsWord(username, explicitWords)) {
                    console.log("CONTAINS vulgar words!");
    
                    NotificationManager.warning('You may NOT use vulgar language in your username.', 'Change your username!', 3000);
                    
                    setState(prevState => ({ ...prevState, isLoading: false }));
                } else {
                    console.log("doesnt contain vular words");
    
                    if (accountType === 0) {
                        if ((typeof email !== "undefined" && email.length > 0 && validateEmail(email)) && (typeof initialPassword !== "undefined" && initialPassword.length > 0) && (typeof confirmationPassword !== "undefined" && confirmationPassword.length > 0) && (typeof firstName !== "undefined" && firstName.length > 0) && (typeof lastName !== "undefined" && lastName.length > 0) && (typeof username !== "undefined" && username.length > 0) && (typeof phoneNumber !== "undefined" && phoneNumber.length >= 10) && (typeof dayOfMonthBirth !== "undefined" && dayOfMonthBirth.length > 0) && (typeof monthOfYearBirth !== "undefined" && monthOfYearBirth.length > 0) && (typeof yearOfBirth !== "undefined" && yearOfBirth.length > 0)) {
        
                            const configgggg = {
                                email,
                                username,
                                firstName,
                                lastName,
                                birthdate,
                                dayOfMonthBirth,
                                monthOfYearBirth,
                                // driversLicenseImage,
                                yearOfBirth,
                                accountType,
                                phoneNumber,
                                initialPassword,
                                confirmationPassword,
                                token,
                                selectedAddress
                            };
                    
                            if (confirmationPassword === initialPassword) {
                                axios.post(`${process.env.REACT_APP_BASE_URL}/register`, configgggg).then((res) => {
                                    if (res.data.message === "Successfully registered!") {
                                        NotificationManager.success('Successfully registered your account!', 'Successfully registered.', 3000);
                                        
                                        const { user } = res.data;
                    
                                        authentication(user);
            
                                        setState(prevState => ({ ...prevState, isLoading: false, runConfetti: true }));
                                        
                                        setTimeout(() => {
                                            navigate("/confirm-email");
                                        }, 5000);
                                    } else if (res.data.message === "A user with the given username is already registered") {
                                        setState(prevState => ({ ...prevState, isLoading: false }));
            
                                        NotificationManager.error(res.data.message, 'Error processing request!', 3000);
                                    } else if (res.data.message === "Error occurred while attempting to save the desired data to the DB...") {
                                        setState(prevState => ({ ...prevState, isLoading: false }));
            
                                        NotificationManager.error("Use a different email address.", 'Error processing request!', 3000);
                                    } else {
                                        console.log("err");
                    
                                        setState(prevState => ({ ...prevState, isLoading: false }));
            
                                        NotificationManager.error('An error has occurred while processing your request.', 'Error processing request!', 3000);
                                    }
                                })
                            } else {
                                setState(prevState => ({ ...prevState, isLoading: false }));
            
                                NotificationManager.warning('Initial password and confirmation password do NOT match.', 'Passwords do NOT match!', 3000);
                            }
                        } else {
                            console.log("false", false);
            
                            setState(prevState => ({ ...prevState, isLoading: false }));
                
                            NotificationManager.warning('You need to complete all the required form elements properly before proceeding...', 'Invalid form values or incomplete!', 3000);
                        }
                    } else {
                    
                        const cardElement = elements.getElement(CardElement);
                        // Create a payment method
                        const { paymentMethod, error } = await stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement,
                        });
                    
                        if (error) {
                            console.log('Error creating payment method:', error);
        
                            NotificationManager.error("Error occurred with payment method!", 'Error adding PAYMENT METHOD.', 3000);
    
                            setState(prevState => ({ ...prevState, isLoading: false }))
                        } else {
                            // Use the paymentMethod.id as the payment token and send it to your server
                            console.log('Payment Method ID:', paymentMethod.id);
        
                            if ((typeof email !== "undefined" && email.length > 0 && validateEmail(email)) && (typeof initialPassword !== "undefined" && initialPassword.length > 0) && (typeof confirmationPassword !== "undefined" && confirmationPassword.length > 0) && (typeof firstName !== "undefined" && firstName.length > 0) && (typeof lastName !== "undefined" && lastName.length > 0) && (typeof username !== "undefined" && username.length > 0) && (typeof phoneNumber !== "undefined" && phoneNumber.length >= 10) && (typeof dayOfMonthBirth !== "undefined" && dayOfMonthBirth.length > 0) && (typeof monthOfYearBirth !== "undefined" && monthOfYearBirth.length > 0) && (typeof yearOfBirth !== "undefined" && yearOfBirth.length > 0) && (isCardReady === true) && (selectedAddress !== null)) {
        
                                const configgggg = {
                                    email,
                                    username,
                                    firstName,
                                    lastName,
                                    birthdate,
                                    accountType,
                                    phoneNumber,
                                    dayOfMonthBirth,
                                    // driversLicenseImage,
                                    monthOfYearBirth,
                                    yearOfBirth,
                                    initialPassword,
                                    confirmationPassword,
                                    token,
                                    paymentMethodID: paymentMethod.id,
                                    selectedAddress
                                };
        
                                if (confirmationPassword === initialPassword) {
                                    axios.post(`${process.env.REACT_APP_BASE_URL}/register`, configgggg).then((res) => {
                                        if (res.data.message === "Successfully registered!") {
                                            NotificationManager.success('Successfully registered your account!', 'Successfully registered.', 3000);
                                            
                                            const { user } = res.data;
                        
                                            authentication(user);
                
                                            setState(prevState => ({ ...prevState, isLoading: false, runConfetti: true }));
                                            
                                            setTimeout(() => {
                                                navigate("/confirm-email");
                                            }, 5000);
                                        } else if (res.data.message === "A user with the given username is already registered") {
                                            setState(prevState => ({ ...prevState, isLoading: false }));
                
                                            NotificationManager.error(res.data.message, 'Error processing request!', 3000);
                                        } else if (res.data.message === "Error occurred while attempting to save the desired data to the DB...") {
                                            setState(prevState => ({ ...prevState, isLoading: false }));
                
                                            NotificationManager.error("Use a different email address.", 'Error processing request!', 3000);
                                        } else if (res.data.message === "An error occurred while attempting to register this new account due to payment method not having funds...") {
                                            setState(prevState => ({ ...prevState, isLoading: false }));
                                            
                                            NotificationManager.error("Card MUST have $1.00 currently available!", 'Use a valid card with $$$ on it.', 3000);
                                        } else {
                                            console.log("err");
                        
                                            setState(prevState => ({ ...prevState, isLoading: false }));
                
                                            NotificationManager.error('An error has occurred while processing your request.', 'Error processing request!', 3000);
                                        }
                                    })
                                } else {
                                    setState(prevState => ({ ...prevState, isLoading: false }));
                
                                    NotificationManager.warning('Initial password and confirmation password do NOT match.', 'Passwords do NOT match!', 3000);
                                }
                            } else {
                                console.log("false", false);
                
                                setState(prevState => ({ ...prevState, isLoading: false }));
                    
                                NotificationManager.warning('You need to complete all the required form elements properly before proceeding...', 'Invalid form values or incomplete!', 3000);
                            }
                        }
                    }
                }
            } else {
    
                setState(prevState => ({ ...prevState, isLoading: false }));
    
                NotificationManager.warning('You must complete the captcha first before proceeding...', 'Complete CAPTCHA first!', 3000);
            }
        } else {
            NotificationManager.warning('Please agree to the terms & conditions on page 2 of the registration.', 'Complete agreement first!', 3000);
        }
    };
     
    const handleSelect = address => {
        geocodeByAddress(address).then(results => setState(prevState => ({ ...prevState, selectedAddress: results[0] }))).then(latLng => console.log('Success', latLng)).catch(error => console.error('Error', error));
    };

    const renderMoreContent = () => {
        const debouncedNotification = debounce(() => {
            NotificationManager.warning(
                "You must complete the prior page first before proceeding with these inputs...",
                "Complete the previous page first!",
                3750
            );
        }, 725);
    
        const handleInputChange = (address) => {
            if (calculateSecondPageDone(state)) {
                setState(prevState => ({ ...prevState, address }));
            } else {
                setState(prevState => ({ ...prevState, address: "" }));

                debouncedNotification(); // Show notification with debounce
            }
        };
        
        if (state.accountType === 1) {
            return (
                <Fragment>
                    <hr />
                    <div style={width >= 775 ? { margin: 100 } : {}} className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="leader">Enter your card details to verify/validate your account as a bidder; this will be your primary card on file. An amount of $1.00 will be charged through our secure vendor, Stripe, to verify card details and complete bidder registration.</p>
                                <div className="row">
                                    <label className="reg-label">Credit/Debit Card:</label>
                                    <CardElement tabIndex="11" onChange={(val) => {
                                        setState(prevState => ({ ...prevState, isCardReady: val.complete }));
                                    }} className="form-control spacing-input" />
                                </div>
                                <div className="row" style={{ paddingTop: 15 }}>
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                    <label className="reg-label">Address:</label>
                                    <PlacesAutocomplete
                                        value={state.address}
                                        tabIndex="12"
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        onSelect={handleSelect}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Search for your address, then select/confirm address using the drop down menu (address MUST be selected/confirmed using the drop down menu in order to register).',
                                                        className: 'location-search-input form-control spacing-input',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {typeof state.selectedAddress !== "undefined" && state.selectedAddress !== null ? <p className="lead" style={{ color: "#800020", textAlign: "left", marginTop: 11.25, fontWeight: "400" }}>{state.selectedAddress.formatted_address}</p> : null}
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            I accept the <span style={{ margin: "0 3px" }}></span><Link to="/terms_and_conditions" style={{ color: "#2C849A", textDecoration: "underline" }}>Terms of Use </Link><span style={{ margin: "0 3px" }}></span> and <Link to="/privacy_policy" style={{ color: "#2C849A", textDecoration: "underline" }}><span style={{ margin: "0 3px" }}></span> Privacy Policy</Link>.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="centered">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_KEY} // containsExplicitWords()
                                    onChange={(value) => value ? setState(prevState => ({ ...prevState, captchaCompleted: true })) : null}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="centered">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_KEY}
                                    onChange={(value) => value ? setState(prevState => ({ ...prevState, captchaCompleted: true })) : null}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    };

    const handlePasswordChange = (password) => {
        const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (regex.test(password)) {
            console.log("match");
            // Password meets the criteria
            setState(prevState => ({ ...prevState, initialPassword: password, notificationDisplayed: false }));
        } else {
            console.log("no match.");

            setState(prevState => ({ ...prevState, initialPassword: password, notificationDisplayed: true }));
        }
    };

    const calculateFirstPageDone = () => {
        const { email, username, initialPassword, confirmationPassword } = state;

        if ((typeof email !== "undefined" && email.length > 0) && (typeof username !== "undefined" && username.length > 0) && (typeof initialPassword !== "undefined" && initialPassword.length > 0) && (typeof confirmationPassword !== "undefined" && confirmationPassword.length > 0)) {
            return true;
        } else {
            return false;
        }
    }

    const steps = [
        { name: 'StepOne', component: <StepOne width={width} handleSubmission={handleSubmission} state={state} setState={setState} handlePasswordChange={handlePasswordChange} renderMoreContent={renderMoreContent} /> },
        { name: 'StepTwo', component: <StepTwo calculateFirstPageDone={calculateFirstPageDone} width={width} handleSubmission={handleSubmission} state={state} setState={setState} handlePasswordChange={handlePasswordChange} renderMoreContent={renderMoreContent} /> },
        { name: 'StepThree', component: <StepThree width={width} handleSubmission={handleSubmission} state={state} setState={setState} handlePasswordChange={handlePasswordChange} renderMoreContent={renderMoreContent} />},
    ];

    return (
        <Fragment>
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}
            <NavigationUniversalHelper />
            <Confetti
                width={width}
                height={height}
                numberOfPieces={100}
                run={state.runConfetti}
            />
            <div className="register-content container">
                <MultiStep
                    prevButton={{ title: 'Back' }}
                    nextButton={{ title: 'Forward' }} 
                    activeStep={0} 
                    showNavigation={true} 
                    steps={steps} 
                />
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
    }
};

export default connect(mapStateToProps, { authentication })(RegisterHelperComponent);
