import React, { Fragment, useRef, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import FAQHelper from "./helpers/faq/index";
import BiddingHelper from "./helpers/bidding/index";
import FeesAccordionHelper from "./helpers/fees/index";
import PaymentAccordionHelper from "./helpers/payment/index";
import ShippingAccordionHelper from "./helpers/shipping/index";
import {
    useWindowSize
} from '@react-hook/window-size'
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";


const BuyersHelperComponent = (props) => {
    const [width, height] = useWindowSize()
    const ref = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);

    const isInView2 = useInView(ref, { once: true, triggerOnce: true })
    const isInView3 = useInView(ref, { once: true, triggerOnce: true })
    const isInView4 = useInView(ref, { once: true, triggerOnce: true })
    const isInView5 = useInView(ref, { once: true, triggerOnce: true })
    const isInView6 = useInView(ref, { once: true, triggerOnce: true })
    const isInView7 = useInView(ref, { once: true, triggerOnce: true })

    const [ state, setState ] = useState({
        active: "buyers"
    });

    const renderAccordionContent = () => {
        const { active } = state;

        switch (active) {
            case "buyers":
                return (
                    <Fragment>
                        <div className={width <= 775 ? "" : "team-container"}>
                            <div className="team-header">
                                <h1 style={{ fontSize: 52.25, marginLeft: 10, textAlign: "left" }}>Buyers</h1>
                            </div>
                            <motion.p
                                ref={ref}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isInView2 ? 1 : 0 }}
                                transition={{ duration: 1, ease: "easeInOut" }} className="text-left">
                                Welcome to The Chicago Violin Auction, a new instrument auction catered to the bowed stringed instrument trade. With operations centered around our online platform and Chicago headquarters, our platform strives for a reliable, fair, and transparent auction process for our buyers.
                                <br /><br />
                                To learn more about buying through The Chicago Violin Auction, you can browse our links and navigate through our FAQ section.
                            </motion.p>
                            <h1 style={{ marginLeft: 12.25 }}>To our buyers</h1>
                            <motion.p
                                ref={ref}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isInView2 ? 1 : 0 }}
                                transition={{ duration: 1, ease: "easeInOut" }} className="text-left">
                                Stringed instrument buyers operate in a highly unique market, and operating as a niche auction, we strive to target our users' specialized needs. In a trade where confidentiality is paramount, our online auction platform's bidding system enables discreet bidding and post-sale anonymity. Though all of our items are available for inspection prior to the auction at our public viewings, descriptions, high-quality images, and condition reports are accessible online for every lot. If you have questions or need assistance, we are available to address any inquiries you may have through our 'Contact Us' page or email at <a href="mailto:info@chicagoviolinauction.com">info@chicagoviolinauction.com</a>.
                            </motion.p>
                        </div>
                    </Fragment>
                );
                break;
            case "faq":
                return <FAQHelper isInView={isInView3} ref2={ref2} width={width} />;
                break;
            case "bidding":
                return (
                    <BiddingHelper ref={ref4} isInView={isInView4} width={width} />
                );
                break;
            case "fees":
                return (
                    <FeesAccordionHelper ref={ref5} isInView={isInView5} width={width} />
                );
                break;
            case "payment":
                return (
                    <PaymentAccordionHelper ref={ref6} isInView={isInView6} width={width} />
                );
                break;
            case "shipping":
                return (
                    <ShippingAccordionHelper ref={ref7} isInView={isInView7} width={width} />
                );
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
                {/* Links Menu - add and rename as many as needed */}
                <div className="container">
                    <div className="row">
                        <div className="spacer" />
                        <div className="header">
                            <img className="banner-image-about-buyers" src={require("../../assets/Untitled-32.png")} />
                        </div>
                        <div className="about-us-container col-sm-12 col-lg-12 col-md-12">
                            {width <= 775 ? <Fragment>
                                <ul>
                                    <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "buyers" }))} className={state.active === "buyers" ? "active" : ""}>Buyers</li>
                                    <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "faq" }))} className={state.active === "faq" ? "active" : ""}>FAQ's</li>
                                    <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "bidding" }))} className={state.active === "bidding" ? "active" : ""}>Bidding</li>
                                    <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "fees" }))} className={state.active === "fees" ? "active" : ""}>Fees</li>
                                    <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "payment" }))} className={state.active === "payment" ? "active" : ""}>Payment</li>
                                    <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "shipping" }))} className={state.active === "shipping" ? "active" : ""}>Shipping</li>
                                </ul>
                                <div className="main-content-secondary">
                                    {renderAccordionContent()}
                                </div>
                            </Fragment> : <div className="content-secondary">
                                <div className="sidebar">
                                    <ul>
                                        <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "buyers" }))} className={state.active === "buyers" ? "active" : ""}>Buyers</li>
                                        <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "faq" }))} className={state.active === "faq" ? "active" : ""}>FAQ's</li>
                                        <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "bidding" }))} className={state.active === "bidding" ? "active" : ""}>Bidding</li>
                                        <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "fees" }))} className={state.active === "fees" ? "active" : ""}>Fees</li>
                                        <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "payment" }))} className={state.active === "payment" ? "active" : ""}>Payment</li>
                                        <li id="list-item-accordian-mobile" onClick={() => setState(prevState => ({ ...prevState, active: "shipping" }))} className={state.active === "shipping" ? "active" : ""}>Shipping</li>
                                    </ul>
                                </div>
                                <div className="main-content-secondary">
                                    {renderAccordionContent()}
                                </div>
                            </div>}
                            <div className={width <= 775 ? "row" : "footer row"}>
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    <Link to="/contact">
                                        <img className="image-bottom image-bottom-left" src={require("../../assets/images/246665936_4403079456412202_3142613271689919136_n4.png")} alt="Facebook" />
                                    </Link>
                                    <div style={{ height: 20 }} />
                                    <span style={{ fontWeight: "bold" }}>Contact Us</span>
                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/chicagoviolinauction">
                                            <img className="image-bottom image-bottom-secondary" src={require("../../assets/images/design1.png")} alt="Facebook" />
                                        </a>
                                        <div style={{ height: 20 }} />
                                        <span style={{ fontWeight: "bold" }}>Instagram</span>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/people/Chicago-Violin-Auction/61568371108590">
                                            <img className="image-bottom image-bottom-secondary" src={require("../../assets/images/design2.png")} alt="Facebook" />
                                        </a>
                                        <div style={{ height: 20 }} />
                                        <span style={{ fontWeight: "bold" }}>Facebook</span>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default BuyersHelperComponent;